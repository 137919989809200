import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckCeis = ({ title = '', ceisCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>{title || 'CEIS'}</Typography>
      <CheckDetailTitle
        email={ceisCheck?.email}
        status={ceisCheck?.status}
        createdAt={ceisCheck?.createdAt}
        updatedAt={ceisCheck?.updatedAt}
      />
      {!Array.isArray(ceisCheck?.checkDetails) && ceisCheck?.checkDetails?.informação && (
        <Box>
          <span>{t('common:information')}</span>
          <p>{ceisCheck?.checkDetails?.informação ?? '---'}</p>
        </Box>
      )}
      {Array.isArray(ceisCheck?.checkDetails) && ceisCheck?.checkDetails?.length && (
        <Box>
          <h4>{t('common:sanctions')}</h4>
          <Box className="group-table">
            <table className="bs bst group-table">
              <thead>
                <tr className="group-table">
                  <th className="bs">{t('common:initialDate')}</th>
                  <th className="bs">{t('common:endDate')}</th>
                  <th className="bs">{t('common:sanctionDescription')}</th>
                  <th className="bs">{t('common:sanctioningBody')}</th>
                </tr>
              </thead>
              <tbody>
                {ceisCheck?.checkDetails?.map((element, index) => {
                  return (
                    <tr className="group-table" key={index}>
                      <td className="bs table-cell col-4">{element.dataInicioSancao ?? '---'}</td>
                      <td className="bs table-cell col-4">{element.dataFimSancao ?? '---'}</td>
                      <td className="bs table-cell col-4">{element.tipoSancao?.descricaoResumida ?? '---'}</td>
                      <td className="bs table-cell col-4">{element.orgaoSancionador?.nome ?? '---'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CheckCeis
