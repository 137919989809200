import { Box, Button } from '@mui/material'
import ModalTitle from '../common/Modal/ModalTitle'
import { useTranslation } from 'react-i18next'

export const FilterModalContainer = ({ children, setModalOpen, handleResetButton, setPage, handleDispatch }) => {
  const { t } = useTranslation(['common'])

  return (
    <Box className="modal">
      <ModalTitle setModalOpen={setModalOpen} title={t('common:filters')} />

      {children}

      <Box className="modal-filter-buttons">
        <Button variant="outlinedGrey" size="small" onClick={() => handleResetButton()}>
          {t('common:clearAll')}
        </Button>

        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setPage(0)
            handleDispatch()
            return setModalOpen(false)
          }}
        >
          {t('common:apply')}
        </Button>
      </Box>
    </Box>
  )
}
