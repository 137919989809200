import '../../home/home.scss'
import './transaction.scss'
import { Autocomplete, Box, Grid, Modal, Paper, Typography } from '@mui/material'
import { CURRENCIES, TX_METHODS, TX_STATES, TX_TYPES } from './transaction-constants'
import { TextField } from '@mui/material'
import { darken, lighten, styled } from '@mui/system'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Button from '@mui/material/Button'
import Datatable from '../../../components/common/datatable/Datatable'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTransactionsFilters, useFindTransactionsQuery } from '../../../features/transactions/transactionSlice'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import {
  kytTransactionDataMapper,
  kytTransactionDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/kytTransactionDataMapper'
import { useTranslation } from 'react-i18next'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { FilterModalContainer } from '../../../components/utilities/FilterModalContainer'
import { selectUserMaster } from '../../../features/kyb/kybCountrySlice'
import { formatDate } from '../../../components/common/time/timeHelper'

const GroupHeader = styled('Box')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  textAlign: 'center',
  fontWeight: 700,
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const defaultSortModel = { field: 'createdTimestamp', sort: 'desc' }

const Transaction = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { emailClient } = useSelector((state) => state.login)
  const userMaster = useSelector((state) => selectUserMaster(state, emailClient))
  const queryState = useSelector((state) => state.transaction.filters)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [sortModel, setSortModel] = useState([defaultSortModel])
  const [transactionId, setTransactionId] = useState(queryState.transactionId)
  const [transactionType, setTransactionType] = useState(queryState.transactionType)
  const [transactionState, setTransactionState] = useState(queryState.transactionState)
  const [originUserId, setOriginUserId] = useState(queryState.originUserId)
  const [destinationUserId, setDestinationUserId] = useState(queryState.destinationUserId)
  const [originCurrency, setOriginCurrency] = useState(queryState.originCurrency)
  const [destinationCurrency, setDestinationCurrency] = useState(queryState.destinationCurrency)
  const [originMethod, setOriginMethod] = useState(queryState.originMethod)
  const [destinationMethod, setDestinationMethod] = useState(queryState.destinationMethod)
  const [ruleId, setRuleId] = useState(queryState.ruleId)
  const [ruleInstanceId, setRuleInstanceId] = useState(queryState.ruleInstanceId)
  const [shadowRuleInstanceId, setShadowRuleInstanceId] = useState(
    searchParams.get('shadowRuleId') ? searchParams.get('shadowRuleId') : queryState.shadowRuleInstanceId,
  )
  const [maxRuleAction, setMaxRuleAction] = useState(queryState.ruleInstanceId)
  const [transactionKey, setTransactionKey] = useState(queryState.transactionKey)
  const [transactionValue, setTransactionValue] = useState(queryState.transactionValue)
  const [documentNumber, setDocumentNumber] = useState(queryState.documentNumber)
  const [firstName, setFirstName] = useState(queryState.firstName)
  const [lastName, setLastName] = useState(queryState.lastName)
  const [legalName, setLegalName] = useState(queryState.legalName)
  const [fromDate, setFromDate] = useState(null)
  const [fromTime, setFromTime] = useState('00:00')
  const [toDate, setToDate] = useState(null)
  const [toTime, setToTime] = useState('23:59')
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation(['common'])
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { field, sort } = useMemo(() => (sortModel?.length ? sortModel[0] : defaultSortModel), [sortModel])
  const {
    data: allTransactions,
    isLoading,
    isFetching,
    refetch,
  } = useFindTransactionsQuery({
    ...queryState,
    emailClient,
    page,
    limit: rowsPerPage,
    offset: rowsPerPage * page,
    sortField: field,
    sortDirection: sort === 'desc' ? -1 : 1,
    timezone: userMaster?.timezone || -3,
    shadowRuleInstanceId,
  })
  const { activeServices } = useSelector((state) => state.login)
  const languageState = useSelector((state) => state.language.lang)

  useEffect(() => {
    dayjs.locale(languageState)
  }, [languageState])

  const handleResetButton = () => {
    if (transactionId !== '') setTransactionId('')
    if (transactionType) setTransactionType(null)
    if (transactionState) setTransactionState(null)
    if (originUserId) setOriginUserId(null)
    if (destinationUserId) setDestinationUserId(null)
    if (fromDate) setFromDate(null)
    if (toDate) setToDate(null)
    if (originCurrency) setOriginCurrency(null)
    if (destinationCurrency) setDestinationCurrency(null)
    if (originMethod) setOriginMethod(null)
    if (destinationMethod) setDestinationMethod(null)
    if (ruleId !== '') setRuleId('')
    if (ruleInstanceId !== '') setRuleInstanceId('')
    if (shadowRuleInstanceId !== '') setShadowRuleInstanceId('')
    if (maxRuleAction !== '') setMaxRuleAction('')
    if (transactionKey !== '') setTransactionKey('')
    if (transactionValue !== '') setTransactionValue('')
    if (documentNumber !== '') setDocumentNumber('')
    if (firstName !== '') setFirstName('')
    if (lastName !== '') setLastName('')
    if (legalName !== '') setLegalName('')
    setPage(0)
    setSearchParams('')

    dispatch(
      updateTransactionsFilters({
        viewMode: true,
        page: 0,
        limit: 50,
        offset: 0,
        transactionId: '',
        transactionType: null,
        transactionState: null,
        originUserId: null,
        destinationUserId: null,
        fromDate: null,
        toDate: null,
        originCurrency: null,
        destinationCurrency: null,
        originMethod: null,
        destinationMethod: null,
        ruleId: '',
        ruleInstanceId: '',
        shadowRuleInstanceId: '',
        maxRuleAction: '',
        transactionKey: '',
        transactionValue: '',
        documentNumber: '',
        firstName: '',
        lastName: '',
        legalName: '',
      }),
    )

    setModalOpen(false)
  }

  const handleDispatch = () => {
    const formattedFromDate = fromDate ? formatDate(fromDate) : null
    const formattedToDate = toDate ? formatDate(toDate) : null

    return dispatch(
      updateTransactionsFilters({
        viewMode: true,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        transactionId,
        transactionType,
        transactionState,
        originUserId,
        destinationUserId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        originCurrency,
        destinationCurrency,
        originMethod,
        destinationMethod,
        ruleId,
        ruleInstanceId,
        shadowRuleInstanceId,
        maxRuleAction,
        transactionKey,
        transactionValue,
        documentNumber,
        firstName,
        lastName,
        legalName,
      }),
    )
  }

  useEffect(() => {
    if (!fromDate && toDate) {
      const formattedDate = new Date(toDate)
      formattedDate?.setHours(0)
      formattedDate?.setMinutes(0)
      formattedDate?.setSeconds(0)
      setFromDate(formattedDate)
    } else if (fromDate && !toDate) {
      const formattedDate = new Date(fromDate)
      formattedDate?.setHours(23)
      formattedDate?.setMinutes(59)
      formattedDate?.setSeconds(59)
      setToDate(formattedDate ?? null)
    }
  }, [fromDate, toDate])

  const handleFromTimeChange = (time) => {
    let newDate = new Date(fromDate)
    const [hs, mins] = time.split(':')
    newDate.setHours(hs)
    newDate.setMinutes(mins)
    setFromTime(time)
    setFromDate(newDate)
  }

  const handleToTimeChange = (time) => {
    let newDate = new Date(toDate)
    const [hs, mins] = time.split(':')
    newDate.setHours(hs)
    newDate.setMinutes(mins)
    setToTime(time)
    setToDate(newDate)
  }

  return (
    <Box>
      <Box className={`filter`}>
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:transactions')}</Typography>
            {activeServices.includes('reportsitem') && (
              <Button
                variant="outlined"
                disabled={!activeServices.includes('reportsitem') || !allTransactions?.data?.length}
                onClick={() => setShowDownloadModal(true)}
                endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
              >
                <Typography variant="title2">{t('common:exportReport')}</Typography>
              </Button>
            )}
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>
        <Paper elevation={0}>
          <Datatable
            transactionsPage={allTransactions}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setModalOpen={setModalOpen}
            sortModel={sortModel}
            setSortModel={setSortModel}
            isLoading={isLoading || isFetching}
            refetch={refetch}
          />
        </Paper>
      </Box>

      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={allTransactions?.data}
            fileName={'transactions'}
            dataMapper={kytTransactionDataMapper}
            dataMapperForPDF={kytTransactionDataMapperForPDF}
            typeDataExport={'transactions'}
          />
        </div>
      </Modal>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div>
          <FilterModalContainer
            setModalOpen={setModalOpen}
            handleResetButton={handleResetButton}
            setPage={setPage}
            handleDispatch={handleDispatch}
          >
            <Box className="modal-box-filters">
              {/* TSX DETAILS */}
              <Box className="modal-box">
                <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
                <TextField
                  required
                  value={transactionId}
                  onChange={(event) => setTransactionId(event.target.value)}
                  size="small"
                  placeholder={`${t('common:insert')} ID`}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:initialDate')}:
                </Typography>
                <LocalizationProvider
                  size="small"
                  dateAdapter={AdapterDayjs}
                  adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
                >
                  <Box className="modal-flex">
                    <DatePicker
                      size="small"
                      value={dayjs(fromDate)}
                      onChange={(newValue) => {
                        setFromDate(newValue ? newValue['$d'] : null)
                      }}
                      renderInput={(params) => <TextField size="small" {...params} />}
                    />
                    <TextField
                      disabled={!fromDate}
                      type="time"
                      step="1"
                      value={fromTime}
                      onChange={(e) => handleFromTimeChange(e.target.value)}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:endDate')}:
                </Typography>
                <LocalizationProvider
                  size="small"
                  dateAdapter={AdapterDayjs}
                  adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
                >
                  <Box className="modal-flex">
                    <DatePicker
                      size="small"
                      value={dayjs(toDate)}
                      onChange={(newValue) => {
                        let aux = newValue ? new Date(newValue['$d']) : null
                        aux?.setHours(23)
                        aux?.setMinutes(59)
                        aux?.setSeconds(59)
                        setToDate(aux)
                      }}
                      renderInput={(params) => <TextField size="small" {...params} />}
                    />

                    <TextField
                      disabled={!toDate}
                      type="time"
                      step="1"
                      value={toTime}
                      onChange={(e) => handleToTimeChange(e.target.value)}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:type')}:
                </Typography>
                <Grid
                  container
                  spacing={1}
                  sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                >
                  {TX_TYPES?.map((item, index) => (
                    <Grid item key={index}>
                      <Button
                        onClick={() => {
                          return setTransactionType(item)
                        }}
                        variant={transactionType === item ? 'action' : 'label'}
                      >
                        {t(`common:${item}`)}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:state')}:
                </Typography>
                <Grid
                  container
                  spacing={1}
                  sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                >
                  {TX_STATES?.map((item, index) => (
                    <Grid item key={index}>
                      <Button
                        onClick={() => {
                          return setTransactionState(item)
                        }}
                        variant={transactionState === item ? 'action' : 'label'}
                      >
                        {t(`common:${item}`)}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:originUserId')}:
                </Typography>
                <TextField
                  required
                  value={originUserId}
                  onChange={(event) => setOriginUserId(event.target.value)}
                  size="small"
                  placeholder={`${t('common:insert')} ID`}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:destinationUserId')}:
                </Typography>
                <TextField
                  required
                  value={destinationUserId}
                  onChange={(event) => setDestinationUserId(event.target.value)}
                  size="small"
                  placeholder={`${t('common:insert')} ID`}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:originCurrency')}:
                </Typography>

                <Autocomplete
                  value={originCurrency}
                  onChange={(event, value) => {
                    if (value) {
                      setOriginCurrency(value)
                    } else setOriginCurrency(null)
                  }}
                  size="small"
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.aka}
                  options={CURRENCIES}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      placeholder={`${t('common:select')} ${t('common:originCurrency')}`}
                    />
                  )}
                  renderGroup={(params) => (
                    <li key={params.children.length}>
                      <GroupHeader key="group-ori">{params.group}</GroupHeader>
                      <GroupItems key="options-ori">{params.children}</GroupItems>
                    </li>
                  )}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:originMethod')}:
                </Typography>

                <Autocomplete
                  size="small"
                  value={originMethod}
                  onChange={(event, value) => {
                    if (value) {
                      setOriginMethod(value)
                    } else setOriginMethod(null)
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={TX_METHODS}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      placeholder={`${t('common:select')} ${t('common:originMethod')}`}
                    />
                  )}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:destinationCurrency')}:
                </Typography>

                <Autocomplete
                  value={destinationCurrency}
                  onChange={(event, value) => {
                    if (value) {
                      setDestinationCurrency(value)
                    } else setDestinationCurrency(null)
                  }}
                  size="small"
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.aka}
                  options={CURRENCIES}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      placeholder={`${t('common:select')} ${t('common:destinationCurrency')}`}
                    />
                  )}
                  renderGroup={(params) => (
                    <li key={params.children.length}>
                      <GroupHeader key="group-dest">{params.group}</GroupHeader>
                      <GroupItems key="options-dest">{params.children}</GroupItems>
                    </li>
                  )}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:destinationMethod')}:
                </Typography>
                <Autocomplete
                  size="small"
                  value={destinationMethod}
                  onChange={(event, value) => {
                    if (value) {
                      setDestinationMethod(value)
                    } else setDestinationMethod(null)
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={TX_METHODS}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      placeholder={`${t('common:select')} ${t('common:destinationMethod')}`}
                    />
                  )}
                />
              </Box>

              {/* RULES INFO */}
              <Box className="modal-box">
                <Typography variant="subtitle3">{t('common:ruleId')}:</Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  value={ruleId}
                  onChange={(event) => setRuleId(event.target.value)}
                  placeholder={`${t('common:insert')} ${t('common:ruleId')}`}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:ruleInstanceId')}:
                </Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  value={ruleInstanceId}
                  onChange={(event) => setRuleInstanceId(event.target.value)}
                  placeholder={`${t('common:insert')} ${t('common:ruleInstanceId')}`}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:shadowRuleInstanceId')}:
                </Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  value={shadowRuleInstanceId}
                  onChange={(event) => setShadowRuleInstanceId(event.target.value)}
                  placeholder={`${t('common:insert')} ${t('common:shadowRuleInstanceId')}`}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:maxRuleAction')}:
                </Typography>
                <Autocomplete
                  size="small"
                  value={maxRuleAction}
                  onChange={(event, value) => {
                    if (value) {
                      setMaxRuleAction(value)
                    } else {
                      setMaxRuleAction(null)
                    }
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={['FLAG', 'SUSPEND', 'BLOCK']}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      placeholder={`${t('common:select')} ${t('common:maxRuleAction')}`}
                    />
                  )}
                />
              </Box>

              {/* USER INFO */}
              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:user')} {t('common:documentNumber')}:
                </Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  value={documentNumber}
                  onChange={(event) => setDocumentNumber(event.target.value)}
                  placeholder={`${t('common:insert')} ${t('common:documentNumber')}`}
                />
              </Box>
              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:legalName')} ({t('common:business')}):
                </Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  value={legalName}
                  onChange={(event) => setLegalName(event.target.value)}
                  placeholder={`${t('common:insert')} ${t('common:legalName')}`}
                />
              </Box>

              {/* TAGS */}
              <Box className="modal-box">
                <Typography variant="subtitle3">
                  {t('common:by')} {t('common:transactionTagValue')}:
                </Typography>
                <Autocomplete
                  size="small"
                  value={transactionValue}
                  onChange={(event, value) => {
                    if (value) {
                      setTransactionValue(value)
                    } else {
                      setTransactionValue(null)
                    }
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={allTransactions?.unique?.uniqueValues ? allTransactions.unique.uniqueValues : []}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      placeholder={`${t('common:select')} ${t('common:transactionTagValue')}`}
                    />
                  )}
                />
              </Box>
            </Box>
          </FilterModalContainer>
        </div>
      </Modal>
    </Box>
  )
}

export default Transaction
