import { Typography } from '@mui/material'
import { toCustomUTCDateString } from '../../../components/common/time/timeHelper'

const RiskMatrixResultTitle = ({ data, total }) => {
  if (!data?.date) {
    return <Typography>-</Typography>
  }

  return <Typography fontWeight="bold">{`${toCustomUTCDateString(data.date)} | Total score: ${total}`}</Typography>
}

export default RiskMatrixResultTitle
