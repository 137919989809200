import { useEffect } from 'react'
import dayjs from 'dayjs'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AutocompleteInputForm, DateTimeInputForm, TextInputForm } from '../../../components/common/form'
import { FilterModalContainer } from '../../../components/utilities/FilterModalContainer'
import { countryDictionary } from '../../../components/common/flag/countries'
import { userKycStatusvalues, userStateValues } from '../constants'

const FiltersUsersConsumerForm = ({
  defaultValues,
  setModalOpen,
  handleResetButton,
  handleDispatch,
  setPage,
  uniqueKeys,
  uniqueValues,
}) => {
  const { t } = useTranslation(['common'])
  const { handleSubmit, reset, control, watch, setValue } = useForm({
    mode: 'onTouched',
    defaultValues,
  })

  const [fromDate, toDate] = watch(['fromDate', 'toDate'])

  const handleReset = () => {
    reset()
    handleResetButton()
    setModalOpen(false)
  }

  useEffect(() => {
    if (fromDate && !toDate) {
      setValue('toDate', dayjs(fromDate).hour(23).minute(59).second(59))
    } else if (!fromDate && toDate) {
      setValue('fromDate', dayjs(fromDate).hour(0).minute(0).second(0))
    }
  }, [fromDate, toDate, setValue])

  return (
    <FilterModalContainer
      setModalOpen={setModalOpen}
      handleResetButton={handleReset}
      setPage={setPage}
      handleDispatch={handleSubmit(handleDispatch)}
    >
      <Box className="modal-box-filters">
        <Box>
          <TextInputForm
            name="userId"
            control={control}
            label={`${t('common:by')} ID:`}
            placeholder={t('common:searchById')}
          />
        </Box>
        <Box>
          <DateTimeInputForm
            name="fromDate"
            timeName="fromTime"
            control={control}
            label={`${t('common:by')} ${t('common:initialDate')}:`}
          />
        </Box>
        <Box>
          <DateTimeInputForm
            name="toDate"
            timeName="toTime"
            control={control}
            label={`${t('common:by')} ${t('common:endDate')}:`}
          />
        </Box>
        <Box>
          <TextInputForm
            name="firstName"
            control={control}
            label={t('common:firstName')}
            placeholder={`${t('common:insert')} ${t('common:firstName')}`}
          />
        </Box>
        <Box>
          <TextInputForm
            name="middleName"
            control={control}
            label={t('common:middleName')}
            placeholder={`${t('common:insert')} ${t('common:middleName')}`}
          />
        </Box>
        <Box>
          <TextInputForm
            name="lastName"
            control={control}
            label={t('common:lastName')}
            placeholder={`${t('common:insert')} ${t('common:lastName')}`}
          />
        </Box>
        <Box>
          <AutocompleteInputForm
            name="countryOfResidence"
            control={control}
            label={t('common:countryOfResidence')}
            placeholder={t('common:countryOfResidence')}
            options={Object.keys(countryDictionary)}
            getOptionLabel={(option) => countryDictionary[option]}
          />
        </Box>
        <Box>
          <AutocompleteInputForm
            name="countryOfNationality"
            control={control}
            label={t('common:countryOfNationality')}
            placeholder={t('common:countryOfNationality')}
            options={Object.keys(countryDictionary)}
            getOptionLabel={(option) => countryDictionary[option]}
          />
        </Box>
        <Box>
          <TextInputForm
            name="documentNumber"
            control={control}
            label={t('common:documentNumber')}
            placeholder={`${t('common:insert')} ${t('common:documentNumber')}`}
          />
        </Box>

        <Box>
          <AutocompleteInputForm
            name="userKycStatus"
            control={control}
            label={`${t('common:user')} KYC ${t('common:status')}`}
            placeholder={`${t('common:searchBy')} KYC ${t('common:status')}`}
            options={userKycStatusvalues}
          />
        </Box>

        <Box>
          <AutocompleteInputForm
            name="selectedUserState"
            control={control}
            label={`${t('common:user')} ${t('common:state')}`}
            placeholder={`${t('common:searchBy')} ${t('common:state')}`}
            options={userStateValues}
          />
        </Box>

        {!!uniqueKeys?.length && (
          <Box>
            <AutocompleteInputForm
              name="userKey"
              control={control}
              label={`${t('common:user')} ${t('common:tagKey')}`}
              options={uniqueKeys}
              placeholder={`${t('common:searchBy')} ${t('common:tagKey')}`}
            />
          </Box>
        )}

        {!!uniqueValues?.length && (
          <Box>
            <AutocompleteInputForm
              name="userValue"
              control={control}
              label={`${t('common:user')} ${t('common:tagValue')}`}
              options={uniqueValues}
              placeholder={`${t('common:searchBy')} ${t('common:tagValue')}`}
            />
          </Box>
        )}
      </Box>
    </FilterModalContainer>
  )
}

export default FiltersUsersConsumerForm
