import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, InputLabel, Modal, TextField } from '@mui/material'
import ModalTitle from '../../../../../components/common/Modal/ModalTitle'
import { LoadingButton } from '@mui/lab'
import { useCreateKycPersonCommentMutation } from '../../../../../features/kyc/kycSlice'
import toaster from '../../../../../toaster'

export const CreateCommentModal = ({ id, modalOpen, setModalOpen }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [newCommentMessage, setNewCommentMessage] = useState('')

  const [createNewComment, { isLoading }] = useCreateKycPersonCommentMutation()

  const onSubmit = async () => {
    try {
      await createNewComment({
        id,
        body: {
          description: newCommentMessage,
        },
      }).unwrap()
      toaster.success(t('messages:createCommentSuccess'))
      setModalOpen(false)
    } catch (error) {
      console.error('[CREATE NEW COMMENT ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:createCommentError'))
    }
  }

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Box className="modal">
        <ModalTitle setModalOpen={setModalOpen} title={t('common:postComment')} subtitle={t('messages:commentDesc')} />
        <Box className="modal-box">
          <InputLabel>{t('common:comment')}:</InputLabel>
          <TextField
            multiline
            placeholder={t('messages:minCharacters')}
            required
            onChange={(e) => setNewCommentMessage(e.target.value)}
          />
        </Box>
        <Box className="modal-button">
          <LoadingButton
            size="small"
            color="secondary"
            variant="contained"
            disabled={newCommentMessage.length < 5}
            loading={isLoading}
            onClick={() => onSubmit(newCommentMessage)}
          >
            {t('common:publishComment')}
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
}
