import { useEffect, useState } from 'react'
import { Box, InputLabel, MenuItem, Select } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFindUserMasterQuery, useUpdateKybMutation } from '../../../services/guenoApi'

const KybClusterModal = ({ initialValue, onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const { emailClient } = useSelector((state) => state.login)
  const { data } = useFindUserMasterQuery({ emailClient })
  const [_, { isLoading }] = useUpdateKybMutation({ fixedCacheKey: 'kyb-update' })

  const [clusters, setClusters] = useState([])
  const [kybCluster, setKybCluster] = useState('')
  const [change, setChange] = useState(false)

  const onChange = (newValue) => {
    setKybCluster(newValue)
    if (newValue !== initialValue) {
      setChange(true)
    } else {
      setChange(false)
    }
  }

  useEffect(() => {
    if (data) {
      setClusters(data.clusters)
    }
  }, [data])

  useEffect(() => {
    setKybCluster(initialValue)
    setChange(false)
  }, [initialValue])

  return (
    <>
      <Box className="modal-box">
        <InputLabel>{t('common:cluster')}:</InputLabel>
        <Select
          id="kyb-cluster"
          size="small"
          inputProps={{ 'aria-label': 'Without label' }}
          value={kybCluster}
          onChange={(e) => onChange(e.target.value)}
        >
          {clusters.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className="modal-button">
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          disabled={!change}
          loading={isLoading}
          onClick={() => onSubmit('cluster', kybCluster)}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </>
  )
}

export default KybClusterModal
