import { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@mui/icons-material'
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FileUploader } from 'react-drag-drop-files'
import { RenderTooltip } from '../../../../components/utilities/RenderTooltip'
import { useCreateBatchMutation } from '../../../../services/guenoApi'
import { LoadingButton } from '@mui/lab'

function ModalCreteBatch({ setShowCreateBatch, handleCreateBatch }) {
  const { t } = useTranslation(['common', 'messages'])
  const [batchType, setBatchType] = useState('CONSUMER')
  const [batchOptions, setBatchOptions] = useState({})
  const [file, setFile] = useState('')
  const [_, { isLoading }] = useCreateBatchMutation({ fixedCacheKey: 'create-batch' })

  const handleSubmit = async () => {
    handleCreateBatch(batchType, file, batchOptions)
  }

  const canSend = () => !file || !batchType

  return (
    <Box className="modal" gap="1.5rem">
      <Box className="modal-top">
        <Typography variant="title">{t('common:CREATE_BATCH')}</Typography>‹
        <Fab variant="close" onClick={() => setShowCreateBatch(false)}>
          <CloseOutlined />
        </Fab>
      </Box>
      <Box className="modal-box-lists" padding="0">
        <Box className="lists-box">
          <Box className="batch-label">
            <InputLabel>{t('common:BATCH_TYPE')}</InputLabel>
            <RenderTooltip title={t('common:BATCH_TYPE_TOOLTIP')} />
          </Box>
          <Select
            value={batchType}
            onChange={(event) => {
              if (event.target.value) {
                setBatchType(event.target.value)
              } else setBatchType(null)
            }}
          >
            {['CONSUMER', 'BUSINESS', 'TRANSACTION'].map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {batchType === 'TRANSACTION' && (
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setBatchOptions({ skipErrors: e.target.checked })}
                    checked={!!batchOptions.skipErrors}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {t('messages:batch.skipErrors')}
                    <RenderTooltip title={t('messages:batch.skipErrorsDescription')} />
                  </Box>
                }
              />
            </FormGroup>
          </Box>
        )}
        <Box className="lists-box">
          <Box className="batch-label">
            <Typography variant="title2">{t('common:BATCH_FILES_MODELS')}</Typography>
            <RenderTooltip title={t('common:BATCH_FILES_MODELS_TOOLTIP')} />
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" gap="0.5rem">
            <Button
              variant="outlined"
              onClick={() =>
                window.open(
                  'https://docs.google.com/spreadsheets/d/1cYMGXqNxLO3YzzsFnlysQsH99kYR4n77zDRrH_3tWg8/edit?usp=sharing',
                  '_blank',
                  'rel=noopener noreferrer',
                )
              }
              startIcon={<InsertLinkOutlinedIcon />}
              sx={{ paddingInline: '10px' }}
            >
              {t('common:CONSUMER_BATCH')}
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                window.open(
                  'https://docs.google.com/spreadsheets/d/17TaSHPE3TDjD9AKSXpxiKEVOqfVjG5QvPaCMOmXbDyI/edit?usp=sharing',
                  '_blank',
                  'rel=noopener noreferrer',
                )
              }
              startIcon={<InsertLinkOutlinedIcon />}
              sx={{ paddingInline: '10px' }}
            >
              {t('common:BUSINESS_BATCH')}
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                window.open(
                  'https://docs.google.com/spreadsheets/d/1bJPV2ybGjCLKuKq42jhPUjJ70qFfQ_qzaK1QfCcKcAQ/edit?usp=sharing',
                  '_blank',
                  'rel=noopener noreferrer',
                )
              }
              startIcon={<InsertLinkOutlinedIcon />}
              sx={{ paddingInline: '10px' }}
            >
              {t('common:TRANSACTION_BATCH')}
            </Button>
          </Box>
        </Box>
        <Box className="lists-box" width="100%">
          <Box className="batch-label">
            <Typography variant="title2">{t('file')}</Typography>
            <RenderTooltip title={t('common:BATCH_FILE_TOOLTIP')} />
          </Box>
          <FileUploader
            handleChange={setFile}
            name="file"
            label={t('common:BATCH_FILE_UPLOADER_LABEL')}
            types={['csv']}
          >
            <Paper
              elevation={0}
              variant="rootDashed"
              className="draggable-main draggable-small"
              sx={{ minHeight: 'auto' }}
            >
              <Box className="draggable-icon" />
              <Typography variant="title2">{t('common:BATCH_FILE_UPLOADER_LABEL')}</Typography>
              <Box>
                <Typography variant="subtitle4">{t('common:or')} </Typography>
                <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                  {t('common:browseMedia')}
                </Typography>
              </Box>
            </Paper>
          </FileUploader>
          <Typography variant="subtitle4">{t('common:supportedMedia')}: CSV.</Typography>
          {file && (
            <Paper elevation={0} variant="colorPrimary" className="onb-file">
              <Box className="onb-file-description">
                <Box className="color-button" sx={{ background: '#E4DDFF' }}>
                  <Typography variant="subtitle1" sx={{ color: '#7E65D5' }}>
                    CSV
                  </Typography>
                </Box>

                <Typography
                  variant="h6"
                  title={file.name}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  maxWidth="160px"
                >
                  {file.name}
                </Typography>
              </Box>
              <Box className="onb-file-icons">
                <Button variant="outlinedBlank" onClick={() => setFile('')}>
                  <DeleteOutlineIcon />
                </Button>
              </Box>
            </Paper>
          )}
        </Box>
      </Box>
      <Box className="lists-buttons">
        <LoadingButton
          size="small"
          variant="outlinedGrey"
          onClick={() => setShowCreateBatch(false)}
          loading={isLoading}
        >
          {t('common:cancel')}
        </LoadingButton>
        <LoadingButton
          disabled={canSend()}
          size="small"
          variant="contained"
          type="submit"
          onClick={() => handleSubmit()}
          loading={isLoading}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default ModalCreteBatch
