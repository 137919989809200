import './pdf.scss'
import html2pdf from 'html2pdf.js'
import { Box, Button, Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFindReportByKybQuery } from '../../../services/guenoApi'
import CheckJap from './CheckDetailJap'
import CheckSerasa from './CheckSerasaDetail'
import CheckNegativeMedia from './CheckNegativeMedia'
import CheckKyc from './CheckKycDetail'
import CheckCade from './CheckCadeDetail'
import CheckDomains from './CheckDetailDomains'
import CheckCdn from './CheckCdnDetail'
import CheckDebtor from './CheckDebtorsDetail'
import CheckLawSuits from './CheckLawSuitDetail'
import CheckCnep from './CheckCnepDetail'
import CheckCeis from './CheckCeisDetail'
import CheckCepim from './CheckCepimDetail'
import CheckLeniencyAgreements from './CheckLeniencyAgreement'

const KybToPdf = ({ kyb, entity, currentCountry }) => {
  const { email } = useSelector((state) => state.login)
  const { t } = useTranslation(['common', 'messages'])
  const [kybDetails, setKybDetails] = useState(true)
  const [companyDetails, setCompanyDetails] = useState(true)
  const [shareholders, setShareholders] = useState(true)
  const [timeline, setTimeline] = useState(false)
  const [assignedUsers, setAssignedUsers] = useState(false)
  const [jap, setJap] = useState(false)
  const [debtors, setDebtors] = useState(false)
  const [domains, setDomains] = useState(false)
  const [kyc, setKyc] = useState(false)
  const [negativeMedia, setNegativeMedia] = useState(false)
  const [cdn, setCdn] = useState(false)
  const [comments, setComments] = useState(false)
  const [serasa, setSerasa] = useState(false)
  const [cnep, setCnep] = useState(false)
  const [cepim, setCepim] = useState(false)
  const [ceis, setCeis] = useState(false)
  const [leniencyAgreements, setLeniencyAgreements] = useState(false)
  const [cade, setCade] = useState(false)
  const [lawsuits, setLawsuits] = useState(false)

  const [serasaCheck, setSerasaCheck] = useState({})
  const [cnepCheck, setCnepCheck] = useState({})
  const [cepimCheck, setCepimCheck] = useState({})
  const [ceisCheck, setCeisCheck] = useState({})
  const [leniencyAgreementsCheck, setLeniencyAgreementsCheck] = useState({})
  const [debtorsCheck, setDebtorsCheck] = useState({})
  const [domainsCheck, setDomainsCheck] = useState([])
  const [kycCheck, setKycCheck] = useState([])
  const [cdnCheck, setCdnCheck] = useState([])
  const [negativeMediaCheck, setNegativeMediaCheck] = useState([])
  const [cadeCheck, setCadeCheck] = useState([])
  const [lawsuitsCheck, setLawsuitsCheck] = useState([])
  const [japCheck, setJapCheck] = useState([])
  const documentNumber = kyb?.companyDetails?.documentNumber

  const { data, isFetching, isLoading } = useFindReportByKybQuery({
    id: kyb?._id,
    currentCountry,
    entity,
    documentNumber,
  })

  const dataHistoryChecks = data?.data?.historyChecks || {}
  const dataShareHolders = data?.data?.shareHolders || []

  useEffect(() => {
    // if (Array.isArray(kyb?.jap?.checkDetails) && kyb?.jap?.checkDetails[0]?.Lawsuits) {
    //   setJapCheck(kyb?.jap?.checkDetails[0]?.Lawsuits)
    //   setJap(true)
    // }

    if (kyb?.jap?.checkDetails) {
      setJapCheck(kyb?.jap)
      setJap(true)
    }

    if (kyb?.serasa?.checkDetails) {
      setSerasaCheck(kyb?.serasa)
      setSerasa(true)
    }

    if (kyb?.cnep?.checkDetails) {
      setCnepCheck(kyb?.cnep)
      setCnep(true)
    }

    if (kyb?.cepim?.checkDetails) {
      setCepimCheck(kyb?.cepim)
      setCepim(true)
    }
    if (kyb?.ceis?.checkDetails) {
      setCeisCheck(kyb?.ceis)
      setCeis(true)
    }

    if (kyb?.leniencyAgreements?.checkDetails) {
      setLeniencyAgreementsCheck(kyb?.leniencyAgreements)
      setLeniencyAgreements(true)
    }
    // if (Array.isArray(kyb?.debtors?.checkDetails) && kyb?.debtors?.checkDetails[0]?.GovernmentDebtors) {
    //   setDebtorsCheck(kyb.debtors.checkDetails[0].GovernmentDebtors)
    //   setDebtors(true)
    // }

    if (kyb?.debtors?.checkDetails) {
      setDebtorsCheck(kyb.debtors)
      setDebtors(true)
    }

    // if (Array.isArray(kyb?.domains?.checkDetails) && kyb?.domains?.checkDetails[0]?.Domains) {
    //   setDomainsCheck(kyb?.domains?.checkDetails[0]?.Domains)
    //   setDomains(true)
    // }

    if (kyb?.domains?.checkDetails) {
      setDomainsCheck(kyb?.domains)
      setDomains(true)
    }

    // if (Array.isArray(kyb?.kyc?.checkDetails) && kyb?.kyc?.checkDetails[0]?.KycData) {
    //   setKycCheck(kyb?.kyc?.checkDetails[0]?.KycData)
    //   setKyc(true)
    // }

    if (kyb?.kyc?.checkDetails) {
      setKycCheck(kyb?.kyc)
      setKyc(true)
    }

    if (kyb?.cdn?.checkDetails) {
      setCdnCheck(kyb?.cdn)
      setCdn(true)
    }

    // if (
    //   Array.isArray(kyb?.negativeMedia?.checkDetails) &&
    //   kyb?.negativeMedia?.checkDetails[0]?.MediaProfileAndExposure
    // ) {
    //   setNegativeMediaCheck(kyb?.negativeMedia?.checkDetails[0]?.MediaProfileAndExposure)
    //   setNegativeMedia(true)
    // }

    if (kyb?.negativeMedia?.checkDetails?.source1?.MediaExposureLevel) {
      setNegativeMediaCheck(kyb?.negativeMedia)
      setNegativeMedia(true)
    }
    if (kyb?.cade?.checkDetails) {
      setCadeCheck(kyb?.cade)
      setCade(true)
    }
    if (kyb?.lawsuits?.checkDetails) {
      setLawsuitsCheck(kyb?.lawsuits)
      setLawsuits(true)
    }
    if (kyb?.assignedUsers?.length) setAssignedUsers(true)
    if (kyb?.comments?.length) setComments(true)
    if (kyb?.timeline?.length) setTimeline(true)
  }, [kyb])

  const generatePDF = () => {
    const element = document.getElementById('contentToConvert') // ID of the HTML element
    const options = {
      margin: [20, 15],
      filename: `${kyb?.companyDetails?.name}_${new Date().getTime()}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    }
    const today = new Date()
    html2pdf()
      .set(options)
      .from(element)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        let totalPages = pdf.internal.getNumberOfPages()

        for (let i = 1; i <= totalPages; i++) {
          // set footer to every page
          pdf.setPage(i)
          // set footer font
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
          if (i === 1) {
            pdf.text(
              pdf.internal.pageSize.getWidth() - 580,
              pdf.internal.pageSize.getHeight() - 825,
              `Güeno - PDF Generated on ${buildTimeStringFromTimestamp(today)} by ${email} - ${today.getTime()}`,
            )
          }
          pdf.text(
            pdf.internal.pageSize.getWidth() - 60,
            pdf.internal.pageSize.getHeight() - 10,
            `Page ${i} of ${totalPages}`,
          )
        }
      })
      .save()
  }

  if (isLoading || isFetching) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <>
      <Box className="modal-kyb-head">
        <Button onClick={generatePDF} variant="contained" size="small" color="secondary">
          {t('common:exportToPdf')}
        </Button>
      </Box>

      <Box className="content-to-convert-container">
        <Box className="detail-buttons">
          <Box className="detail-group-flow">
            <FormControlLabel
              control={<Checkbox checked={kybDetails} />}
              label={t('common:kybDetails')}
              onChange={() => setKybDetails(!kybDetails)}
            />
            <FormControlLabel
              control={<Checkbox checked={companyDetails} />}
              label={t('common:companyDetails')}
              onChange={() => setCompanyDetails(!companyDetails)}
            />
            {dataShareHolders && (
              <FormControlLabel
                control={<Checkbox checked={shareholders} />}
                label={t('common:shareholders')}
                onChange={() => setShareholders(!shareholders)}
              />
            )}
            {kyb?.assignedUsers && (
              <FormControlLabel
                control={<Checkbox checked={assignedUsers} />}
                label={t('common:assignedUsers')}
                onChange={() => setAssignedUsers(!assignedUsers)}
              />
            )}
            {kyb?.domains && (
              <FormControlLabel
                control={<Checkbox checked={domains} />}
                label={t('common:domains')}
                onChange={() => setDomains(!domains)}
              />
            )}
            {kyb?.kyc && (
              <FormControlLabel control={<Checkbox checked={kyc} />} label={'KYC'} onChange={() => setKyc(!kyc)} />
            )}
            {kyb?.negativeMedia && (
              <FormControlLabel
                control={<Checkbox checked={negativeMedia} />}
                label={t('common:negativeMedia')}
                onChange={() => setNegativeMedia(!negativeMedia)}
              />
            )}

            {kyb?.serasa && (
              <FormControlLabel
                control={<Checkbox checked={serasa} />}
                label={t('common:serasaScore')}
                onChange={() => setSerasa(!serasa)}
              />
            )}
            {kyb?.cnep && (
              <FormControlLabel control={<Checkbox checked={cnep} />} label={'CNEP'} onChange={() => setCnep(!cnep)} />
            )}
            {kyb?.cepim && (
              <FormControlLabel
                control={<Checkbox checked={cepim} />}
                label={'CEPIM'}
                onChange={() => setCepim(!cepim)}
              />
            )}
            {kyb?.ceis && (
              <FormControlLabel control={<Checkbox checked={ceis} />} label={'CEIS'} onChange={() => setCeis(!ceis)} />
            )}
            {kyb?.leniencyAgreements && (
              <FormControlLabel
                control={<Checkbox checked={leniencyAgreements} />}
                label={t('common:leniencyAgreements')}
                onChange={() => setLeniencyAgreements(!leniencyAgreements)}
              />
            )}
            {kyb?.debtors && (
              <FormControlLabel
                control={<Checkbox checked={debtors} />}
                label={t('common:governmentDebtors')}
                onChange={() => setDebtors(!debtors)}
              />
            )}
            {kyb?.cdn && (
              <FormControlLabel
                control={<Checkbox checked={cdn} />}
                label={t('common:cdn')}
                onChange={() => setCdn(!cdn)}
              />
            )}
            {kyb?.jap && (
              <FormControlLabel control={<Checkbox checked={jap} />} label={'JAP'} onChange={() => setJap(!jap)} />
            )}
            {kyb?.cade && (
              <FormControlLabel control={<Checkbox checked={cade} />} label={'CADE'} onChange={() => setCade(!cade)} />
            )}
            {kyb?.lawsuits && (
              <FormControlLabel
                control={<Checkbox checked={lawsuits} />}
                label={t('common:lawsuits')}
                onChange={() => setLawsuits(!lawsuits)}
              />
            )}
            {kyb?.comments && (
              <FormControlLabel
                control={<Checkbox checked={comments} />}
                label={t('common:comments')}
                onChange={() => setComments(!comments)}
              />
            )}
            {kyb?.timeline && (
              <FormControlLabel
                control={<Checkbox checked={timeline} />}
                label={t('common:timeline')}
                onChange={() => setTimeline(!timeline)}
              />
            )}
          </Box>
        </Box>
        <Box id="contentToConvert" className="content-to-convert">
          <h1>{kyb?.companyDetails?.name ? kyb?.companyDetails?.name : kyb?._id ? kyb?._id : '---'}</h1>
          {kybDetails && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:kybDetails')}</h3>
              <Box className="detail-group-grid-2">
                <Box>
                  <span>ID</span>
                  <p>{kyb?._id}</p>
                </Box>
                <Box>
                  <span>{t('common:status')}</span>
                  <p>{kyb?.status}</p>
                </Box>
                <Box>
                  <span>{t('common:creationDate')}</span>
                  <p>{kyb?.createdAt}</p>
                </Box>
                <Box>
                  <span>{t('common:lastUpdated')}</span>
                  <p>{kyb?.updatedAt}</p>
                </Box>
              </Box>
            </Box>
          )}
          {companyDetails && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:companyDetails')}</h3>
              <Box className="detail-group-grid-4">
                <Box>
                  <span>{t('common:companyDetails')}</span>
                  <p>{kyb?.companyDetails?.name ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:documentNumber')}</span>
                  <p>{kyb?.companyDetails?.cnpj ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:dateOfRegistration')}</span>
                  <p>{kyb?.companyDetails?.dateOfRegistration ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:country')}</span>
                  <p>{kyb?.companyDetails?.country ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:situation')}</span>
                  <p>{kyb?.companyDetails?.situacao?.nome ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:legalNature')}</span>
                  <p>{kyb?.companyDetails?.naturezaJuridica?.descricao ?? '---'}</p>
                </Box>
                <Box>
                  <span>{t('common:size')}</span>
                  <p>{kyb?.companyDetails?.porte?.descricao ?? '---'}</p>
                </Box>
              </Box>
              {Array.isArray(kyb?.companyDetails?.addresses) && (
                <Box>
                  <h4>{t('common:address')}</h4>
                  <Box className="detail-group-grid-4">
                    <Box>
                      <span>{t('common:postcode')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.postcode ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:street')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.street ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:number')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.number ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:aptSuite')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.apt_suite ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:city')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.city ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:state')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.state ?? '---'}</p>
                    </Box>
                    <Box>
                      <span>{t('common:country')}</span>
                      <p>{kyb?.companyDetails?.addresses[0]?.country ?? '---'}</p>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {companyDetails && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:companyEconomicActivity')}</h3>
              <Box className="detail-group-grid-2">
                <Box>
                  <span>
                    {t('common:mainActivity')} {t('common:class')}
                  </span>
                  <p>{kyb?.companyDetails?.cnae?.fiscal ?? '---'}</p>
                </Box>
                <Box>
                  <span>
                    {t('common:mainActivity')} {t('common:description')}
                  </span>
                  <p>{kyb?.companyDetails?.cnae?.descricao ?? '---'}</p>
                </Box>
              </Box>
              {kyb?.companyDetails?.cnae?.secundarias && (
                <Box className="group-table">
                  <span>
                    {t('common:secondaryActivity')} {t('common:class')}
                  </span>
                  <table className="bs bst group-table">
                    <thead>
                      <tr>
                        <th className="bs">ID</th>
                        <th className="bs">{t('common:description')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kyb.companyDetails.cnae.secundarias.map((element, index) => {
                        return (
                          <tr key={index}>
                            <td className="bs table-cell col-2-small">{element.id}</td>
                            <td className="bs table-cell col-2-big">{element.descricao}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
          {shareholders && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:shareholders')}</h3>
              {kyb?.shareHolders && (
                <Box className="group-table">
                  <table className="bs bst group-table">
                    <thead>
                      <tr className="group-table">
                        <th className="bs">{t('common:name')}</th>
                        <th className="bs">{t('common:document')}</th>
                        <th className="bs">{t('common:country')}</th>
                        <th className="bs">{t('common:type')}</th>
                        <th className="bs">{t('common:relationship')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataShareHolders.map((element, index) => {
                        return (
                          <tr className="group-table" key={index}>
                            <td className="bs table-cell col-5">{element.name}</td>
                            <td className="bs table-cell col-5">{element.documentNumber}</td>
                            <td className="bs table-cell col-5">{element.nationality}</td>
                            <td className="bs table-cell col-5">{element.type}</td>
                            <td className="bs table-cell col-5">
                              {element.relationship?.type ?? '---'} - {element.relationship?.name ?? '---'}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
          {comments && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:comments')}</h3>
              {kyb?.comments && (
                <Box className="group-table">
                  <table className="bs bst group-table">
                    <thead>
                      <tr className="group-table">
                        <th className="bs" style={{ width: 'auto', whiteSpace: 'nowrap' }}>
                          {t('common:user')}
                        </th>
                        <th className="bs" style={{ maxWidth: '300px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                          {t('common:description')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {kyb?.comments.map((element, index) => {
                        return (
                          <tr className="group-table" key={index}>
                            <td className="bs table-cell" style={{ width: 'auto', whiteSpace: 'nowrap' }}>
                              {element.email}
                            </td>
                            <td
                              className="bs table-cell"
                              style={{ maxWidth: '300px', wordWrap: 'break-word', whiteSpace: 'normal' }}
                            >
                              {element.description}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
          {cnep && <CheckCnep cnepCheck={cnepCheck} level={0} />}
          {cnep &&
            Array.isArray(dataHistoryChecks.SANCTIONS_CNEP) &&
            dataHistoryChecks.SANCTIONS_CNEP.map((item, index) => (
              <CheckCnep key={item._id} title={`Check History ${index + 1}`} cnepCheck={item} level={1} />
            ))}
          {cepim && <CheckCepim cepimCheck={cepimCheck} level={0} />}
          {cepim &&
            Array.isArray(dataHistoryChecks.SANCTIONS_CEPIM) &&
            dataHistoryChecks.SANCTIONS_CEPIM.map((item, index) => (
              <CheckCepim key={item._id} title={`Check History ${index + 1}`} cepimCheck={item} level={1} />
            ))}
          {ceis && <CheckCeis ceisCheck={ceisCheck} level={0} />}
          {ceis &&
            Array.isArray(dataHistoryChecks.SANCTIONS_CEIS) &&
            dataHistoryChecks.SANCTIONS_CEIS.map((item, index) => (
              <CheckCeis key={item._id} title={`Check History ${index + 1}`} ceisCheck={item} level={1} />
            ))}
          {leniencyAgreements && (
            <CheckLeniencyAgreements leniencyAgreementsCheck={leniencyAgreementsCheck} level={0} />
          )}
          {leniencyAgreements &&
            Array.isArray(dataHistoryChecks.SANCTIONS_LENIENCY_AGREEMENTS) &&
            dataHistoryChecks.SANCTIONS_LENIENCY_AGREEMENTS.map((item, index) => (
              <CheckLeniencyAgreements
                key={item._id}
                title={`Check History ${index + 1}`}
                leniencyAgreementsCheck={item}
                level={1}
              />
            ))}
          {/* {ceaf && <CheckCeaf ceafCheck={ceafCheck} level={0} />}
          {ceaf &&
            Array.isArray(dataHistoryChecks.SANCTIONS_CEAF) &&
            dataHistoryChecks.SANCTIONS_CEAF.map((item, index) => (
              <CheckCeaf key={item._id} title={`Check History ${index + 1}`} cnepCheck={item} level={1} />
            ))} */}
          {jap && <CheckJap jap={japCheck} level={0} />}
          {jap &&
            Array.isArray(dataHistoryChecks.JUDICIAL_ADMINISTRATIVE_PROCESSES) &&
            dataHistoryChecks.JUDICIAL_ADMINISTRATIVE_PROCESSES.map((item, index) => (
              <CheckJap key={item._id} title={`Check History ${index + 1}`} jap={item} level={1} />
            ))}
          {cade && <CheckCade cadeCheck={cadeCheck} level={0} />}
          {cade &&
            Array.isArray(dataHistoryChecks.CADE) &&
            dataHistoryChecks.CADE.map((item, index) => (
              <CheckCade key={item._id} title={`Check History ${index + 1}`} cadeCheck={item} level={1} />
            ))}
          {debtors && <CheckDebtor debtorsCheck={debtorsCheck} level={0} />}
          {debtors &&
            Array.isArray(dataHistoryChecks.GOVERNMENT_DEBTORS) &&
            dataHistoryChecks.GOVERNMENT_DEBTORS.map((item, index) => (
              <CheckDebtor key={item._id} title={`Check History ${index + 1}`} debtorsCheck={item} level={1} />
            ))}
          {domains && <CheckDomains domainCheck={domainsCheck} level={0} />}
          {domains &&
            Array.isArray(dataHistoryChecks.DOMAINS) &&
            dataHistoryChecks.DOMAINS.map((item, index) => (
              <CheckDomains key={item._id} title={`Check History ${index + 1}`} domainCheck={item} level={1} />
            ))}
          {lawsuits && <CheckLawSuits lawSuitsCheck={lawsuitsCheck} level={0} />}
          {lawsuits &&
            Array.isArray(dataHistoryChecks.OWNERS_LAWSUITS) &&
            dataHistoryChecks.OWNERS_LAWSUITS.map((item, index) => (
              <CheckLawSuits key={item._id} title={`Check History ${index + 1}`} lawSuitsCheck={item} level={1} />
            ))}
          {kyc && <CheckKyc kycCheck={kycCheck} level={0} />}
          {kyc &&
            Array.isArray(dataHistoryChecks.KYC_BUSINESS) &&
            dataHistoryChecks.KYC_BUSINESS.map((item, index) => (
              <CheckKyc key={item._id} title={`Check History ${index + 1}`} kycCheck={item} level={1} />
            ))}
          {cdn && <CheckCdn cdnCheck={cdnCheck} level={0} />}
          {cdn &&
            Array.isArray(dataHistoryChecks.NEGATIVE_DEBT_CERTIFICATES) &&
            dataHistoryChecks.NEGATIVE_DEBT_CERTIFICATES.map((item, index) => (
              <CheckCdn key={item._id} title={`Check History ${index + 1}`} cdnCheck={item} level={1} />
            ))}
          {negativeMedia && <CheckNegativeMedia level={0} negativeMediaCheck={negativeMediaCheck} />}
          {negativeMedia &&
            Array.isArray(dataHistoryChecks.NEGATIVE_MEDIA_BUSINESS) &&
            dataHistoryChecks.NEGATIVE_MEDIA_BUSINESS.map((item, index) => (
              <CheckNegativeMedia
                key={item._id}
                title={`Check History ${index + 1}`}
                negativeMediaCheck={item}
                level={1}
              />
            ))}
          {serasa && <CheckSerasa level={0} serasaCheck={serasaCheck} />}
          {serasa &&
            Array.isArray(dataHistoryChecks.SERASA_SCORE) &&
            dataHistoryChecks.SERASA_SCORE.map((item, index) => (
              <CheckSerasa key={item._id} title={`Check History ${index + 1}`} serasaCheck={item} level={1} />
            ))}
          {assignedUsers && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:assignedUsers')}</h3>
              {dataShareHolders.length > 0 && (
                <Box className="group-table">
                  <table className="bs bst group-table">
                    <thead>
                      <tr className="group-table">
                        <th className="bs">email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kyb?.assignedUsers?.map((element, index) => {
                        return (
                          <tr className="group-table" key={index}>
                            <td className="bs table-cell col-1">{element?.email}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
          {timeline && (
            <Box className="detail-group">
              <h3 className="detail-group-title">{t('common:timelineAndActivity')}</h3>
              {kyb?.timeline && (
                <Box>
                  <table className="bs bst group-table">
                    <thead>
                      <tr>
                        <th className="bs">{t('common:email')}</th>
                        <th className="bs">{t('common:type')}</th>
                        <th className="bs">{t('common:description')}</th>
                        <th className="bs">{t('common:status')}</th>
                        <th className="bs">{t('common:date')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kyb.timeline.map((element, index) => {
                        return (
                          <tr key={index}>
                            <td className="bs table-cell col-5">{element.email}</td>
                            <td className="bs table-cell col-5">{element.type}</td>
                            <td className="bs table-cell col-5">{element.description}</td>
                            <td className="bs table-cell col-5">{element.status}</td>
                            <td className="bs table-cell col-5">{element.createdAt}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default KybToPdf
