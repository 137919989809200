import { Autocomplete, Box, Chip, FormControl, FormHelperText, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { RenderTooltip } from '../../../utilities/RenderTooltip'
import styles from './TagsInputForm.module.scss'

const TagsInputForm = ({
  name,
  control,
  label,
  placeholder = '',
  tooltip = '',
  options = [],
  required = false,
  disabled = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Box className={styles.container}>
            <Box className={styles.label}>
              {!!label && <Typography variant="subtitle2">{label}</Typography>}
              {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
              {!!tooltip && <RenderTooltip title={tooltip} />}
            </Box>
            <FormControl error={!!fieldState.error}>
              <Autocomplete
                size="small"
                multiple
                disabled={disabled}
                placeholder={placeholder}
                value={field.value || []}
                onChange={(_, values) => field.onChange(values)}
                onBlur={field.onBlur}
                options={options}
                renderInput={(params) => <TextField variant="outlined" {...params} placeholder={placeholder} />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip key={option} variant="outlined" color="primary" label={option} {...getTagProps({ index })} />
                  ))
                }
                freeSolo
                disablePortal
              />
              {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          </Box>
        )
      }}
    />
  )
}

export default TagsInputForm
