import '../home/home.scss'
import { Box, Paper, Typography, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CountrySelector from '../../components/kyb/countrySelector'
import KybManualForm from './components/KybManualForm'
import { useFindUserMasterQuery } from '../../services/guenoApi'

const KybManualCheck = () => {
  const { t } = useTranslation(['common', 'messages'])

  const { emailClient } = useSelector((state) => state.login)

  const { currentCountry } = useSelector((state) => state.kybCountry)

  const useMasterResponse = useFindUserMasterQuery({ emailClient })

  if (useMasterResponse.isLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={`filter`}>
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:manualCheck')}</Typography>
          <CountrySelector />
        </Box>
      </Box>

      <Paper elevation={0}>{currentCountry === 'AR' && <KybManualForm />}</Paper>
    </Box>
  )
}

export default KybManualCheck
