import { Box, FormControl, FormHelperText, MenuItem, Select, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { RenderTooltip } from '../../../utilities/RenderTooltip'
import styles from './SelectInputForm.module.scss'

const SelectInputForm = ({
  name,
  control,
  label,
  placeholder = '',
  tooltip = '',
  defaultValue,
  options = [],
  required = false,
  disabled = false,
  emptyLabel = '',
  onchange,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Box className={styles.container}>
            <Box className={styles.label}>
              {!!label && <Typography variant="subtitle2">{label}</Typography>}
              {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
              {!!tooltip && <RenderTooltip title={tooltip} />}
            </Box>
            <FormControl error={!!fieldState.error}>
              <Select
                displayEmpty={!!emptyLabel}
                disabled={disabled}
                placeholder={placeholder}
                defaultValue={emptyLabel ? '' : defaultValue}
                value={field.value}
                onChange={onchange || field.onChange}
                onBlur={field.onBlur}
              >
                {!!emptyLabel && (
                  <MenuItem disabled value="">
                    <Typography variant="subtitle3">{emptyLabel}</Typography>
                  </MenuItem>
                )}
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          </Box>
        )
      }}
    />
  )
}

export default SelectInputForm
