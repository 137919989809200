import './timeRange.scss'
import { useEffect, useState } from 'react'
import NumberInput from '../numberInput/numberInput'
import SelectSingle from '../selectSingle/selectSingle'
import { Box, InputLabel, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const TimeRange = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  desc,
  schema,
  setError,
  errorList,
  setErrorList,
  isEditing,
}) => {
  const [finalParameter, setFinalParameter] = useState({})
  const [granular, setGranular] = useState({})
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (ruleParameters[propertie] && Object.keys(finalParameter).length === 0) {
      setFinalParameter({ ...ruleParameters[propertie] })
      setGranular({ granularity: ruleParameters[propertie].minAge?.granularity })
    }
  }, [propertie])

  const handleGranularChange = (value) => {
    let aux = { ...finalParameter }
    if (Object.keys(value).length !== 0) {
      if (!aux['minAge']) aux['minAge'] = {}
      if (!aux['maxAge']) aux['maxAge'] = {}
      aux['minAge']['granularity'] = value.granularity
      aux['maxAge']['granularity'] = value.granularity
    } else {
      delete aux['minAge']['granularity']
      delete aux['maxAge']['granularity']
    }
    setFinalParameter({ ...aux })
    setGranular(value)
    setChange(true)
  }

  const handleMinChange = (value) => {
    let aux = { ...finalParameter }
    if (Object.keys(value).length !== 0) {
      if (!aux['minAge']) aux['minAge'] = {}
      aux['minAge']['units'] = value.units
    } else {
      delete aux['minAge']['units']
    }
    setFinalParameter({ ...aux })
    setChange(true)
  }

  const handleMaxChange = (value) => {
    let aux = { ...finalParameter }
    if (Object.keys(value).length !== 0) {
      if (!aux['maxAge']) aux['maxAge'] = {}
      aux['maxAge']['units'] = value.units
    } else {
      delete aux['maxAge']['units']
    }
    setFinalParameter({ ...aux })
    setChange(true)
  }

  useEffect(() => {
    if (
      finalParameter?.minAge?.units &&
      finalParameter?.maxAge?.units &&
      finalParameter?.minAge?.units >= finalParameter?.maxAge?.units
    ) {
      let all = errorList
      all[propertie] = ['minAge should be  lower than maxAge']
      setErrorList({ ...all })
      setError(true)
    } else {
      let all = errorList
      delete all[propertie]
      setErrorList(all)
      if (Object.keys(all).length === 0) setError(false)
    }
  }, [finalParameter])

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      if (Object.keys(finalParameter).length !== 0) {
        all[propertie] = { ...finalParameter }
      } else {
        delete all[propertie]
      }
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [change, finalParameter])

  return (
    <Box>
      {desc && <Typography variant="subtitle4">{desc}</Typography>}
      <Box className="range-container">
        <Box className="range-row">
          <Box className="range-label">
            <span className="mandatory">* </span>
            <Typography variant="title3">{t('common:minAge')}</Typography>
          </Box>
          <NumberInput
            isEditing={isEditing}
            propertie={'units'}
            ruleParameters={finalParameter.minAge ?? {}}
            setRuleParameters={handleMinChange}
            schema={schema.properties?.minAge?.properties?.units}
          />
        </Box>
        <Box className="range-row">
          <Box className="range-label">
            <span className="mandatory">* </span>
            <Typography variant="title3">{t('common:maxAge')}</Typography>
          </Box>
          <NumberInput
            isEditing={isEditing}
            propertie={'units'}
            ruleParameters={finalParameter.maxAge ?? {}}
            setRuleParameters={handleMaxChange}
            schema={schema.properties?.maxAge?.properties?.units}
          />
        </Box>
        <Box className="range-row">
          <Box className="range-label">
            <span className="mandatory">* </span>
            <Typography variant="title3">{t('common:timeGranularity')}</Typography>
          </Box>
          <SelectSingle
            isEditing={isEditing}
            propertie={'granularity'}
            ruleParameters={granular}
            setRuleParameters={handleGranularChange}
            posibleValues={schema.properties?.maxAge?.properties?.granularity?.enum}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default TimeRange
