import { useState } from 'react'
import { useEffect } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import './selectMultiple.scss'
import { useTranslation } from 'react-i18next'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'

const SelectMultiple = ({ propertie, ruleParameters, setRuleParameters, posibleValues, isEditing }) => {
  const [selectedValue, setSelectedValue] = useState([])
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common', 'messages'])

  useEffect(() => {
    if (ruleParameters[propertie]) {
      setSelectedValue(ruleParameters[propertie])
    }
  }, [propertie, ruleParameters])

  const handleChange = (event, value) => {
    setSelectedValue(value)
    setChange(true)
    let all = { ...ruleParameters }
    all[propertie] = value
    setRuleParameters({ ...all })
  }

  useEffect(() => {
    if (change && selectedValue.length !== ruleParameters[propertie]?.length) {
      let all = { ...ruleParameters }
      all[propertie] = selectedValue
      setRuleParameters({ ...all })
      setChange(true)
    }
  }, [selectedValue, ruleParameters, setRuleParameters])

  return (
    <Autocomplete
      multiple
      options={posibleValues}
      getOptionLabel={(option) => mapCodesToNames(option)}
      filterSelectedOptions
      value={selectedValue}
      disabled={!isEditing}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
        />
      )}
    />
  )
}

export default SelectMultiple
