import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckDomains = ({ title = '', domainCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>
        {title || `Website ${t('common:data')}`}
      </Typography>
      <CheckDetailTitle
        email={domainCheck?.email}
        status={domainCheck?.status}
        createdAt={domainCheck?.createdAt}
        updatedAt={domainCheck?.updatedAt}
      />
      <Box>
        <h4>Websites {t('common:details')}</h4>
        <Box className="group-table">
          <table className="bs bst group-table">
            <thead>
              <tr className="group-table">
                <th className="bs">{t('common:domain')}</th>
                <th className="bs">{t('common:class')}</th>
                <th className="bs">{t('common:mainSegment')}</th>
                <th className="bs">{t('common:visitorRange')}</th>
                <th className="bs">{t('common:socialNetworks')}</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(domainCheck?.checkDetails?.domainsItems) &&
                domainCheck?.checkDetails?.domainsItems.map((detail, index) => (
                  <tr key={index} className="group-table">
                    <td className="bs table-cell col-5">{detail.FinalDomainName}</td>
                    <td className="bs table-cell col-5">{detail.DomainClass}</td>
                    <td className="bs table-cell col-5">{detail.DomainMainSegment}</td>
                    <td className="bs table-cell col-5">{detail.VisitorRange}</td>
                    <td className="bs table-cell col-5">{detail.SocialNetworks}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Box>
        {domainCheck?.checkDetails?.informação && (
          <Box>
            <span>{t('common:information')}</span>
            <p>{domainCheck.checkDetails.informação}</p>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CheckDomains
