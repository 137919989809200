import './roles.scss'
import { Box, Button, Paper, TextField, Typography, Select, MenuItem, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatatableRoles from '../../../components/common/datatable/DatatableRoles'
import { updateRolesFilters } from '../../../features/roles/rolesSlice'
import { useRolesByEmailClient } from '../../pages-hooks/useRoles'
import Spinner from '../../../components/common/spinner/spinner'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import {
  roleAdminDataMapper,
  roleAdminDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/roleAdminDataMapper'
import { useTranslation } from 'react-i18next'
import RoleCreationModal from './RoleCreationModal'
import { FilterModal } from '../../../components/utilities/FilterModal'

const Roles = () => {
  const { activeServices, emailClient } = useSelector((state) => state.login)
  const rolesState = useSelector((state) => state.role.roles)
  const queryState = useSelector((state) => state.role.filters)
  const [roleStatus, setRoleStatus] = useState(queryState?.status)
  const [roleName, setRoleName] = useState(queryState?.roleName)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [open, setModalOpen] = useState(false)
  const [createNewRoleModalOpen, setCreateNewRoleModalOpen] = useState(false)
  const [canCreate, setCanCreate] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  const verifyRoleCreation = () => {
    return activeServices && activeServices.length !== 0
      ? activeServices.some((path) => {
          return path === 'adminroles' && !path.includes('healthCheck')
        })
      : false
  }

  const { t } = useTranslation(['common'])

  useRolesByEmailClient(emailClient, isLoading, setIsLoading)

  const handleResetButton = () => {
    if (roleStatus !== '') setRoleStatus('')
    if (roleName !== '') setRoleName('')
    setPage(0)
    setIsLoading(true)

    dispatch(
      updateRolesFilters({
        status: '',
        roleName: '',
        page: 0,
        viewMode: true,
        limit: 50,
        offset: 0,
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateRolesFilters({
        status: roleStatus,
        roleName,
        page,
        viewMode: true,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      }),
    )
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (activeServices && activeServices.length !== 0) {
      if (verifyRoleCreation()) {
        setCanCreate(true)
      }
    }
  }, [activeServices])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:roles')}</Typography>
            <Box className="user-icons">
              {activeServices.includes('reportsitem') && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowDownloadModal(true)
                  }}
                  endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
                >
                  {t('common:exportList')}
                </Button>
              )}
              {canCreate && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setCreateNewRoleModalOpen(true)
                  }}
                >
                  {t('common:createRole')}
                </Button>
              )}
            </Box>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>
        <Paper elevation={0}>
          <DatatableRoles
            setIsLoading={setIsLoading}
            queryState={queryState}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setModalOpen={setModalOpen}
          />
        </Paper>
      </Box>
      <FilterModal
        modalOpen={open}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:role')}:
            </Typography>
            <TextField
              size="small"
              value={roleName}
              onChange={(event) => setRoleName(event.target.value)}
              placeholder={t('common:searchByRole')}
            />
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:status')}:
            </Typography>
            <Select
              size="small"
              value={roleStatus}
              onChange={(e) => setRoleStatus(e.target.value)}
              placeholder={t('common:searchByStatus')}
              options={['ACTIVE', 'INACTIVE']}
              displayEmpty
            >
              <MenuItem disabled value="">
                {t('common:searchByStatus')}
              </MenuItem>
              {['ACTIVE', 'INACTIVE'].map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </FilterModal>
      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={rolesState.data}
            dataMapper={roleAdminDataMapper}
            dataMapperForPDF={roleAdminDataMapperForPDF}
            fileName={'roles-admin'}
          />
        </div>
      </Modal>
      <RoleCreationModal
        show={createNewRoleModalOpen}
        setShow={setCreateNewRoleModalOpen}
        setIsLoading={setIsLoading}
      />
    </Box>
  )
}

export default Roles
