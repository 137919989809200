import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckCdn = ({ title = '', cdnCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>
        {title || t('common:negativeDebtCertificate')}
      </Typography>
      <CheckDetailTitle
        email={cdnCheck.email}
        status={cdnCheck.status}
        createdAt={cdnCheck.createdAt}
        updatedAt={cdnCheck.updatedAt}
      />
      <Box className="">
        <h4>
          {t('common:negativeDebtCertificate')} {t('common:details')}
        </h4>
        <Box className="detail-group-grid-4">
          <Box>
            <span>{t('common:message')}</span>
            <p>{cdnCheck?.checkDetails?.Mensagem ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:taxpayerType')}</span>
            <p>{cdnCheck?.checkDetails?.Certidao?.TipoContribuinte ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:taxpayerCertificate')}</span>
            <p>{cdnCheck?.checkDetails?.Certidao?.ContribuinteCertidao ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:certificateType')}</span>
            <p>{cdnCheck?.checkDetails?.Certidao?.TipoCertidao ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:controlCode')}</span>
            <p>{cdnCheck?.checkDetails?.Certidao?.CodigoControle ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:issuanceDate')}</span>
            <p>{cdnCheck?.checkDetails?.Certidao?.DataEmissao ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:expirationDate')}</span>
            <p>{cdnCheck?.checkDetails?.Certidao?.DataValidade ?? '---'}</p>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CheckCdn
