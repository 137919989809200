import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckDebtor = ({ title = '', debtorsCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>
        {title || t('common:governmentDebtors')}
      </Typography>
      <CheckDetailTitle
        email={debtorsCheck.email}
        status={debtorsCheck.status}
        createdAt={debtorsCheck.createdAt}
        updatedAt={debtorsCheck.updatedAt}
      />
      <Box className="">
        <h4>{t('common:debtorsDetails')}</h4>
        <Box className="detail-group-grid-3">
          <Box>
            <span>{t('common:debtorName')}</span>
            <p>{debtorsCheck?.checkDetails.DebtorName ?? 'No debts'}</p>
          </Box>
          <Box>
            <span>{t('common:totalDebtValue')}</span>
            <p>{debtorsCheck?.checkDetails.TotalDebtValue ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:totalDebts')}</span>
            <p>{debtorsCheck?.checkDetails.TotalDebts ?? '---'}</p>
          </Box>
        </Box>
        {debtorsCheck.checkDetails?.informação && (
          <Box>
            <span>{t('common:data')}</span>
            <p>{debtorsCheck.checkDetails.informação}</p>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CheckDebtor
