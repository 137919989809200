import { useMemo, useState } from 'react'
import { Box, Button, Grid, Link, Paper, TableContainer, TablePagination, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { useFindKycChecksQuery } from '../../../../features/kyc/kycSlice'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { FilterModal } from '../../../../components/utilities/FilterModal'
import { kycStatusEnum } from '../../kyc.enums'
import { TableChip } from '../../../../components/utilities/TableChip'

const DatatableChecks = ({ text }) => {
  const { t } = useTranslation(['common, messages'])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [showFilters, setShowFilters] = useState(false)
  const [status, setStatus] = useState()

  const { isFetching, isLoading, refetch, data } = useFindKycChecksQuery({
    limit: rowsPerPage,
    page,
    status,
    text,
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const columns = [
    {
      field: 'id',
      headerName: t('common:id'),
      flex: 0.7,
      minWidth: 100,
      renderCell: ({ row }) => {
        return (
          <Link component={RouterLink} to={`/kyc/person/${row.person._id}/${row.type}?checkId=${row._id}`}>
            <Typography variant="number">{row._id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'type',
      headerName: t('common:type'),
      flex: 0.6,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <TableChip noIcon={true} action={row.type} />
      },
    },
    {
      field: 'firstname',
      headerName: t('common:firstName'),
      flex: 0.7,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <Typography variant="body2">{`${row.person.firstname} ${row.person.lastname}` || '---'}</Typography>
      },
    },
    {
      field: 'email',
      headerName: t('common:email'),
      flex: 0.8,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <Typography variant="body2">{row.person.email || '---'}</Typography>
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 0.6,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <Typography variant="body2">{t(`messages:kyc.check.status.${row.status}`) || '---'}</Typography>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 0.8,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(row.createdAt)}</Typography>
      },
    },
  ]

  const { items: kycs, pagination } = useMemo(() => {
    if (data?.data) {
      return data?.data
    }
    return { items: [], pagination: null }
  }, [data])

  return (
    <Paper elevation={0}>
      <TableContainer>
        <Box className="filter-bar">
          <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setShowFilters(true)}>
            {t('common:filters')}
          </Button>
        </Box>
        <DataGrid
          getRowId={(row) => row._id}
          rows={kycs || []}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
          loading={isFetching || isLoading}
        />
        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <FilterModal
        modalOpen={showFilters}
        setModalOpen={setShowFilters}
        handleResetButton={() => setStatus()}
        setPage={setPage}
        handleDispatch={() => refetch()}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:status')}:
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {kycStatusEnum?.map((item, index) => (
                <Grid item key={index}>
                  <Button onClick={() => setStatus(item)} variant={status === item ? 'action' : 'label'}>
                    {t(`common:${item}`)}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </FilterModal>
    </Paper>
  )
}

export default DatatableChecks
