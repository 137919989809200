import { Box, Button, FormControl, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import DatatableKycs from './components/DataTableKycs'
import toaster from '../../toaster'
import { useCreateKycPersonMutation } from '../../features/kyc/kycSlice'
import CreatePersonForm from './components/CreatePersonForm'
import useDebounce from '../../components/hooks/useDebounce'
import styles from './styles.module.scss'

export const Kyc = () => {
  const { t } = useTranslation(['common', 'messages'])
  const [searchText, setSearchText] = useState()
  const [showCreatePersonModal, setShowCreatePersonModal] = useState(false)

  const [createKycPerson] = useCreateKycPersonMutation({ fixedCacheKey: 'create-person' })

  const searchTextDebounced = useDebounce(searchText, 500)

  const onSubmit = async (values) => {
    const { email, firstname, lastname, externalId } = values

    try {
      await createKycPerson({
        body: {
          email,
          firstname,
          lastname,
          externalId,
        },
      }).unwrap()
      toaster.success(t('messages:createKycPersonSuccess'))
      setShowCreatePersonModal(false)
    } catch (error) {
      console.error('[CREATE NEW COMMENT ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:createKycPersonError'))
    }
  }

  return (
    <Box className={`${styles.container} filter`}>
      <Typography variant="h2" className={styles.pageTitle}>
        {t('common:KYC')} - {t('common:knowYourClient')}
      </Typography>
      <Box className={styles.actionsContainer}>
        <FormControl sx={{ m: 1, width: 350 }}>
          <TextField
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={() => {}}
            placeholder={t('messages:searchKycPersonByEmailOrName')}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton alt="Clear" disabled={!searchText} onClick={() => setSearchText('')}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Button variant="contained" onClick={() => setShowCreatePersonModal(true)}>
          {`${t('common:create')} ${t('common:person')}`}
        </Button>
      </Box>
      <Box className={styles.tableContainer}>
        <DatatableKycs text={searchTextDebounced} />
      </Box>
      <Modal open={showCreatePersonModal} onClose={() => setShowCreatePersonModal(false)}>
        <div>
          <CreatePersonForm onSubmit={onSubmit} onCancel={() => setShowCreatePersonModal(false)} />
        </div>
      </Modal>
    </Box>
  )
}
