import './user.scss'
import '../../home/home.scss'
import { Box, Paper } from '@mui/material'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TabPanel } from '@mui/lab'
import dayjs from 'dayjs'
import DatatableKytUsersConsumer from '../../../components/common/datatable/DatatableKytUsersConsumer'
import DatatableKytUsersBusiness from '../../../components/common/datatable/DatatableKytUsersBusiness'
import { TabContextByParam, useGetRouterParam } from '../../../components/common/TabsByParam'

const User = () => {
  const { t } = useTranslation(['common'])
  const languageState = useSelector((state) => state.language.lang)

  const paramType = useGetRouterParam('type', ['consumerUsers', 'businessUsers'], 'consumerUsers')

  useEffect(() => {
    dayjs.locale(languageState)
  }, [languageState])

  return (
    <Box>
      <TabContextByParam
        param="type"
        currentValue={paramType}
        items={[
          { value: 'consumerUsers', label: t('common:consumer') },
          { value: 'businessUsers', label: t('common:business') },
        ]}
      >
        <Paper elevation={0}>
          <TabPanel value="consumerUsers">
            <DatatableKytUsersConsumer />
          </TabPanel>

          <TabPanel value="businessUsers">
            <DatatableKytUsersBusiness />
          </TabPanel>
        </Paper>
      </TabContextByParam>
    </Box>
  )
}

export default User
