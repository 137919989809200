import { Chip } from '@mui/material'

export const KycChip = ({ action, icon, color, backgroundColor }) => {
  const getStyle = () => {
    const boxShadow = '1px 3px 6px rgba(1, 1, 1, 0.18)'
    return {
      color,
      backgroundColor,
      boxShadow,
      width: 'fit-content',
    }
  }

  return <Chip label={action} size="small" style={getStyle()} icon={icon} />
}
