import '../../home/home.scss'
import './externalPaymentMethods.scss'
import { Autocomplete, Box, Grid, Paper, Typography, TextField, Button } from '@mui/material'
import { TX_TYPES, FILTER_MODE, TX_STATES, IDS_BY_PAYMENTMETHOD } from './externalPaymentMethods-constants'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateExternalPaymentMethodsFilters,
  resetExternalPaymentMethodsFilters,
} from '../../../features/externalPaymentMethods/externalPaymentMethodsSlice'
import Spinner from '../../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { FilterModal } from '../../../components/utilities/FilterModal'
import DatatableExternalPaymentMethods from '../../../components/common/datatable/DatatableExternalPaymentMethods'
import { useExternalPaymentMethods } from '../../../../src/pages/pages-hooks/useExternalPaymentMethods'

const ExternalPaymentMethods = () => {
  const queryState = useSelector((state) => state.externalPaymentMethods.filters)
  const [isLoading, setIsLoading] = useState(true)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [transactionType, setTransactionType] = useState(queryState.transactionType)
  const [fromDate, setFromDate] = useState(new Date(queryState.fromDate))
  const [fromTime, setFromTime] = useState('00:00')
  const [toDate, setToDate] = useState(new Date(queryState.toDate))
  const [toTime, setToTime] = useState('23:59')
  const [modalOpen, setModalOpen] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(queryState.paymentMethodId)
  const dispatch = useDispatch()
  const { t } = useTranslation(['common'])
  const languageState = useSelector((state) => state.language.lang)
  const [transactions, setTransactions] = useState(queryState.transactions)
  const [mode, setMode] = useState(queryState.mode)
  const [transactionState, setTransactionState] = useState(queryState.transactionState)
  const [paymentMethod, setPaymentMethod] = useState(queryState.paymentMethod)
  const { emailClient } = useSelector((state) => state.login)

  useExternalPaymentMethods(emailClient, isLoading, setIsLoading)

  useEffect(() => {
    if (isFirstRender) setIsFirstRender(false)
  }, [])

  useEffect(() => {
    if (!isFirstRender) {
      handleDispatch()
    }
  }, [rowsPerPage, page])

  useEffect(() => {
    dayjs.locale(languageState)
  }, [languageState])

  const handleResetButton = () => {
    const fromDate = new Date()
    fromDate.setMonth(fromDate.getMonth() - 1)

    setIsLoading(true)
    setTransactionType([])
    setFromDate(fromDate.toISOString())
    setFromTime('00:00')
    setToDate(new Date().toISOString())
    setToTime('23:59')
    setPaymentMethodId('')
    setTransactions(0)
    setMode('cashout')
    setTransactionState([])
    setPaymentMethod('')
    dispatch(resetExternalPaymentMethodsFilters())
    setModalOpen(false)
  }

  const handleDispatch = () => {
    const formattedFromDate = fromDate ? dayjs(fromDate).format('YYYY-MM-DDTHH:mm:ss') : null
    const formattedToDate = toDate ? dayjs(toDate).format('YYYY-MM-DDTHH:mm:ss') : null
    setIsLoading(true)
    const filters = {
      viewMode: true,
      page,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      transactionType,
      transactionState,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      mode,
      transactions,
      paymentMethod,
      paymentMethodId,
    }
    dispatch(updateExternalPaymentMethodsFilters(filters))
  }

  useEffect(() => {
    if (!fromDate && toDate) {
      const formattedDate = new Date(toDate)
      formattedDate.setHours(0)
      formattedDate.setMinutes(0)
      formattedDate.setSeconds(0)
      setFromDate(formattedDate)
    }
  }, [toDate])

  useEffect(() => {
    if (fromDate && !toDate) {
      const formattedDate = new Date(fromDate)
      formattedDate.setHours(23)
      formattedDate.setMinutes(59)
      formattedDate.setSeconds(59)
      setToDate(formattedDate ?? null)
    }
  }, [fromDate])

  const handleFromTimeChange = (time) => {
    let newDate = new Date(fromDate)
    const [hs, mins] = time.split(':')
    newDate.setHours(hs)
    newDate.setMinutes(mins)
    newDate.setSeconds(0)
    setFromTime(time)
    setFromDate(newDate)
  }

  const handleToTimeChange = (time) => {
    let newDate = new Date(toDate)
    const [hs, mins] = time.split(':')
    newDate.setHours(hs)
    newDate.setMinutes(mins)
    newDate.setSeconds(59)
    setToTime(time)
    setToDate(newDate)
  }

  const handleModeChange = (_, value) => {
    if (value) {
      setMode(value.toLowerCase())
    } else {
      setMode('cashout')
    }
  }

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}
        <Box className="component-title-wrapper">
          <Box className="component-title" justifyContent={'flex-start'} alignItems="center" gap="1.5rem">
            <Typography variant="h2">{t('common:externalPaymentMethods')}</Typography>
            <Typography variant="h4" color={'var(--grey-70)'}>
              {queryState.mode === 'cashin' ? '(Cash in)' : '(Cash out)'}
            </Typography>
          </Box>
        </Box>
        <Paper elevation={0}>
          <DatatableExternalPaymentMethods
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setModalOpen={setModalOpen}
          />
        </Paper>
      </Box>
      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4 }}>
          <Box sx={{ flex: 1 }} className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:externalTransactionsMode')}:
            </Typography>
            <Autocomplete
              value={mode}
              onChange={handleModeChange}
              size="small"
              getOptionLabel={(option) => option.toUpperCase()}
              options={FILTER_MODE.map((mode) => mode.toLowerCase())}
              disableClearable
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={`${t('common:select')} ${t('common:transactionMode')}`}
                />
              )}
            />
          </Box>
          <Box sx={{ flex: 1 }} className="modal-box">
            <Typography variant="subtitle3">
              {t('common:minimum')} {t('common:transactions')}:
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={transactions}
              onChange={(event) => setTransactions(Number(event.target.value))}
              placeholder="0"
            />
          </Box>
        </Box>
        <Box className="modal-box-filters">
          {/* TX Details */}
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:initialDate')}:
            </Typography>
            <LocalizationProvider size="small" dateAdapter={AdapterDayjs} adapterLocale={languageState}>
              <Box className="modal-flex">
                <DatePicker
                  size="small"
                  value={dayjs(fromDate)}
                  onChange={(newValue) => setFromDate(newValue ? newValue['$d'] : null)}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
                <TextField
                  disabled={!fromDate}
                  type="time"
                  step="1"
                  value={fromTime}
                  onChange={(e) => handleFromTimeChange(e.target.value)}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:endDate')}:
            </Typography>
            <LocalizationProvider size="small" dateAdapter={AdapterDayjs} adapterLocale={languageState}>
              <Box className="modal-flex">
                <DatePicker
                  size="small"
                  value={dayjs(toDate)}
                  onChange={(newValue) => {
                    let aux = newValue ? new Date(newValue['$d']) : null
                    aux?.setHours(23)
                    aux?.setMinutes(59)
                    aux?.setSeconds(59)
                    setToDate(aux)
                  }}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
                <TextField
                  disabled={!toDate}
                  type="time"
                  step="1"
                  value={toTime}
                  onChange={(e) => handleToTimeChange(e.target.value)}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:transactionType')}:
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {TX_TYPES.map((item, index) => (
                <Grid item key={index}>
                  <Button
                    onClick={() => {
                      setTransactionType((prevType) => {
                        if (prevType.includes(item)) {
                          return prevType.filter((type) => type !== item)
                        } else {
                          return [...prevType, item]
                        }
                      })
                    }}
                    variant={transactionType.includes(item) ? 'action' : 'label'}
                  >
                    {t(`common:${item}`)}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:transactionState')}:
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {TX_STATES.map((item, index) => (
                <Grid item key={index}>
                  <Button
                    onClick={() => {
                      setTransactionState((prevStatus) => {
                        if (prevStatus.includes(item)) {
                          return prevStatus.filter((status) => status !== item)
                        } else {
                          return [...prevStatus, item]
                        }
                      })
                    }}
                    variant={transactionState.includes(item) ? 'action' : 'label'}
                  >
                    {t(`common:${item}`)}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:paymentMethod')}:
            </Typography>
            <Autocomplete
              value={paymentMethod}
              onChange={(_, value) => {
                setPaymentMethod(value ?? '')
                setPaymentMethodId('')
              }}
              size="small"
              options={Object.keys(IDS_BY_PAYMENTMETHOD)}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={`${t('common:select')} ${t('common:paymentMethod')}`}
                />
              )}
            />
          </Box>
          {paymentMethod && (
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:by')} {t(`common:${IDS_BY_PAYMENTMETHOD[paymentMethod]}`)}:
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                value={paymentMethodId}
                onChange={(event) => setPaymentMethodId(event.target.value)}
                placeholder={t(`common:${IDS_BY_PAYMENTMETHOD[paymentMethod]}`) || ''}
              />
            </Box>
          )}
        </Box>
      </FilterModal>
    </Box>
  )
}

export default ExternalPaymentMethods
