import { useRef } from 'react'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import EditOutlined from '@mui/icons-material/EditOutlined'
import styles from './styles.module.scss'

const GridItem = ({ name, value, copyable = false, editable = false, onEditClick = () => {}, icon }) => {
  const componentValueRef = useRef(null)

  return (
    <Paper elevation={0} variant="colorPrimarySmall">
      <Box className={styles.extraContainer}>
        <Typography variant="subtitle1">{name}</Typography>
        {icon}
      </Box>
      {!!value && (
        <Box className={styles.extraContainer}>
          <Typography
            variant="h5"
            ref={componentValueRef}
            sx={{ maxHeight: '2.5rem', overflow: 'hidden' }}
            style={{ whiteSpace: 'pre-line' }}
          >
            {value}
          </Typography>
          {copyable && (
            <IconButton style={{ padding: '0' }} onClick={() => navigator.clipboard.writeText(value)}>
              <ContentCopyOutlinedIcon style={{ height: '1rem', width: '1rem', fill: 'grey' }} />
            </IconButton>
          )}
          {editable && (
            <IconButton style={{ padding: '0' }} onClick={onEditClick}>
              <EditOutlined color="primary" style={{ height: '1rem', width: '1rem' }} />
            </IconButton>
          )}
        </Box>
      )}
    </Paper>
  )
}

export default GridItem
