import './datatable.scss'
import { Box, Button, IconButton, Link, Modal, TablePagination, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import { toLocaleUTCDateString } from '../time/timeHelper'
import { Flags } from '../flag/flags'
import { useTranslation } from 'react-i18next'
import CustomColumnMenu from '../datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../datagrid/CustomLoadingOverlay'
import {
  useCreateConsumerUserEventMutation,
  useFindKytUsersQuery,
} from '../../../features/transactions/transactionSlice'
import toaster from '../../../toaster'
import EditStateUserRow from '../../../pages/kyt/components/EditStateUserRow'
import DataExportModal from '../dataExport/DataExportModal'
import {
  kytConsumerUserDataMapper,
  kytConsumerUserDataMapperForPDF,
} from '../dataExport/dataMappers/kytConsumerUserDataMapper'
import FiltersUsersConsumerForm from '../../../pages/kyt/components/FiltersUsersConsumerForm'
import dayjs from 'dayjs'
import { resetUsersFilters, updateUsersFilters } from '../../../features/users/userSlice'
import { formatDate } from '../../../pages/kyt/utils'

const DatatableKytUsersConsumer = () => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const { emailClient, activeServices } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.user.filters)

  const apiRef = useGridApiRef()
  const [canEdit, setCanEdit] = useState(false)
  const [isEditState, setIsEditState] = useState({})
  const [rowStates, setRowStates] = useState({})

  const [createUserEvent, { isLoading: isCreatingUserEvent, originalArgs: createUserEventArgs }] =
    useCreateConsumerUserEventMutation()

  const { data, isLoading, isFetching, refetch } = useFindKytUsersQuery({
    ...queryState,
    type: 'consumerUsers',
    emailClient,
    page,
    limit: rowsPerPage,
    offset: rowsPerPage * page,
  })

  const defaultFromDate = queryState.fromDate ? dayjs(queryState.fromDate) : null
  const defaultToDate = queryState.toDate ? dayjs(queryState.toDate) : null

  const { users, pagination } = useMemo(() => {
    if (data?.data) {
      return {
        users: data.data,
        pagination: data.pagination,
        unique: data.unique,
      }
    }
    return {
      users: [],
      pagination: {},
    }
  }, [data])

  const { uniqueKeys, uniqueValues } = useMemo(
    () => ({ uniqueKeys: data?.unique?.uniqueKeys || [], uniqueValues: data?.unique?.uniqueValues || [] }),
    [data?.unique],
  )

  useEffect(() => {
    if (Array.isArray(activeServices) && activeServices.indexOf('kytcreateConsumerUserEvent') > -1) {
      setCanEdit(true)
    }
  }, [activeServices])

  const handleCreateUserEvent = async (id, state) => {
    try {
      await createUserEvent({ id, state }).unwrap()

      setRowStates((prevRowStates) => ({
        ...prevRowStates,
        [id]: state,
      }))

      toaster.success(t('messages:creationSuccess'))
    } catch (err) {
      console.error(err)
      toaster.error(t('messages:creationError'))
    } finally {
      setIsEditState((prevState) => ({
        ...prevState,
        [id]: false,
      }))
    }
  }

  const handleDispatch = (values) => {
    const { fromDate, fromTime, toDate, toTime, ...filters } = values
    return dispatch(
      updateUsersFilters({
        viewMode: true,
        page,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        fromDate: formatDate(fromDate, fromTime),
        toDate: formatDate(toDate, toTime),
        ...filters,
      }),
    )
  }

  const handleResetButton = () => {
    setPage(0)
    dispatch(resetUsersFilters())
  }

  const columns = [
    {
      field: 'id',
      headerName: `${t('common:user')} ID`,
      minWidth: 200,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Link component={ReactRouterLink} to={`/users/${row.id}?type=consumer`}>
            <Typography variant="number">{row.id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'firstName',
      headerName: t('common:firstName'),
      minWidth: 260,
      sortable: false,
      renderCell: ({ row }) => {
        if (!row.firstName && !row.lastName) {
          return '---'
        }
        const firstName = row.firstName || ''
        const lastName = row.lastName || ''

        return `${firstName} ${lastName}`.trim()
      },
    },
    {
      field: 'dateOfBirth',
      minWidth: 150,
      sortable: false,
      headerName: t('common:dateOfBirth'),
      renderCell: ({ row }) => row.dateOfBirth ?? '---',
    },
    {
      field: 'countryOfResidence',
      headerName: t('common:countryOfResidence'),
      minWidth: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return Flags(row.countryOfResidence)
      },
    },
    {
      field: 'countryOfNationality',
      headerName: t('common:countryOfNationality'),
      minWidth: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return Flags(row.countryOfNationality)
      },
    },
    {
      field: 'createdTimestamp',
      headerName: t('common:createdAt'),
      minWidth: 200,
      sortable: false,
      renderCell: ({ row }) => {
        return <Typography variant="number">{toLocaleUTCDateString(row.createdTimestamp)}</Typography>
      },
    },
    {
      field: 'details',
      minWidth: 250,
      headerName: t('common:details'),
      hide: !canEdit,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        const isLoading = !!isCreatingUserEvent && createUserEventArgs?.id === row.id

        return (
          <EditStateUserRow
            isActive={isEditState[row.id] || false}
            canEdit={canEdit}
            isLoading={isLoading}
            currentValue={row.userStateDetails?.state}
            newValue={rowStates[row.id]}
            onChangeActive={(active) =>
              setIsEditState((prevState) => ({
                ...prevState,
                [row.id]: active,
              }))
            }
            onChangeRowState={(rowState) =>
              setRowStates((prevState) => ({
                ...prevState,
                [row.id]: rowState,
              }))
            }
            onSubmit={() => handleCreateUserEvent(row.id, rowStates[row.id])}
          />
        )
      },
    },
  ]

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Box className="filter-bar">
        <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setFiltersOpen(true)}>
          {t('common:filters')}
        </Button>
        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {activeServices.includes('reportsitem') && (
            <Button
              variant="outlined"
              disabled={!activeServices.includes('reportsitem')}
              onClick={() => setShowDownloadModal(true)}
              endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
            >
              <Typography variant="title2">{t('common:exportReport')}</Typography>
            </Button>
          )}
          <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
            <RefreshIcon color="action" />
          </IconButton>
        </Box>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={users || []}
        columns={columns}
        apiRef={apiRef}
        slots={{
          columnMenu: CustomColumnMenu,
          loadingOverlay: CustomLoadingOverlay,
        }}
        hideFooter={true}
        autoHeight
        loading={isLoading || isFetching}
      />
      <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        <div>
          <FiltersUsersConsumerForm
            defaultValues={{
              userId: queryState.userId,
              fromDate: defaultFromDate || null,
              fromTime: defaultFromDate ? defaultFromDate.format('HH:mm') : '00:00',
              toDate: defaultToDate || null,
              toTime: defaultToDate ? defaultToDate.format('HH:mm') : '23:59',
              firstName: queryState.firstName,
              middleName: queryState.middleName,
              lastName: queryState.lastName,
              countryOfResidence: queryState.countryOfResidence,
              countryOfNationality: queryState.countryOfNationality,
              documentNumber: queryState.documentNumber,
              userKycStatus: queryState.userKycStatus,
              selectedUserState: queryState.selectedUserState,
              userKey: queryState.userKey,
              userValue: queryState.userValue,
            }}
            setModalOpen={setFiltersOpen}
            handleResetButton={handleResetButton}
            handleDispatch={handleDispatch}
            setPage={setPage}
            uniqueKeys={uniqueKeys}
            uniqueValues={uniqueValues}
          />
        </div>
      </Modal>
      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={users}
            fileName="consumerUsers"
            dataMapper={kytConsumerUserDataMapper}
            dataMapperForPDF={kytConsumerUserDataMapperForPDF}
            typeDataExport="consumerUsers"
          />
        </div>
      </Modal>
    </Box>
  )
}

export default DatatableKytUsersConsumer
