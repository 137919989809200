import { TabContext, TabList } from '@mui/lab'
import { Chip, Tab } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

export const useGetRouterParam = (param = '', routes = [], defaultRoute) => {
  const [searchParams] = useSearchParams()
  const paramValue = searchParams.get(param)

  return routes.includes(paramValue) ? paramValue : defaultRoute
}

export const TabContextByParam = ({ param = '', currentValue, items, children }) => {
  const [_, setSearchParams] = useSearchParams()

  const handleUpdateParam = (_, newValue) => {
    setSearchParams({ [param]: newValue })
  }

  return (
    <TabContext value={currentValue}>
      <TabList
        TabIndicatorProps={{ style: { display: 'none' } }}
        sx={{ marginBottom: '1rem' }}
        onChange={handleUpdateParam}
      >
        {items.map(({ value, label }) => (
          <Tab
            key={value}
            label={<Chip label={label} variant={currentValue === value ? 'sliderSelected' : 'slider'} />}
            value={value}
            style={{ opacity: 1 }}
          />
        ))}
      </TabList>
      {children}
    </TabContext>
  )
}
