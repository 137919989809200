import translateObj from '../../../pages/kyb/translateObject.json'

export const camelCaseFormatter = (str) => {
  if (str) {
    const replacedStr = replaceForbiddenWords(str)
    const storageRoot = localStorage.getItem('persist:root')
    const storageLang = storageRoot ? JSON.parse(storageRoot).language : ''
    const lang = storageLang ? JSON.parse(storageLang) : ''

    if (lang.lang === 'pt-BR' && translateObj.hasOwnProperty(replacedStr)) {
      return translateObj[replacedStr]
    } else {
      let formattedStr = replacedStr?.replace(/([a-z])([A-Z])/g, '$1 $2')

      const firstChar = formattedStr?.charAt(0).toUpperCase()

      const remainingChars = formattedStr?.slice(1)

      return `${firstChar}${remainingChars}`
    }
  }
}

export const replaceForbiddenWords = (str) => {
  if (str) {
    return str
      .replace(/black/gi, (match) => (match[0] === match[0].toUpperCase() ? 'Block' : 'block'))
      .replace(/white/gi, (match) => (match[0] === match[0].toUpperCase() ? 'Allow' : 'allow'))
  }
}
