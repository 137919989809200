import dayjs from 'dayjs'

const FORMAT = 'YYYY-MM-DDTHH:mm:ss'

export const formatDate = (date, time) => {
  if (date && time) {
    const [hour, minute] = time.split(':')
    return dayjs(date).hour(hour).minute(minute).format(FORMAT)
  }

  return null
}
