import { countryDictionary } from '../common/flag/countries'
import currencies from '../kyt/json/currencies.json'

export const mapCodesToNames = (code) => {
  if (code) {
    if (currencies[code]) {
      return `${code} - ${currencies[code]}`
    }

    if (countryDictionary[code]) {
      return countryDictionary[code]
    }

    return code
  }
}
