import { CircularProgress, styled } from '@mui/material'

const StyledGridOverlay = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  backgroundColor: 'rgb(244, 248, 246, 0.5)',
}))

const CustomLoadingOverlay = () => (
  <StyledGridOverlay>
    <CircularProgress />
  </StyledGridOverlay>
)

export default CustomLoadingOverlay
