import { Box, TextField, Typography } from '@mui/material'
import { useController } from 'react-hook-form'
import { RenderTooltip } from '../../../utilities/RenderTooltip'
import styles from './RiskRangeNumberInputForm.module.scss'

const RiskRangeNumberInputForm = ({ range, rangesName, control, label, required, tooltip }) => {
  const {
    field: { value: ranges, onChange: onChangeRanges },
  } = useController({
    name: rangesName,
    control,
  })

  const onMinChange = (value) => {
    let updatedRanges = { ...ranges }
    const newValue = Number(value)

    updatedRanges[range].min = newValue

    if ((range === 'low' && newValue >= 0) || range !== 'low') {
      updatedRanges[range].min = newValue
    }

    if (range !== 'low') {
      const previousKey = Object.keys(updatedRanges)[Object.keys(updatedRanges).indexOf(range) - 1]
      updatedRanges[previousKey].max = newValue - 1
    }

    onChangeRanges(updatedRanges)
  }

  const onMaxChange = (value) => {
    let updatedRanges = { ...ranges }
    const newValue = Number(value)

    updatedRanges[range].max = newValue

    if (range !== 'veryHigh') {
      const nextKey = Object.keys(updatedRanges)[Object.keys(updatedRanges).indexOf(range) + 1]
      updatedRanges[nextKey].min = newValue + 1
    }

    onChangeRanges(updatedRanges)
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.label}>
        {!!label && <Typography variant="subtitle2">{label}</Typography>}
        {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
        {!!tooltip && <RenderTooltip title={tooltip} />}
      </Box>
      <Box className={styles.items}>
        <TextField fullWidth type="number" value={ranges[range]?.min} onChange={(e) => onMinChange(e.target.value)} />
        <TextField fullWidth type="number" value={ranges[range]?.max} onChange={(e) => onMaxChange(e.target.value)} />
      </Box>
    </Box>
  )
}

export default RiskRangeNumberInputForm
