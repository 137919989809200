import './transactionAmountThreshold.scss'
import Button from '@mui/material/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useEffect, useState } from 'react'
import { Autocomplete, Box, Fab, InputLabel, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'
import { CURRENCIES_MANUAL } from '../../../../../pages/kyt/transaction/transaction-constants'

const TransactionAmountThreshold = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  setError,
  errorList,
  setErrorList,
  isRequired,
  isEditing,
}) => {
  const [tat, setTat] = useState({})
  const [change, setChange] = useState(false)
  const [keyState, setKeyState] = useState(null)
  const [valueState, setValueState] = useState('')
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (ruleParameters[propertie]) {
      let aux = {}
      Object.keys(ruleParameters[propertie]).forEach((element) => {
        aux[element] = ruleParameters[propertie][element]
      })
      setTat(aux)
    }
  }, [ruleParameters, propertie])

  const handleKeyChange = (value, key) => {
    const aux = { ...tat }
    const aux2 = aux[key]

    delete aux[key]
    aux[value] = aux2
    setTat({ ...aux })
    setChange(true)
  }

  const handleValueChange = (value, key) => {
    const aux = { ...tat }
    aux[key] = value !== '' ? parseInt(value) : ''

    setTat({ ...aux })
    setChange(true)
  }

  const handleServiceRemove = (key) => {
    const aux = { ...tat }
    delete aux[key]

    setTat({ ...aux })
    setChange(true)
  }

  const handleServiceAdd = () => {
    let objAux = { ...tat }
    objAux[keyState] = valueState
    setTat({ ...objAux })
    setKeyState(null)
    setValueState('')
    setChange(true)
  }

  useEffect(() => {
    if (Object.keys(tat).length !== 0 && change) {
      let all = { ...ruleParameters }

      if (Object.keys(tat).length === 0) delete all[propertie]
      else all[propertie] = tat
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [tat, change])

  useEffect(() => {
    let list = []
    if (Object.keys(tat).length === 0 && isRequired) list.push(`The propertie ${propertie} is required`)

    Object.keys(tat).forEach((item) => {
      let aux = ''
      if (item === '') {
        aux = `${propertie} should not have an empty attribute.`
      }

      if (tat[item] === '') {
        aux = `${propertie}.${item} should not be empty.`
      }

      if (aux !== '' && !list.includes(aux)) {
        list.push(aux)
      }
    })

    let all = errorList
    if (list.length !== 0) {
      all[propertie] = list
      setErrorList({ ...all })
      setError(true)
    } else {
      delete all[propertie]
      setErrorList({ ...all })
      if (Object.keys(all).length === 0) setError(false)
    }
  }, [tat])

  return (
    <Box className="threshold-container">
      <Box className="threshold-inputs">
        <InputLabel>{t('common:currency')}</InputLabel>
        <Autocomplete
          options={CURRENCIES_MANUAL}
          getOptionLabel={(option) => mapCodesToNames(option)}
          filterSelectedOptions
          value={keyState}
          onChange={(e, value) => setKeyState(value)}
          disabled={!isEditing}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
            />
          )}
        />
      </Box>
      <Box className="threshold-inputs">
        <InputLabel>{t('common:amount')}</InputLabel>
        <TextField
          fullWidth
          disabled={!isEditing}
          variant="outlined"
          size="small"
          type="number"
          placeholder={t('common:amount')}
          value={valueState}
          onChange={(event) => setValueState(event.target.value !== '' ? parseInt(event.target.value) : '')}
        />
      </Box>
      <Box className="button-right">
        {isEditing && (
          <Button
            endIcon={<AddCircleOutlineIcon />}
            variant="outlinedGrey"
            disabled={keyState === '' || valueState === ''}
            onClick={handleServiceAdd}
          >
            {t('common:add')}
          </Button>
        )}
      </Box>

      {tat &&
        Object.keys(tat).map((tatKey, index) => (
          <Box key={index}>
            <Box className="threshold-inputs">
              <InputLabel>{t('common:currency')}</InputLabel>
              <Autocomplete
                options={CURRENCIES_MANUAL}
                getOptionLabel={(option) => mapCodesToNames(option)}
                filterSelectedOptions
                value={tatKey}
                onChange={(e, value) => handleKeyChange(value, tatKey)}
                disabled={!isEditing}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
                  />
                )}
              />
            </Box>
            <Box className="threshold-inputs">
              <InputLabel>{t('common:amount')}</InputLabel>
              <TextField
                fullWidth
                disabled={!isEditing}
                variant="outlined"
                size="small"
                type="number"
                value={tat[tatKey]}
                onChange={(event) => handleValueChange(event.target.value, tatKey)}
              />
            </Box>
            <Box className="button-right">
              {isEditing && (
                <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(tatKey)}>
                  <DeleteOutlineOutlinedIcon />
                </Fab>
              )}
            </Box>
          </Box>
        ))}
    </Box>
  )
}

export default TransactionAmountThreshold
