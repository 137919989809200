import { createSlice } from '@reduxjs/toolkit'
import { searchTypes } from '../../pages/kyb/constants'

export const kybPersonSlice = createSlice({
  name: 'kyc',
  initialState: {
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      status: 'ALL',
      nationality: '',
      showMyAssignments: false,
      searchType: searchTypes[0],
      searchText: '',
    },
  },
  reducers: {
    updateKycFilters: (state, action) => {
      state.filters = action.payload
    },
    addKycFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
  },
})

export const { updateKycFilters, addKycFilter } = kybPersonSlice.actions

export default kybPersonSlice.reducer
