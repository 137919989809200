import { Switch, styled } from '@mui/material'
const cloudOffSvg = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="22" height="22"><path fill="${encodeURIComponent(
  '#fff',
)}" d="M825.813333 428.16C796.8 281.386667 667.306667 170.666667 512 170.666667c-62.933333 0-121.6 18.56-171.093333 49.92l62.293333 62.293333A236.586667 236.586667 0 0 1 512 256c129.706667 0 234.666667 104.96 234.666667 234.666667v21.333333h64c70.613333 0 128 57.386667 128 128 0 48.426667-27.093333 90.026667-66.773334 111.786667l61.866667 61.866666C988.16 775.04 1024 711.893333 1024 640c0-112.64-87.68-203.946667-198.186667-211.84zM128 225.066667l117.333333 116.906666C109.226667 347.733333 0 459.733333 0 597.333333c0 141.44 114.56 256 256 256h500.266667l85.333333 85.333334L896 884.48 182.4 170.666667 128 225.066667zM329.6 426.666667l341.333333 341.333333H256c-94.293333 0-170.666667-76.373333-170.666667-170.666667s76.373333-170.666667 170.666667-170.666666h73.6z"/></svg>')`

const cloudOn = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="22" height="22"><path fill="${encodeURIComponent(
  '#fff',
)}" d="M825.813333 428.16C796.8 281.386667 667.306667 170.666667 512 170.666667c-123.306667 0-230.186667 69.973333-283.733333 172.16C100.053333 356.693333 0 465.28 0 597.333333c0 141.44 114.56 256 256 256h554.666667c117.76 0 213.333333-95.573333 213.333333-213.333333 0-112.64-87.68-203.946667-198.186667-211.84zM810.666667 768H256c-94.293333 0-170.666667-76.373333-170.666667-170.666667s76.373333-170.666667 170.666667-170.666666h30.293333c27.946667-98.346667 118.186667-170.666667 225.706667-170.666667 129.706667 0 234.666667 104.96 234.666667 234.666667v21.333333h64c70.613333 0 128 57.386667 128 128s-57.386667 128-128 128z"/></svg>')`

export const MaterialUISwitch = styled(Switch)(({ theme, themeMode }) => ({
  width: 56,
  height: 28,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 0,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: cloudOn,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: themeMode === 'light' ? '#aab4be' : '#8796A5',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'var(--lile)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'var(--dark-lile)',
    width: 28,
    height: 28,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: cloudOffSvg, // Use the cloudOffSvg here
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: themeMode === 'light' ? '#aab4be' : '#8796A5',
    borderRadius: 20 / 2,
  },
}))

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, themeMode }) => ({
  width: 37,
  height: 21,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#00C43D',
        opacity: 1,
        border: 0,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#171B18',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: themeMode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: themeMode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 17,
    height: 17,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: themeMode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))
