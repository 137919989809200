import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useMerchants } from '../../../../../pages/pages-hooks/useMerchants'
import { useTranslation } from 'react-i18next'

const ListOfMccIds = ({ propertie, ruleParameters, setRuleParameters, isEditing }) => {
  const { activeServices } = useSelector((state) => state.login)
  const [selectedMccList, setSelectedMccList] = useState({})
  const [change, setChange] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  useMerchants(setIsLoading, isLoading)
  const merchants = useSelector((state) => state.merchants?.merchants?.data)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (!change && merchants && ruleParameters[propertie]) {
      const selectedCL = Array.isArray(merchants) ? merchants?.find((cl) => cl.id === ruleParameters[propertie]) : {}
      if (Object.keys(selectedCL).length) setSelectedMccList(selectedCL)
    }
  }, [ruleParameters])

  const handleChange = (evt) => {
    const {
      target: { value },
    } = evt

    setSelectedMccList(value)
    setChange(true)
  }

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      all[propertie] = selectedMccList.id

      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [change])

  return (
    <Box>
      <Select
        size="small"
        displayEmpty
        fullWidth
        disabled={!isEditing || !activeServices?.some((path) => path.includes('merchants'))}
        value={(Array.isArray(merchants) && merchants.find((m) => m.id === selectedMccList.id)) || ''}
        onChange={handleChange}
        renderValue={(selectedMccList) => {
          return selectedMccList?.title ? (
            `${selectedMccList.title} - ${selectedMccList.id}`
          ) : (
            <Typography variant="subtitle3">
              {t('common:select')} {t('common:listId')}
            </Typography>
          )
        }}
      >
        {merchants &&
          Array.isArray(merchants) &&
          merchants?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {`${item.title}(${item.id})`}
            </MenuItem>
          ))}
      </Select>
    </Box>
  )
}

export default ListOfMccIds
