import './mccListBusinessHours.scss'
import { useEffect, useState } from 'react'
import { Box, MenuItem, Select, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMerchants } from '../../../../../pages/pages-hooks/useMerchants'
import { useSelector } from 'react-redux'
import CheckboxItem from '../../../rules/ruleParameters2/checkbox/checkbox'

const MCCBusinessHours = ({ propertie, ruleParameters, setRuleParameters, isEditing, filterTemplate, description }) => {
  const { activeServices } = useSelector((state) => state.login)
  const [businessHours, setBusinessHours] = useState({})
  const [selectedMccList, setSelectedMccList] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  useMerchants(setIsLoading, isLoading)
  const merchants = useSelector((state) => state.merchants?.merchants?.data)
  const { t } = useTranslation(['common', 'messages'])

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux && aux[propertie]) {
      const propData = aux[propertie]
      const selectedMerchant = merchants?.find((m) => m.id === propData.listId) || ''
      setSelectedMccList(selectedMerchant)
      setBusinessHours(propData.isBusinessHours ? { isBusinessHours: propData.isBusinessHours } : {})
    } else {
      setSelectedMccList('')
      setBusinessHours({})
    }
  }, [ruleParameters, propertie, filterTemplate, merchants])

  const handleChange = (evt) => {
    const {
      target: { value },
    } = evt
    setSelectedMccList(value)
    updateRuleParameters(value, businessHours)
  }

  const handleCheckboxChange = (value) => {
    setBusinessHours(value)
    updateRuleParameters(selectedMccList, value)
  }

  const updateRuleParameters = (listId, businessHoursState) => {
    const updatedParameters = ruleParameters.map((param) =>
      param.filterTemplate === filterTemplate
        ? { ...param, [propertie]: { ...businessHoursState, listId: listId?.id } }
        : param,
    )

    if (!updatedParameters.some((param) => param.filterTemplate === filterTemplate) && listId) {
      updatedParameters.push({
        [propertie]: { ...businessHoursState, listId: listId?.id },
        filterTemplate,
      })
    }

    if (!listId && !Object.keys(businessHoursState).length) {
      setRuleParameters(updatedParameters.filter((param) => param.filterTemplate !== filterTemplate))
    } else {
      setRuleParameters(updatedParameters)
    }
  }

  return (
    <Box className="mcc-container">
      {description && <Typography variant="subtitle4">{description}</Typography>}

      <Select
        size="small"
        displayEmpty
        fullWidth
        disabled={!isEditing || !activeServices?.some((path) => path.includes('merchants'))}
        value={selectedMccList || ''}
        onChange={handleChange}
        renderValue={(selected) =>
          selected?.title ? `${selected.title} - ${selected.id}` : t('common:select') + ' ' + t('common:listId')
        }
      >
        {merchants &&
          Array.isArray(merchants) &&
          merchants.map((item) => (
            <MenuItem key={item.id} value={item}>
              {`${item.title}(${item.id})`}
            </MenuItem>
          ))}
      </Select>

      <CheckboxItem
        isEditing={isEditing}
        propertie={'isBusinessHours'}
        ruleParameters={businessHours}
        setRuleParameters={handleCheckboxChange}
        label={t('common:businessHours')}
        text={t('messages:mcc.checkEnabled')}
      />
    </Box>
  )
}

export default MCCBusinessHours
