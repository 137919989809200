import '../home/home.scss'
import './batch.scss'
import { useState } from 'react'
import { Box, Button, Modal, Paper, Typography } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline'
import { useTranslation } from 'react-i18next'
import DatatableBatch from '../../components/common/datatable/DatatableBatch'
import ModalCreteBatch from './components/ModalCreateBatch'
import { useCreateBatchMutation } from '../../services/guenoApi'

function Batch() {
  const { t } = useTranslation(['common'])
  const [showCreateBatch, setShowCreateBatch] = useState(false)
  const [createBatch] = useCreateBatchMutation({ fixedCacheKey: 'create-batch' })

  const handleCreateBatch = async (batchType, file, extraOptions = {}) => {
    await createBatch({
      file,
      batchType,
      skipErrors: extraOptions?.skipErrors,
    })
    setShowCreateBatch(false)
  }

  return (
    <Box className={`filter`}>
      <Box>
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:batchLoading')}</Typography>
            <Button
              variant="outlined"
              onClick={() => {
                setShowCreateBatch(true)
              }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
            >
              {`${t('common:CREATE_BATCH')}`}
            </Button>
          </Box>
        </Box>
        <Box className="batch_table">
          <Paper elevation={0}>
            <DatatableBatch />
          </Paper>
        </Box>
      </Box>
      <Modal open={showCreateBatch} onClose={() => setShowCreateBatch(false)}>
        <div>
          <ModalCreteBatch setShowCreateBatch={setShowCreateBatch} handleCreateBatch={handleCreateBatch} />
        </div>
      </Modal>
    </Box>
  )
}

export default Batch
