import { Modal } from '@mui/material'
import { FilterModalContainer } from './FilterModalContainer'

export const FilterModal = ({ children, modalOpen, setModalOpen, handleResetButton, setPage, handleDispatch }) => (
  <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
    <div>
      <FilterModalContainer
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        {children}
      </FilterModalContainer>
    </div>
  </Modal>
)
