import { Box, TextField, Typography } from '@mui/material'
import { Controller, useController } from 'react-hook-form'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useSelector } from 'react-redux'
import { RenderTooltip } from '../../../utilities/RenderTooltip'
import styles from './DateTimeInputForm.module.scss'

const DateTimeInputForm = ({ name, timeName, control, label, tooltip = '', required = false }) => {
  const languageState = useSelector((state) => state.language.lang)
  const { field: timeField } = useController({
    name: timeName,
    control,
  })

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box className={styles.container}>
            <Box className={styles.label}>
              <Typography variant="subtitle2">{label}</Typography>
              {!!required && <Typography variant="subtitle3">(*)</Typography>}
              {!!tooltip && <RenderTooltip title={tooltip} />}
            </Box>
            <LocalizationProvider
              size="small"
              dateAdapter={AdapterDayjs}
              adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
            >
              <Box>
                <DatePicker size="small" slotProps={{ textField: { size: 'small' } }} {...field} />
                <TextField
                  disabled={!field.value}
                  type="time"
                  step="1"
                  value={timeField.value}
                  onChange={(e) => timeField.onChange(e.target.value)}
                  style={{ marginLeft: 10 }}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        )
      }}
    />
  )
}

export default DateTimeInputForm
