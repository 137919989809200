export const defaultValuesByType = {
  string: '',
  list: null,
  number: 0,
  boolean: true,
  number_range: { min: 0, max: 100 },
  list_sizes_descriptions: '',
  custom_list: '',
}

export const inputTypeByOperator = {
  includes: 'string',
  includes_any: 'list',
  includes_all: 'list',
  any_includes: 'string',
  any_not_includes: 'string',
  any_not_equals: 'string',
  any_includes_any: 'list',
  all_includes_all: 'list',
  max_unique_values: 'number',
  is: 'boolean',
  any_is_number: 'number',
  equals: 'string',
  equals_number: 'number',
  less_than_number: 'number',
  less_equal_than_number: 'number',
  greater_than_number: 'number',
  greater_equal_than_number: 'number',
  between_inclusive_numbers: 'number_range',
  between_exclusive_numbers: 'number_range',
  is_size_description: 'list_sizes_descriptions',
  in_custom_list: 'custom_list',
  in_list: 'list',
  any_greater_than_number: 'number',
  any_greater_equal_than_number: 'number',
}

export const kybAttributes = {
  category_types: {
    operators: ['any_includes', 'any_includes_any', 'max_unique_values'],
  },
  category_descriptions: {
    operators: ['any_includes', 'any_includes_any'],
  },
  category_secondary_types_distint: {
    operators: ['equals_number', 'greater_equal_than_number'],
  },
  is_currently_pep: {
    operators: ['is'],
  },
  last_beneficiaries_pep_levels: {
    operators: ['any_is_number'],
  },
  last_beneficiaries_pep_history_times: {
    operators: ['any_greater_than_number', 'any_greater_equal_than_number'],
  },
  last_beneficiaries_japs: {
    operators: ['any_includes_any'],
  },
  last_beneficiaries_registration_status_names: {
    operators: ['any_not_includes', 'any_not_equals'],
  },
  legal_organization_description: {
    operators: ['equals', 'includes'],
  },
  size_description: {
    operators: ['is_size_description'],
  },
  registration_uptime_months: {
    operators: [
      'less_than_number',
      'less_equal_than_number',
      'greater_than_number',
      'greater_equal_than_number',
      'between_inclusive_numbers',
      'between_exclusive_numbers',
    ],
  },
  company_city: {
    operators: ['in_custom_list'],
  },
  company_country: {
    operators: ['in_custom_list'],
  },
  company_cluster: {
    operators: ['includes', 'equals'],
  },
  company_business_segments: {
    operators: ['any_includes_any', 'any_includes'],
  },
  company_client_types: {
    operators: ['any_includes_any', 'any_includes'],
  },
  // Cantidad de socios PJ (empresas) que no son QSA -> number
  relationships_business_qty_no_qsa: {
    operators: ['greater_than_number', 'greater_equal_than_number'],
  },
  // Shareholders personas con role (SOCIO-ADMINISTRADOR | ADMINISTRADOR) en otras empresas con situation Ativa -> array[number] any is greater than (2)
  shareholders_person_administrator_active: {
    operators: ['any_greater_than_number', 'any_greater_equal_than_number'],
  },
  // shareholders empresas con status diferente a Ativo -> number
  shareholders_business_qty_no_active: {
    operators: ['greater_than_number', 'greater_equal_than_number'],
  },
  company_is_mei: {
    operators: ['is'],
  },
  last_beneficiaries_history_sanctions: {
    operators: ['any_includes_any', 'any_includes', 'in_custom_list'],
  },
  // Los Main Subject de los shareholders -> array[strins] any_includes_any ['Lavagem de Dinheiro', 'Corrupção', 'Organizações Criminosas']
}

export const kybAttributesList = Object.keys(kybAttributes).map((key) => ({ key, operators: kybAttributes[key] }))
