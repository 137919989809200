import { Box, MenuItem, Select, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import SelectInputForm from '../SelectInputForm/SelectInputForm'
import { useFindAllListsQuery } from '../../../../features/lists/listsSlice'
import { RenderTooltip } from '../../../utilities/RenderTooltip'

const SelectListInputForm = ({ type = 'neutral', context = 'riskMatrix', ...props }) => {
  const { emailClient } = useSelector((state) => state.login)

  const { data, isFetching, isLoading } = useFindAllListsQuery({
    emailClient,
    type,
    context,
  })

  const options = useMemo(() => {
    if (Array.isArray(data?.data)) {
      return data?.data?.map((item) => ({
        label: (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{item.title}</Typography>
            <RenderTooltip
              title={
                <Box>
                  <Typography variant="h5">{item.description}:</Typography>
                  <Typography variant="body2">{item.list.join(', ')}</Typography>
                </Box>
              }
            />
          </Box>
        ),
        value: item.id,
      }))
    }
    return []
  }, [data])

  if (isFetching || isLoading) {
    return (
      <Select displayEmpty options={[]} value="">
        <MenuItem disabled value="">
          <Typography variant="subtitle3">Loading...</Typography>
        </MenuItem>
      </Select>
    )
  }

  return <SelectInputForm options={options} {...props} />
}

export default SelectListInputForm
