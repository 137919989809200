import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { Controller } from 'react-hook-form'
import { RenderTooltip } from '../../../utilities/RenderTooltip'
import styles from './CheckInputForm.module.scss'

const CheckInputForm = ({ name, control, label, tooltip = '', required = false }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box className={styles.container}>
            <Box className={styles.label}>{!!tooltip && <RenderTooltip title={tooltip} />}</Box>
            <FormGroup>
              <FormControlLabel
                required={required}
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={label}
                labelPlacement="start"
              />
            </FormGroup>
          </Box>
        )
      }}
    />
  )
}

export default CheckInputForm
