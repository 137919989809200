import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import demoData from './demoData'
import { buildKybParams } from './builders'

const baseUrl = process.env.REACT_APP_BASEURL

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = getState().login
    headers.set('Authorization', `Bearer ${accessToken}`)
    return headers
  },
  fetchFn: async (...args) => {
    if (process.env.REACT_APP_IS_DEMO) {
      const url = new URL(args[0]?.url)
      const body = demoData[args[0]?.method][url?.pathname] || {}
      return new Response(JSON.stringify(body), {
        status: 200,
        statusText: 'OK',
        headers: {
          'content-type': 'application/json; charset=utf-8',
        },
      })
    }
    return fetch(...args)
  },
})

export const guenoApi = createApi({
  reducerPath: 'guenoApi',
  baseQuery,
  keepUnusedDataFor: 5,
  tagTypes: [
    'subjectsByKyb',
    'kybPerson',
    'kybBusiness',
    'kycPersonList',
    'kycPerson',
    'kycPersonChecks',
    'kycCheck',
    'kytImportItems',
    'kyt-consumerUsers',
    'kyt-businessUsers',
    'riskMatrixById',
  ],
  endpoints: (builder) => ({
    findKybs: builder.query({
      query: ({ limit, offset, page, sortField, sortDirection, countrySegment, ...query }) => {
        const searchParams = buildKybParams(query)

        return {
          url: `/kyb${countrySegment}`,
          params: {
            limit,
            offset,
            page,
            sortField,
            sortDirection,
            ...searchParams,
          },
        }
      },
    }),
    createKyb: builder.mutation({
      query: ({ countrySegment, isKyb, body }) => {
        let url = `/kyb${countrySegment}`

        if (!isKyb) {
          url += 'subject'
        }

        return {
          url,
          method: 'POST',
          body,
        }
      },
    }),
    createManualKyb: builder.mutation({
      query: ({ countrySegment, body }) => {
        return {
          url: `/kyb${countrySegment}manual`,
          method: 'POST',
          body,
        }
      },
    }),
    updateKyb: builder.mutation({
      query: ({ id, countrySegment, body }) => {
        const url = `/kyb${countrySegment}${id}`
        return {
          url,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: ['kybBusiness'],
    }),
    updateKybPerson: builder.mutation({
      query: ({ id, countrySegment, body }) => {
        const url = `/kyb${countrySegment}subject/${id}`
        return {
          url,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: ['kybPerson'],
    }),
    updateKybLastBeneficiaries: builder.mutation({
      query: ({ kybId, documentNumber, operation }) => {
        const url = `/kyb/${kybId}/lastBeneficiary/${documentNumber}/${operation}`

        return {
          url,
          method: 'PATCH',
        }
      },
      invalidatesTags: ['kybBusiness'],
    }),
    findKycs: builder.query({
      query: ({ limit, offset, page, sortField, sortDirection, countrySegment, ...query }) => {
        const searchParams = buildKybParams(query)

        return {
          url: `/kyb${countrySegment}all/subjects`,
          params: {
            limit,
            offset,
            page,
            sortField,
            sortDirection,
            ...searchParams,
          },
        }
      },
    }),
    findChecksByKybAndType: builder.query({
      query: ({ kybId, type, countrySegment }) => ({
        url: `/kyb${countrySegment}${kybId}/checks`,
        params: {
          type,
        },
      }),
    }),
    findUserMaster: builder.query({
      query: ({ emailClient }) => {
        return {
          url: `/usermaster/${emailClient}`,
        }
      },
    }),
    updateUserMaster: builder.mutation({
      query: ({ emailClient, body }) => {
        return {
          url: `/usermaster/${emailClient}`,
          method: 'PATCH',
          body,
        }
      },
    }),
    updateKybSubject: builder.mutation({
      query: ({ subjectId, body }) => {
        let url = `/kyb/subject/${subjectId}`

        return {
          url,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: ['subjectsByKyb'],
    }),
    deleteSubjectKyb: builder.mutation({
      query: ({ kybId, subjectId, relationType, countrySegment }) => {
        let url = `/kyb${countrySegment}${kybId}/subject/${subjectId}?relationType=${relationType}`

        return {
          url,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['subjectsByKyb'],
    }),
    findSubjectsByKyb: builder.query({
      query: ({ id, selectedTab, entityType, page, limit, sortField, sortDirection, countrySegment }) => {
        let url = `/kyb${countrySegment}${id}/${entityType}/${selectedTab}`
        return {
          url,
          params: {
            page,
            limit,
            sortField,
            sortDirection,
          },
        }
      },
      providesTags: ['subjectsByKyb'],
    }),
    getKybsReport: builder.mutation({
      query: ({ countrySegment, fromDate, endDate }) => ({
        url: `/kyb${countrySegment}report`,
        method: 'GET',
        params: {
          fromDate,
          endDate,
        },
        responseHandler: (response) => response.text(),
      }),
    }),
    runKyb: builder.mutation({
      query: ({ documentNumber, subjectId, countrySegment }) => {
        return {
          url: `/kyb${countrySegment}`,
          method: 'POST',
          body: {
            documentNumber,
            subjectId,
          },
        }
      },
    }),
    restartKyb: builder.mutation({
      query: ({ countrySegment, kybId }) => ({
        url: `/kyb${countrySegment}${kybId}/restart`,
        method: 'PATCH',
        body: {},
      }),
      invalidatesTags: ['kybBusiness', 'subjectsByKyb'],
    }),
    restartKybSubject: builder.mutation({
      query: ({ countrySegment, id }) => ({
        url: `/kyb${countrySegment}subject/${id}/restart`,
        method: 'PATCH',
        body: {},
      }),
      invalidatesTags: ['kybPerson', 'subjectsByKyb'],
    }),
    findBatches: builder.query({
      query: ({ emailClient, limit, offset, page, type, status }) => {
        let params = {
          emailClient,
          limit,
          offset,
          page,
        }

        if (type) {
          params.type = type
        }

        if (status) {
          params.status = status
        }

        return {
          url: `/batch/userBatches`,
          params,
        }
      },
    }),
    createBatch: builder.mutation({
      query: ({ batchType, file, skipErrors }) => {
        const formData = new FormData()
        formData.append('file', file)

        if (batchType === 'TRANSACTION' && skipErrors) {
          formData.append('skipErrors', true)
        }

        return {
          url: `/batch/kyt/${batchType.toLocaleLowerCase()}`,
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
    }),
    deleteBatch: builder.mutation({
      query: ({ id }) => ({
        url: `/batch/deepDelete/${id}`,
        method: 'DELETE',
        body: {},
      }),
    }),
    getBatchById: builder.query({
      query: ({ id }) => ({
        url: `/batch/getBatch/${id}`,
      }),
    }),
    findKytUserActions: builder.query({
      query: ({ id, page, limit }) => {
        let url = `/kyt/userActions/${id}`
        return {
          url,
          params: {
            page,
            limit,
          },
        }
      },
    }),
    getKybNotifications: builder.query({
      query: ({ countrySegment, ...params }) => ({
        url: `/kyb${countrySegment}all/notifications`,
        method: 'GET',
        params,
      }),
    }),
    findAvailableUsers: builder.query({
      query: () => ({
        url: `/user/toAssign`,
      }),
    }),
    findKybPersonById: builder.query({
      query: ({ id, countrySegment, languageState }) => ({
        url: `/kyb${countrySegment}subject/${id}`,
        params: {
          ...(languageState === 'pt-BR' && {
            lang: 'PT',
          }),
        },
      }),
      providesTags: ['kybPerson'],
    }),
    findKybBusinessById: builder.query({
      query: ({ id, countrySegment, languageState }) => ({
        url: `/kyb${countrySegment}${id}`,
        params: {
          ...(languageState === 'pt-BR' && {
            lang: 'PT',
          }),
        },
      }),
      providesTags: ['kybBusiness'],
    }),
    checkKyb: builder.mutation({
      query: ({ countrySegment, body }) => ({
        url: `/kyb${countrySegment}check`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['kybPerson', 'kybBusiness', 'subjectsByKyb'],
    }),
    findReportByKyb: builder.query({
      query: ({ id, currentCountry, entity, documentNumber }) => ({
        url: `/kyb/v2/${currentCountry}/${id}/report`,
        params: {
          entity,
          documentNumber,
        },
      }),
    }),
    createNewComment: builder.mutation({
      query: ({ countrySegment, id, body }) => ({
        url: `/kyb${countrySegment}subject/${id}/comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['kybPerson'],
    }),
    createNewCommentBusiness: builder.mutation({
      query: ({ countrySegment, id, body }) => ({
        url: `/kyb${countrySegment}${id}/comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['kybBusiness'],
    }),
    updateCheck: builder.mutation({
      query: ({ countrySegment, checkId, body }) => ({
        url: `/kyb${countrySegment}check/${checkId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['kybPerson', 'kybBusiness'],
    }),
    deleteFilePerson: builder.mutation({
      query: ({ countrySegment, id, key }) => {
        let url = `/kyb${countrySegment}subject/${id}/file/${key}`
        return {
          url,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['kybPerson'],
    }),
    deleteFileBusiness: builder.mutation({
      query: ({ countrySegment, id, key }) => {
        let url = `/kyb${countrySegment}${id}/file/${key}`
        return {
          url,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['kybBusiness'],
    }),
    uploadFilePerson: builder.mutation({
      query: ({ countrySegment, id, body }) => {
        let url = `/kyb${countrySegment}subject/${id}/file`
        return {
          url,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['kybPerson'],
    }),
    uploadFileBusiness: builder.mutation({
      query: ({ countrySegment, id, body }) => {
        let url = `/kyb${countrySegment}${id}/file`
        return {
          url,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['kybBusiness'],
    }),
    updateIsPrivateKyb: builder.mutation({
      query: ({ countrySegment, id, body }) => {
        let url = `/kyb${countrySegment}${id}/private`
        return {
          url,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: ['kybBusiness'],
    }),
    updateRiskLevel: builder.mutation({
      query: ({ countrySegment, id, lvl }) => {
        let url = `/kyb${countrySegment}${id}/riskLevel/${lvl}`
        return {
          url,
          method: 'PATCH',
        }
      },
      invalidatesTags: ['kybBusiness'],
    }),
    runRiskMatrix: builder.mutation({
      query: ({ countrySegment, id }) => {
        return {
          url: `/kyb${countrySegment}${id}/riskMatrix`,
          method: 'POST',
        }
      },
      invalidatesTags: ['kybBusiness'],
    }),
    createMCC: builder.mutation({
      query: ({ file, listId }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/admin/merchants/${listId}/mcc`,
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
    }),
    getMCCsbyListId: builder.mutation({
      query: ({ listId }) => {
        return {
          url: `/admin/merchants/${listId}/mcc`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const {
  useFindKybsQuery,
  useFindKycsQuery,
  useCreateKybMutation,
  useCreateManualKybMutation,
  useUpdateKybMutation,
  useUpdateKybLastBeneficiariesMutation,
  useLazyFindChecksByKybAndTypeQuery,
  useFindUserMasterQuery,
  useUpdateUserMasterMutation,
  useUpdateKybSubjectMutation,
  useDeleteSubjectKybMutation,
  useFindSubjectsByKybQuery,
  useGetKybsReportMutation,
  useRunKybMutation,
  useRestartKybMutation,
  useRestartKybSubjectMutation,
  useFindBatchesQuery,
  useCreateBatchMutation,
  useDeleteBatchMutation,
  useGetBatchByIdQuery,
  useFindKytUserActionsQuery,
  useGetKybNotificationsQuery,
  useFindKybPersonByIdQuery,
  useFindAvailableUsersQuery,
  useUpdateKybPersonMutation,
  useCheckKybMutation,
  useCreateNewCommentMutation,
  useCreateNewCommentBusinessMutation,
  useUpdateCheckMutation,
  useDeleteFilePersonMutation,
  useDeleteFileBusinessMutation,
  useUploadFilePersonMutation,
  useUploadFileBusinessMutation,
  useFindKybBusinessByIdQuery,
  useUpdateIsPrivateKybMutation,
  useUpdateRiskLevelMutation,
  useRunRiskMatrixMutation,
  useCreateMCCMutation,
  useGetMCCsbyListIdMutation,
  useFindReportByKybQuery,
} = guenoApi

export default guenoApi
