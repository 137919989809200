import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'

const CheckDetailTitle = ({ email, status, createdAt, updatedAt }) => {
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Box className="detail-group-grid-4">
      <Box>
        <span>{t('common:executedBy')}</span>
        <p>{email ?? '---'}</p>
      </Box>
      <Box>
        <span>{t('common:status')}</span>
        <p>{status ?? '---'}</p>
      </Box>
      <Box>
        <span>{t('common:creationDate')}</span>
        <p>{buildTimeStringFromTimestamp(createdAt) ?? '---'}</p>
      </Box>
    </Box>
  )
}

export default CheckDetailTitle
