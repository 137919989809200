import './listOfStrings.scss'
import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Fab, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ListOfStrings = ({ propertie, ruleParameters, setRuleParameters, defaultListValues, isEditing }) => {
  const [updatedList, setUpdatedList] = useState([])
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (Array.isArray(ruleParameters) && JSON.stringify(ruleParameters) !== JSON.stringify(updatedList)) {
      setUpdatedList(ruleParameters.length ? [...ruleParameters] : defaultListValues || [])
    }
  }, [ruleParameters, defaultListValues, propertie])

  const handleCurrenciesChange = (currency, index) => {
    const newList = [...updatedList]
    newList[index] = currency
    setUpdatedList(newList)
    setRuleParameters(newList)
  }

  const handleMovement = (up, index) => {
    const newList = [...updatedList]
    const temp = newList[index]
    const swapIndex = up ? index - 1 : index + 1

    newList[index] = newList[swapIndex]
    newList[swapIndex] = temp
    setUpdatedList(newList)
    setRuleParameters(newList)
  }

  const handleServiceRemove = (index) => {
    const newList = [...updatedList]
    newList.splice(index, 1)
    setUpdatedList(newList)
    setRuleParameters(newList)
  }

  const handleServiceAdd = () => {
    const newList = [...updatedList, '']
    setUpdatedList(newList)
    setRuleParameters(newList)
  }

  return (
    <Box className="list-strings-container">
      {updatedList.length !== 0 &&
        updatedList?.map((item, index) => (
          <Box className="list-row" key={index}>
            <TextField
              fullWidth
              type="text"
              disabled={!isEditing}
              variant="outlined"
              size="small"
              value={item || ''}
              onChange={(event) => handleCurrenciesChange(event.target.value, index)}
            />
            {isEditing && (
              <Box className="buttons">
                {index === 0 && updatedList.length > 1 && (
                  <Button disabled>
                    <ArrowUpwardIcon />
                  </Button>
                )}
                {index !== 0 && updatedList.length > 1 && (
                  <Button onClick={() => handleMovement(true, index)}>
                    <ArrowUpwardIcon />
                  </Button>
                )}
                {index !== updatedList.length - 1 && updatedList.length > 1 && (
                  <Button onClick={() => handleMovement(false, index)}>
                    <ArrowDownwardIcon />
                  </Button>
                )}
                {index === updatedList.length - 1 && updatedList.length > 1 && (
                  <Button disabled>
                    <ArrowDownwardIcon />
                  </Button>
                )}

                <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(index)}>
                  <DeleteOutlineOutlinedIcon />
                </Fab>
              </Box>
            )}
          </Box>
        ))}
      <Box className="button-right">
        {isEditing && (
          <Button endIcon={<AddCircleOutlineIcon />} variant="outlinedGrey" onClick={handleServiceAdd}>
            {t('common:add')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default ListOfStrings
