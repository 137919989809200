import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTemporalItems } from '../../features/batch/batchSlice'

export const useBatchTemporalItems = (setIsLoading, isLoading, id) => {
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const batchesState = useSelector((state) => state.batch.temporalItems)
  const temporalFilters = useSelector((state) => state.batch?.temporalFilters)

  const { limit, offset, page, status } = temporalFilters
  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?emailClient=${emailClient}&limit=${limit}&page=${page}&offset=${offset}`
    if (status !== '' && status !== undefined) url += `&status=${status}`
    return url
  }

  useEffect(() => {
    if (isLoading) {
      const baseUrl = `${process.env.REACT_APP_BASEURL}/batch/temporalItems/${id}`

      let urlGetItems = buildUrl(baseUrl)

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlGetItems, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            const { data, pagination } = res
            const aux = { data, pagination }
            dispatch(updateTemporalItems(aux))
            setIsLoading(false)
          }
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [temporalFilters, isLoading])

  return batchesState.temporalItems
}
