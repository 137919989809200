import guenoApi from '../../services/guenoApi'

const kycApi = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    createKytImportItem: builder.mutation({
      query: ({ type, file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/kyt-import/${type}`,
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
      invalidatesTags: ['kytImportItems'],
    }),
    findAllKytImportItems: builder.query({
      query: ({ page, limit }) => ({
        url: '/kyt-import',
        method: 'GET',
        params: {
          page,
          limit,
        },
      }),
      providesTags: ['kytImportItems'],
    }),
  }),
})

export const { useCreateKytImportItemMutation, useFindAllKytImportItemsQuery } = kycApi
