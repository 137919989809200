import { useState, useEffect } from 'react'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'

const SelectSingle = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  posibleValues,
  desc,
  isEditing,
  filterTemplate,
}) => {
  const [selected, setSelected] = useState(null)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux && aux[propertie] !== selected) {
      setSelected(aux[propertie] || null)
    }

    if (!aux && selected !== null) {
      setSelected(null)
    }
  }, [ruleParameters, filterTemplate, propertie])

  const handleChange = (event, value) => {
    setSelected(value)

    const updatedParameters = ruleParameters.map((param) =>
      param.filterTemplate === filterTemplate ? { ...param, [propertie]: value } : param,
    )

    if (!updatedParameters.some((param) => param.filterTemplate === filterTemplate)) {
      updatedParameters.push({ [propertie]: value, filterTemplate })
    }

    setRuleParameters(updatedParameters)
  }

  return (
    <Box className="select-single-container">
      {desc && <Typography variant="subtitle4">{desc}</Typography>}

      <Autocomplete
        options={posibleValues}
        getOptionLabel={(option) => mapCodesToNames(option)}
        filterSelectedOptions
        value={selected}
        disabled={!isEditing}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
          />
        )}
      />
    </Box>
  )
}

export default SelectSingle
