import { Box, Button, MenuItem, Select, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { userStateValues } from '../constants'

const EditStateUserRow = ({
  isActive,
  canEdit,
  isLoading,
  currentValue,
  newValue,
  onChangeActive,
  onChangeRowState,
  onSubmit,
}) => {
  const { t } = useTranslation(['common'])

  return (
    <>
      {isActive ? (
        <Box className="state-edit-container">
          <Box className="state-edit-container">
            <LoadingButton
              variant="outlinedButtonSuccess"
              disabled={!newValue || newValue === currentValue}
              onClick={() => onSubmit()}
              loading={isLoading}
            >
              <DoneIcon />
            </LoadingButton>
            <LoadingButton variant="outlinedButtonError" onClick={() => onChangeActive(false)} loading={isLoading}>
              <CloseIcon />
            </LoadingButton>
            <Select
              size="small"
              disabled={!canEdit || isLoading}
              displayEmpty
              value={newValue || currentValue} // Use rowState here
              defaultValue={currentValue}
              onChange={(e) => onChangeRowState(e.target.value)}
            >
              <MenuItem disabled value="">
                <Typography variant="subtitle3">{t('common:state')}</Typography>
              </MenuItem>
              {Object.entries(userStateValues).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  <Typography variant="subtitle1">{value}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      ) : (
        <Box className="state-container">
          <Button variant="outlinedButtonSuccess" onClick={() => onChangeActive(true)}>
            <EditIcon />
          </Button>
          <Typography variant="body2">{currentValue ? currentValue : '---'}</Typography>
        </Box>
      )}
    </>
  )
}

export default EditStateUserRow
