import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { RenderTooltip } from '../../../utilities/RenderTooltip'
import styles from './AutocompleteInputForm.module.scss'

const AutocompleteInputForm = ({
  name,
  control,
  label,
  placeholder = '',
  tooltip = '',
  options = [],
  required = false,
  disabled = false,
  getOptionLabel = (option) => option.label ?? option,
  multiple,
  ...params
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Box className={styles.container}>
          <Box className={styles.label}>
            {!!label && <Typography variant="subtitle2">{label}</Typography>}
            {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
            {!!tooltip && <RenderTooltip title={tooltip} />}
          </Box>
          <FormControl error={!!fieldState.error}>
            <Autocomplete
              size="small"
              disabled={disabled}
              placeholder={placeholder}
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              onBlur={field.onBlur}
              options={options}
              renderInput={(params) => <TextField variant="outlined" {...params} placeholder={placeholder} />}
              getOptionLabel={getOptionLabel}
              multiple={multiple}
              disablePortal
              {...params}
            />
            {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
          </FormControl>
        </Box>
      )}
    />
  )
}

export default AutocompleteInputForm
