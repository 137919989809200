import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckCnep = ({ title = '', cnepCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>{title || t('common:cnep')}</Typography>
      <CheckDetailTitle
        email={cnepCheck?.email}
        status={cnepCheck?.status}
        createdAt={cnepCheck?.createdAt}
        updatedAt={cnepCheck?.updatedAt}
      />
      {!Array.isArray(cnepCheck?.checkDetails) && cnepCheck?.checkDetails?.informação && (
        <Box>
          <span>{t('common:information')}</span>
          <p>{cnepCheck?.checkDetails?.informação ?? '---'}</p>
        </Box>
      )}
      {Array.isArray(cnepCheck?.checkDetails) && cnepCheck?.checkDetails?.length && (
        <Box>
          <h4>{t('common:sanctions')}</h4>
          <Box className="group-table">
            <table className="bs bst group-table">
              <thead>
                <tr className="group-table">
                  <th className="bs">{t('common:initialDate')}</th>
                  <th className="bs">{t('common:endDate')}</th>
                  <th className="bs">{t('common:sanctionDescription')}</th>
                  <th className="bs">{t('common:sanctioningBody')}</th>
                  <th className="bs">{t('common:fineValue')}</th>
                </tr>
              </thead>
              <tbody>
                {cnepCheck?.checkDetails?.map((element, index) => {
                  return (
                    <tr className="group-table" key={index}>
                      <td className="bs table-cell col-5">{element.dataInicioSancao ?? '---'}</td>
                      <td className="bs table-cell col-5">{element.dataFimSancao ?? '---'}</td>
                      <td className="bs table-cell col-5">{element.tipoSancao?.descricaoResumida ?? '---'}</td>
                      <td className="bs table-cell col-5">{element.orgaoSancionador?.nome ?? '---'}</td>
                      <td className="bs table-cell col-5">{element.valorMulta ?? '---'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CheckCnep
