import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { renderJap, renderLawsuits, renderLawsuitsPerson } from '../../../components/utilities/MapLawsuits'
import { renderNegativeMedia } from '../../../components/utilities/MapNegativeMedia'
import RecursiveProperty from '../../../components/utilities/RenderJSON'

const KybCheckDetail = ({ name, value }) => {
  const { t } = useTranslation(['common', 'messages'])
  const { theme } = useSelector((state) => state.theme)

  if (name === 'jap') {
    return (
      <Box>
        {renderJap(Array.isArray(value?.checkDetails) ? value?.checkDetails[0]?.Lawsuits : value?.checkDetails, theme)}
      </Box>
    )
  }

  if (name === 'lawsuits') {
    return (
      <Box>
        {renderLawsuits(
          Array.isArray(value?.checkDetails) ? value?.checkDetails[0]?.OwnersLawsuits : value?.checkDetails,
          theme,
        )}
      </Box>
    )
  }
  if (name === 'lawsuitsPerson') {
    return <Box>{renderLawsuitsPerson(value?.checkDetails, theme)}</Box>
  }

  if (name === 'negativeMedia') {
    return (
      <Box>
        <h3>{t('common:kyb.checks.negativeMedia.source1.title')}</h3>
        <Box>{renderNegativeMedia(value?.checkDetails?.source1, theme)}</Box>
        <br />
        {value?.checkDetails?.source2 && <h3>{t('common:kyb.checks.negativeMedia.source2.title')}</h3>}
        {value?.checkDetails?.source2 && (
          <Box className="accordion-jap" sx={{ backgroundColor: theme === 'light' ? '#FCFFFD' : '#020D06' }}>
            <RecursiveProperty
              property={value?.checkDetails?.source2}
              excludeBottomBorder={false}
              rootProperty={true}
            />
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box className="recursive-property" sx={{ backgroundColor: theme === 'light' ? '#FCFFFD' : '#020D06' }}>
      <RecursiveProperty
        property={Array.isArray(value?.checkDetails) ? value?.checkDetails[0] : value?.checkDetails}
        excludeBottomBorder={false}
        rootProperty={true}
      />
    </Box>
  )
}

export default KybCheckDetail
