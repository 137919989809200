import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckSerasa = ({ title = '', serasaCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>{title || t('common:serasaScore')}</Typography>
      <CheckDetailTitle
        email={serasaCheck?.email}
        status={serasaCheck?.status}
        createdAt={serasaCheck?.createdAt}
        updatedAt={serasaCheck?.updatedAt}
      />
      <Box>
        <h4>{t('common:risk')}</h4>
        <Box className="detail-group-grid-3">
          <Box>
            <span>{t('common:level')}</span>
            <p>{serasaCheck?.checkDetails?.risco?.nivel ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:description')}</span>
            <p>{serasaCheck?.checkDetails.risco?.descricao ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:score')}</span>
            <p>{serasaCheck?.checkDetails.risco?.score ?? '---'}</p>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CheckSerasa
