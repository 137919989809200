import './datatable.scss'
import { useState } from 'react'
import { Box, Button, Divider, Link, Modal, TableContainer, Typography } from '@mui/material'
import { Flags } from '../flag/flags'
import { renderTags } from '../toRender/renderTags'
import { renderAddresses } from '../toRender/renderAddresses'
import { renderNames } from '../toRender/renderNames'
import { DataGrid } from '@mui/x-data-grid'
import { countryDictionary } from '../flag/countries'
import { useTranslation } from 'react-i18next'

const DatatableContact = ({ rows }) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const { t } = useTranslation(['common'])

  const columns = [
    {
      field: 'id',
      headerName: t('common:name'),
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        return (
          <Box className="table-body-cell">
            {params.row.generalDetails?.name ? renderNames(params.row.generalDetails.name) : '---'}
          </Box>
        )
      },
      valueGetter: (params) => {
        return params.row.generalDetails?.name
      },
    },
    {
      field: 'countryOfNationality',
      headerName: t('common:countryOfNationality'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box className="table-body-cell">
            {params.row.generalDetails.countryOfNationality
              ? Flags(params.row.generalDetails.countryOfNationality)
              : '---'}
          </Box>
        )
      },
      valueGetter: (params) => {
        return countryDictionary[params.row.generalDetails.countryOfNationality]
      },
    },
    {
      field: 'countryOfResidence',
      headerName: t('common:countryOfResidence'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box className="table-body-cell">
            {params.row.generalDetails.countryOfResidence ? Flags(params.row.generalDetails.countryOfResidence) : '---'}
          </Box>
        )
      },
      valueGetter: (params) => {
        return countryDictionary[params.row.generalDetails.countryOfResidence]
      },
    },
    {
      field: 'dateOfBirth',
      headerName: t('common:dateOfBirth'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.generalDetails.dateOfBirth ? params.row.generalDetails.dateOfBirth : '---'
      },
    },
    {
      field: 'gender',
      headerName: t('common:gender'),
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return params.row.generalDetails.gender ? params.row.generalDetails.gender : '---'
      },
    },
    {
      field: 'details',
      headerName: t('common:details'),
      flex: 1,
      minWidth: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              setSelected(params.row)
              return setOpen(true)
            }}
          >
            {t('common:viewDetails')}
          </Link>
        )
      },
    },
  ]

  return (
    <Box>
      <TableContainer>
        <DataGrid
          getRowId={(row) => row.generalDetails?.name}
          className="grid-table"
          rows={Array.isArray(rows) && rows}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
        />
      </TableContainer>
      <Modal
        open={open}
        onClose={() => {
          setSelected('')
          return setOpen(false)
        }}
        className="datatable-modal"
      >
        <Box className="modal">
          <Box className="datatable-modal-detail">
            {selected ? (
              <Box>
                <Typography variant="title" id="modal-modal-title">
                  {t('common:name') + ': '}
                  {`${selected?.generalDetails?.name?.firstName} ${selected?.generalDetails?.name?.lastName}`}
                </Typography>

                <Divider style={{ margin: '.6rem 0rem 1.6rem 0rem' }} />

                <Box className="ud">
                  <Box className="tl">
                    <Box>
                      <Typography variant="title" className="datatable-modal-title">
                        {t('common:address')}:
                      </Typography>
                      {renderAddresses(selected)}
                    </Box>
                    <Box>
                      <Typography variant="title" className="datatable-modal-title">
                        {t('common:legalDocuments')}:
                      </Typography>
                      <Box className="tags">
                        {selected.legalDocuments?.length ? (
                          selected.legalDocuments?.map((element, index) => {
                            return (
                              <Box key={index}>
                                <p>
                                  <strong>{index + 1}</strong>
                                </p>
                                {Object.keys(element).map((item, index) => {
                                  if (item !== 'tags' && item !== 'nameOnDocument') {
                                    return (
                                      <p className="tags" key={index}>
                                        <strong>{item}:</strong> {element[item]}
                                      </p>
                                    )
                                  } else if (item === 'nameOnDocument') {
                                    return (
                                      <p className="tags">
                                        <strong>{item}: </strong>
                                        {renderNames(element[item], 'Document')}
                                      </p>
                                    )
                                  } else {
                                    return <Box className="tags">{renderTags({ tags: element[item] })}</Box>
                                  }
                                })}
                              </Box>
                            )
                          })
                        ) : (
                          <p className="tags">---</p>
                        )}
                      </Box>
                    </Box>

                    <Box>
                      <Typography variant="title" className="datatable-modal-title">
                        Emails:
                      </Typography>
                      {selected.contactDetails && selected.contactDetails.emailIds ? (
                        selected.contactDetails.emailIds.map((e, index) => {
                          return (
                            <Box className="tags" key={index}>
                              <p>
                                <strong>{index + 1}:</strong> {e}
                              </p>
                            </Box>
                          )
                        })
                      ) : (
                        <p className="tags">---</p>
                      )}
                    </Box>

                    <Box>
                      <Typography variant="title" className="datatable-modal-title">
                        {t('common:contactNumbers')}:
                      </Typography>
                      {selected.contactDetails && selected.contactDetails.contactNumbers ? (
                        selected.contactDetails.contactNumbers.map((e, index) => {
                          return (
                            <Box className="tags" key={index}>
                              <p>
                                <strong>{index + 1}:</strong> {e}
                              </p>
                            </Box>
                          )
                        })
                      ) : (
                        <p className="tags">---</p>
                      )}
                    </Box>

                    <Box>
                      <Typography variant="title" className="datatable-modal-title">
                        {t('common:faxNumbers')}:
                      </Typography>
                      {selected.contactDetails && selected.contactDetails.faxNumbers ? (
                        selected.contactDetails.faxNumbers.map((e, index) => {
                          return (
                            <Box className="tags" key={index}>
                              <p>
                                <strong>{index + 1}:</strong> {e}
                              </p>
                            </Box>
                          )
                        })
                      ) : (
                        <p className="tags">---</p>
                      )}
                    </Box>

                    <Box>
                      <Typography variant="title" className="datatable-modal-title">
                        {t('common:websites')}:
                      </Typography>
                      {selected.contactDetails && selected.contactDetails.websites ? (
                        selected.contactDetails.websites.map((e, index) => {
                          return (
                            <Box className="tags" key={index}>
                              <p>
                                <strong>{index + 1}:</strong> {e}
                              </p>
                            </Box>
                          )
                        })
                      ) : (
                        <p className="tags">---</p>
                      )}
                    </Box>

                    <Box>{renderTags(selected)}</Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              ''
            )}

            <Divider style={{ margin: '.6rem 0rem 1.6rem 0rem' }} />
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default DatatableContact
