import { Box, Slider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import InfoIcon from '@mui/icons-material/Info'
import './riskLevel.scss'

const configsByRiskLevel = {
  NOT_DEFINED: {
    title: 'notDefined',
    value: 15,
    background: 'linear-gradient(90deg, #eee, #000)',
  },
  VERY_LOW: {
    title: 'noRisk',
    value: 20,
    background: 'linear-gradient(90deg, #00B85F, #00FF84)',
  },
  LOW: {
    title: 'lowRisk',
    value: 40,
    background: 'linear-gradient(90deg, #00B85F, #00FF84)',
  },
  MEDIUM: {
    title: 'mediumRisk',
    value: 60,
    background: 'linear-gradient(90deg, #FFB800, #FF2B1B)',
  },
  HIGH: {
    title: 'highRisk',
    value: 80,
    background: 'linear-gradient(90deg, #FFB800, #FF2B1B, #BB0647)',
  },
  VERY_HIGH: {
    title: 'veryHighRisk',
    value: 100,
    background: 'linear-gradient(90deg, #FF2B1B, #420098)',
  },
}

export const RiskLevelSlider = ({ type, onShowDetail = () => {}, showDetail = false }) => {
  const { t } = useTranslation(['common'])
  const RiskSlider = styled(Slider)(({ background }) => ({
    height: 10,
    padding: 0,
    pointerEvents: 'none',
    '& .MuiSlider-track': {
      border: 'none',
      background,
    },
    '& .MuiSlider-thumb': {
      display: 'none',
    },
    '& .MuiSlider-rail': {
      color: '#ECEFED',
      border: '1px solid #1C282026',
      height: 8,
    },
  }))
  const { title, value, background } = configsByRiskLevel[type] || configsByRiskLevel.NOT_DEFINED

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4">{t(`common:${title}`)}</Typography>
        {showDetail && (
          <InfoIcon
            sx={{ cursor: 'pointer', color: type === 'NOT_DEFINED' ? 'red' : 'inherit' }}
            onClick={onShowDetail}
          />
        )}
      </Box>
      <RiskSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={value} background={background} />
    </Box>
  )
}
