import { useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toaster from '../../../toaster'
import { useFindUserMasterQuery, useUpdateUserMasterMutation } from '../../../services/guenoApi'
import { SelectInputForm, TextInputForm } from '../../../components/common/form'

const KybNotificationsSettings = () => {
  const { emailClient } = useSelector((state) => state.login)
  const { t } = useTranslation(['common', 'messages'])
  const { data } = useFindUserMasterQuery({ emailClient })
  const [updateUserMaster, { isLoading }] = useUpdateUserMasterMutation({ fixedCacheKey: 'userMasterUpdate' })

  const schema = yup.object().shape({
    monthsExpirationLowLevel: yup.number().max(36).required(),
    monthsExpirationMediumLevel: yup.number().max(36).positive().required(),
    monthsExpirationHighLevel: yup.number().max(36).required(),
    monthsExpirationVeryHighLevel: yup.number().max(36).required(),
    kybNotificationEmail: yup.string().email().required(),
    kybNotificationLanguage: yup.string().required(),
  })
  const { handleSubmit, control, formState } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      monthsExpirationLowLevel: data?.monthsExpirationLowLevel || 18,
      monthsExpirationMediumLevel: data?.monthsExpirationMediumLevel || 12,
      monthsExpirationHighLevel: data?.monthsExpirationHighLevel || 6,
      monthsExpirationVeryHighLevel: data?.monthsExpirationVeryHighLevel || 3,
      kybNotificationEmail: data?.kybNotificationEmail || '',
      kybNotificationLanguage: data?.kybNotificationLanguage || 'BR',
    },
  })

  const onSubmit = async (body) => {
    try {
      const { data } = await updateUserMaster({
        emailClient,
        body,
      })
      if (data.success && data.data) {
        toaster.success(t('messages:updateSuccess'))
      } else {
        throw new Error(data?.responseBody?.message || data?.info || 'Unexpected')
      }
    } catch (err) {
      toaster.error(t('messages:updateError'))
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap="2rem" component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="title">{t('common:settings')}</Typography>
      <Box display="flex" flexDirection="row" justifyContent="flex-start" gap="2rem">
        <TextInputForm
          name="monthsExpirationLowLevel"
          control={control}
          label={t('common:expirationDateForLowRisk')}
          placeholder={`${t('common:insert')} ${t('common:months')}`}
          type="number"
          required
        />
        <TextInputForm
          name="monthsExpirationMediumLevel"
          control={control}
          label={t('common:expirationDateForMediumRisk')}
          placeholder={`${t('common:insert')} ${t('common:months')}`}
          type="number"
          required
        />
        <TextInputForm
          name="monthsExpirationHighLevel"
          control={control}
          label={t('common:expirationDateForHighRisk')}
          placeholder={`${t('common:insert')} ${t('common:months')}`}
          type="number"
          required
        />
        <TextInputForm
          name="monthsExpirationVeryHighLevel"
          control={control}
          label={t('common:expirationDateForVeryHighRisk')}
          placeholder={`${t('common:insert')} ${t('common:months')}`}
          type="number"
          required
        />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-start" gap="2.5rem">
        <TextInputForm
          name="kybNotificationEmail"
          control={control}
          label={t('messages:kyb.notification.email')}
          placeholder={t('messages:kyb.notification.email')}
          required
        />
        <SelectInputForm
          name="kybNotificationLanguage"
          control={control}
          label={t('messages:kyb.notification.language')}
          options={[
            { value: 'PT', label: 'PT' },
            { value: 'EN', label: 'EN' },
            { value: 'ES', label: 'ES' },
          ]}
          required
        />
      </Box>
      <Box className="modal-filter-buttons" display="flex" justifyContent="flex-end" style={{ marginRight: '1rem' }}>
        <LoadingButton
          loading={isLoading}
          type="submit"
          variant="contained"
          size="small"
          disabled={!formState.isValid || !formState.isDirty}
        >
          <span>{t('common:save')}</span>
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default KybNotificationsSettings
