import './case.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { Box, Fab, Button, MenuItem, Select, InputLabel, Modal, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import DatatableCases from '../../../components/common/datatable/DatatableCases'
import TypingAnimation from '../../../components/common/TypingAnimation'
import { useTranslation } from 'react-i18next'
import { CaseStatus } from '../case.status'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DatatableAssignUsers from '../../../components/common/datatable/DatatableAssignedUsers'
import {
  useFindCasesQuery,
  useUpdateCaseCommentMutation,
  useUpdateCaseMutation,
} from '../../../features/cases/caseSlice'
import toaster from '../../../toaster'

const CasesDatatable = ({
  setPage,
  setCount,
  count,
  setModalOpen,
  page,
  rowsPerPage,
  setRowsPerPage,
  hasTabs,
  actualTab,
  isFromDetail,
}) => {
  const { emailClient, email, activeServices, roles } = useSelector((state) => state.login)
  const caseDetailsSlice = useSelector((state) => state.case)
  const caseQueryState = useSelector((state) => state.case.filters)
  const [isEditingAssign, setIsEditingAssign] = useState(false)
  const [status, setStatus] = useState('')
  const [label, setLabel] = useState('')
  const [category, setCategory] = useState('')
  const [caseToClose, setCaseToClose] = useState('')
  const [openCloseCase, setOpenCloseCase] = useState(false)
  const [openCloseCaseReport, setOpenCloseCaseReport] = useState(false)
  const [caseToCloseComment, setCaseToCloseComment] = useState('')
  const [assignedUsers, setAssignedUsers] = useState([])
  const [toAssign, setToAssign] = useState(caseDetailsSlice.toAssign ?? [])

  const { t } = useTranslation(['common', 'messages'])

  const {
    limit,
    offset,
    page: filterPage,
    fromDate,
    toDate,
    status: statusCases,
    caseId,
    userId,
    firstName,
    lastName,
    legalName,
    userType,
    userKey,
    userValue,
    transactionKey,
    transactionValue,
    documentNumber,
  } = caseQueryState

  const {
    data,
    isLoading,
    refetch,
    isFetching: isFetchingCases,
  } = useFindCasesQuery({
    emailClient,
    ...(actualTab === 'MyCases' && { roles }),
    limit,
    offset,
    filterPage,
    fromDate,
    toDate,
    statusCases,
    caseId,
    userId,
    firstName,
    lastName,
    legalName,
    ...(userType !== 'ALL' && { userType }),
    userKey,
    userValue,
    transactionKey,
    transactionValue,
    documentNumber,
  })

  const { cases, pagination } = useMemo(() => ({ cases: data?.data, pagination: data?.pagination }), [data])

  const updateAssignsForm = (assignedUsers2) => {
    const aux = assignedUsers2.map((item) => {
      const user = toAssign.find((u) => u._id === item)
      return { _id: item, email: user ? user.email : '---', roles: user.roles }
    })
    setAssignedUsers(aux)
  }

  const [updateCase] = useUpdateCaseMutation()
  const [updateCaseComment] = useUpdateCaseCommentMutation()

  const handleUpdateCase = async () => {
    const bodyUpdateCaseComment = {
      description: caseToCloseComment !== '' ? caseToCloseComment : 'Closed without comment.',
      emailClient,
      email,
    }

    const bodyUpdateCase = {
      emailClient,
      assignedUsers: assignedUsers.map((item) => item._id),
      ...(status && { status }),
      ...(label && { label }),
      ...(category && { category }),
    }

    try {
      await Promise.all([
        updateCaseComment({ body: bodyUpdateCaseComment, caseToClose }).unwrap(),
        updateCase({ body: bodyUpdateCase, caseToClose }).unwrap(),
        handleClose(),
      ])
      refetch()
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      toaster.error(t('messages:updateError'))
      console.error(error?.data?.data)
    }
  }

  const handleClose = () => {
    setCaseToClose({ maskId: '', id: '' })
    setOpenCloseCase(false)
    setCaseToCloseComment('')
    setPage(0)
  }

  useEffect(() => {
    if (pagination?.totalElements) {
      setCount(pagination?.totalElements)
    }
  }, [pagination?.totalElements, setCount])

  return (
    <Box>
      <DatatableCases
        cases={cases}
        setCaseToClose={setCaseToClose}
        setOpenCloseCase={setOpenCloseCase}
        setOpenCloseCaseReport={setOpenCloseCaseReport}
        setModalOpen={setModalOpen}
        count={count}
        hasTabs={hasTabs}
        page={page}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        isFromDetail={isFromDetail}
        isLoading={isLoading || isFetchingCases}
      />
      <Modal
        className="modal-case"
        open={openCloseCaseReport}
        onClose={() => setOpenCloseCaseReport(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="create-role">
          <TypingAnimation casesData={cases} caseToClose={caseToClose} />
        </Box>
      </Modal>
      <Modal open={openCloseCase} onClose={handleClose}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:updateCase')}</Typography>
            </Box>
            <Fab variant="close" onClick={handleClose}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters">
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('messages:doYouWantUpdate')} {caseToClose.maskId ? caseToClose.maskId : caseToClose.id}?
              </Typography>
            </Box>
            <Box className="modal-box">
              <InputLabel>{t('common:newStatus')}:</InputLabel>
              <Select
                fullWidth
                size="small"
                value={status}
                onChange={(e) => {
                  if (e.target.value) {
                    setStatus(e.target.value)
                  } else setStatus('')
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <Typography variant="subtitle3">
                    {t('common:select')} {t('common:newStatus')}
                  </Typography>
                </MenuItem>
                {CaseStatus?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="modal-box">
              <InputLabel>{t('common:newLabel')}:</InputLabel>
              <Select fullWidth size="small" value={label} onChange={(e) => setLabel(e.target.value)} displayEmpty>
                <MenuItem disabled value="">
                  <Typography variant="subtitle3">
                    {t('common:select')} {t('common:newLabel')}
                  </Typography>
                </MenuItem>
                {caseDetailsSlice?.caseAdminPanel?.labels?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="modal-box">
              <InputLabel>{t('common:newCategory')}:</InputLabel>
              <Select
                fullWidth
                size="small"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <Typography variant="subtitle3">
                    {t('common:select')} {t('common:newCategory')}
                  </Typography>
                </MenuItem>
                {caseDetailsSlice?.caseAdminPanel?.categories?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="modal-box">
              <Box className="modal-box">
                <InputLabel>{t('common:assignedUsers')}</InputLabel>
                {activeServices.includes('caseManagement:id') && (
                  <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      onClick={() => {
                        setIsEditingAssign(!isEditingAssign)
                      }}
                      variant={!isEditingAssign ? 'outlinedButtonSuccess' : 'outlinedButtonError'}
                    >
                      {!isEditingAssign && <EditIcon />}
                      {isEditingAssign && <CloseIcon />}
                    </Button>
                  </Box>
                )}
              </Box>
              <DatatableAssignUsers
                rows={assignedUsers}
                toAssign={toAssign}
                isEditing={isEditingAssign}
                updateAssigns={updateAssignsForm}
                setIsEditingAssign={setIsEditingAssign}
              />
            </Box>
            <Box className="modal-box">
              <InputLabel>{t('common:updatingReasons')}:</InputLabel>
              <TextField
                multiline
                rows={4}
                size="small"
                value={caseToCloseComment}
                onChange={(event) => setCaseToCloseComment(event.target.value)}
                placeholder={t('messages:closingReason')}
              />
            </Box>
            <Box className="modal-filter-buttons">
              <Button variant="outlinedGrey" onClick={handleClose}>
                {t('common:close')}
              </Button>
              <Button disabled={!caseToCloseComment} variant="contained" onClick={handleUpdateCase}>
                {t('common:update')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default CasesDatatable
