import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { SelectInputForm } from '../../../components/common/form'
import FileSingleInputForm from '../../../components/common/form/FileInputForm/FileSingleInputForm'
import { useCreateKytImportItemMutation } from '../../../features/kyt-import/kytImportSlice'

const CreateKytImportForm = ({ onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [_, { isLoading }] = useCreateKytImportItemMutation({
    fixedCacheKey: 'create-kyt-import-item',
  })

  const schema = yup.object().shape({
    type: yup.string().required(),
    file: yup.mixed().required(),
  })

  const { handleSubmit, control } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      type: 'transactions',
      file: null,
    },
  })

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: 1 }}>
        <SelectInputForm
          name="type"
          label="Type"
          control={control}
          defaultValue="transactions"
          options={[
            { label: 'Transactions', value: 'transactions' },
            { label: 'Users', value: 'users' },
          ]}
        />
        <FileSingleInputForm name="file" control={control} fileTypes={['CSV', 'JSON']} />
      </Box>
      <Box className="modal-button">
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t('common:create')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default CreateKytImportForm
