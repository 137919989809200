import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'
import { cleanParams } from '../../services/builders'

export const generalAlertsSlice = createSlice({
  name: 'generalAlerts',
  initialState: {
    alerts: {},
    consumerUser: [],
    filters: {
      emailClient: '',
      id: '',
      page: 0,
      limit: 10,
      offset: 0,
      type: '',
      fromDate: null,
      toDate: '',
    },
  },
  reducers: {
    updateGeneralAlerts: (state, action) => {
      state.alerts = action.payload
    },
    updateConsumerUserGeneralAlert: (state, action) => {
      state.consumerUser = action.payload
    },
    updateGeneralAlertsFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

const endpoints = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findGeneralAlerts: builder.query({
      query: ({ emailClient, id, limit, offset, page, fromDate, toDate, type }) => {
        const filteredParams = cleanParams({
          emailClient,
          id,
          limit,
          offset,
          page,
          fromDate,
          toDate,
          type,
        })
        return {
          url: `/generalAlerts`,
          params: filteredParams,
        }
      },
    }),
    updateAlertStatus: builder.mutation({
      query: ({ alertToUpdate, status }) => ({
        url: `/generalAlerts/${alertToUpdate}/status/${status}`,
        method: 'PATCH',
      }),
    }),
  }),
})

export const { updateGeneralAlerts, updateConsumerUserGeneralAlert, updateGeneralAlertsFilters } =
  generalAlertsSlice.actions

export default generalAlertsSlice.reducer

export const { useFindGeneralAlertsQuery, useUpdateAlertStatusMutation } = endpoints
