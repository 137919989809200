import '../home/home.scss'
import './govCheck.scss'
import { Box, Paper, Typography, TextField, Select, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../components/common/spinner/spinner'
import DatatableGovCheck from '../../components/common/datatable/DatatableGovCheck'
import { updategovCheckFilters } from '../../features/govCheck/govCheckSlice'
import { useTranslation } from 'react-i18next'
import MyDatePicker from '../../components/utilities/MyDatePicker'
import { FilterModal } from '../../components/utilities/FilterModal'
import dayjs from 'dayjs'

const GovCheck = () => {
  const queryState = useSelector((state) => state.govCheck?.filters)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [id, setId] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [checkId, setCheckId] = useState(queryState?.id)
  const [document, setDocument] = useState(queryState?.document)
  const [type, setType] = useState(queryState?.type)
  const [selectedDate, setSelectedDate] = useState(queryState.fromDate)
  const [toDate, setToDate] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])

  const CheckTypes = [
    'argentinaValidation',
    'brazilValidation',
    'brazilValidationRelativeName',
    'rutChile',
    'colombiaValidation',
    'mexicoCurp',
    'mexicoINE',
    'mexicoPersona',
    'mexicoRfc',
    'peruValidationDNI',
    'peruValidationDNIFicha',
    'peruValidationRUC',
  ]

  const handleResetButton = () => {
    if (checkId !== '') setCheckId('')
    if (type !== '') setType('')
    if (document !== '') setDocument('')
    if (selectedDate) setSelectedDate(null)
    setPage(0)
    setIsLoading(true)
    setSelectedFilters([])
    return dispatch(
      updategovCheckFilters({
        page: 0,
        limit: 25,
        offset: 0,
        id: '',
        document: '',
        type: '',
        fromDate: null,
        toDate: null,
      }),
    )
  }

  const handleDispatch = () => {
    const selectedStartOfDay = selectedDate ? dayjs(selectedDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
    const todayEndOfDay = toDate ? dayjs(toDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
    setIsLoading(true)
    return dispatch(
      updategovCheckFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        id: checkId,
        type,
        document,
        fromDate: selectedStartOfDay,
        toDate: todayEndOfDay,
      }),
    )
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate()) : null)
    }
  }, [selectedDate])

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}

      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:govcheck')}</Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      <Paper elevation={0}>
        <DatatableGovCheck
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setModalOpen={setModalOpen}
        />
      </Paper>
      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">{`${t('common:by')} ID`}:</Typography>
            <TextField
              required
              value={id}
              onChange={(event) => setId(event.target.value)}
              size="small"
              placeholder={`${t('common:insert')} id`}
            />
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">{`${t('common:by')} ${t('common:documentN')}`}:</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={document}
              onChange={(event, newValue) => {
                setSelectedFilters([...selectedFilters, newValue])
                return setDocument(event.target.value)
              }}
              placeholder={t('common:searchByRuleId')}
            />
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">{`${t('common:by')} ${t('common:type')}`}:</Typography>
            <Select size="small" value={type} onChange={(e) => setType(e.target.value)} displayEmpty>
              <MenuItem disabled value="">
                {t('common:select')} {t('common:type')}
              </MenuItem>
              {CheckTypes.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default GovCheck
