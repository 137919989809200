import { createSlice } from '@reduxjs/toolkit'

export const amlBrSlice = createSlice({
  name: 'amlBr',
  initialState: {
    amlBr: {},
    amlBrDetail: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      id: '',
      documentNumber: '',
    },
  },
  reducers: {
    updateAmlBr: (state, action) => {
      state.amlBr = action.payload
    },
    updateAmlBrDetail: (state, action) => {
      state.amlBrDetail = action.payload
    },
    updateAmlBrFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateAmlBr, updateAmlBrDetail, updateAmlBrFilters } = amlBrSlice.actions
export default amlBrSlice.reducer
