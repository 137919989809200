import '../home/home.scss'
import './aml.scss'

import { Box, Paper, Typography, Grid } from '@mui/material'
import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatatableAml from '../../components/common/datatable/DatatableAml'
import { updateAmlFilters } from '../../features/aml/amlSlice'
import { useTranslation } from 'react-i18next'
import Spinner from '../../components/common/spinner/spinner'
import { FilterModal } from '../../components/utilities/FilterModal'
import MyDatePicker from '../../components/utilities/MyDatePicker'
import dayjs from 'dayjs'

const POSSIBLE_MATCHES = [
  'unknown',
  'no_match',
  'potential_match',
  'false_positive',
  'true_positive',
  'true_positive_approve',
  'true_positive_reject',
]

const Aml = () => {
  const queryState = useSelector((state) => state.aml.filters)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [id, setId] = useState(queryState.id)
  const [search_id, setSearch_id] = useState(queryState.search_id)
  const [search_term, setSearchTerm] = useState(queryState.search_term)
  const [hit_id, setHit_id] = useState(queryState.hit_id)
  const [match_status, setMatch_status] = useState(queryState.match_status)
  const [selectedDate, setSelectedDate] = useState(queryState.fromDate)
  const [toDate, setToDate] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])

  let isQueryOk = !id && !search_term && !selectedDate && !search_id && !hit_id && !match_status

  const handleResetButton = () => {
    if (id !== '') setId('')
    if (search_id !== '') setSearch_id('')
    if (search_term) setSearchTerm('')
    if (hit_id) setHit_id('')
    if (match_status) setMatch_status('')
    if (selectedDate) setSelectedDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateAmlFilters({
        page: 0,
        limit: 25,
        offset: 0,
        id: '',
        search_term: null,
        fromDate: null,
        toDate: '',
      }),
    )
  }

  const handleDispatch = () => {
    const selectedStartOfDay = selectedDate ? dayjs(selectedDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
    const todayEndOfDay = toDate ? dayjs(toDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
    setIsLoading(true)
    return dispatch(
      updateAmlFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        id,
        search_id,
        match_status,
        hit_id,
        search_term,
        fromDate: selectedStartOfDay,
        toDate: todayEndOfDay,
      }),
    )
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate()) : null)
    }
  }, [selectedDate])

  return (
    <Box sx={{ width: '100%' }}>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner />}

        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:amlAndList')}</Typography>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <Paper elevation={0}>
          <DatatableAml
            setIsLoading={setIsLoading}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setModalOpen={setModalOpen}
          />
        </Paper>
      </Box>

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:matchStatus')}:
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {POSSIBLE_MATCHES?.map((item, index) => (
                <Grid item key={index}>
                  <Button
                    onClick={() => {
                      return setMatch_status(item)
                    }}
                    variant={match_status === item ? 'action' : 'label'}
                  >
                    {t(`common:${item}`).toUpperCase()}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>

            <TextField
              size="small"
              value={search_id}
              onChange={(event) => setSearch_id(event.target.value)}
              placeholder={`${t('common:insert')} ID`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:referenceId')}:
            </Typography>
            <TextField
              required
              value={id}
              onChange={(event) => setId(event.target.value)}
              size="small"
              placeholder={`${t('common:insert')} ${t('common:referenceId')}`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} Hit ID:</Typography>

            <TextField
              size="small"
              value={hit_id}
              onChange={(event) => setHit_id(event.target.value)}
              placeholder={`${t('common:insert')} Hit ID`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:searchTerm')}:
            </Typography>

            <TextField
              size="small"
              value={search_term}
              onChange={(event) => setSearchTerm(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:searchTerm')}`}
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default Aml
