import '../home/home.scss'
import './onboarding.scss'
import { Autocomplete, Box, Paper, Typography } from '@mui/material'
import { TYPES } from './onboardingConstants'
import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatatableOnboardings from '../../components/common/datatable/DatatableOnboardings'
import { updateOnboardingFilters } from '../../features/onboarding/onboardingSlice'
import { useTranslation } from 'react-i18next'
import Spinner from '../../components/common/spinner/spinner'
import MyDatePicker from '../../components/utilities/MyDatePicker'
import { FilterModal } from '../../components/utilities/FilterModal'
import dayjs from 'dayjs'

const Onboarding = () => {
  const queryState = useSelector((state) => state.transaction.filters)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [checkId, setCheckId] = useState(queryState.checkId)
  const [type, setType] = useState(queryState.type)
  const [entityName, setEntityName] = useState(queryState.entityName)
  const [selectedDate, setSelectedDate] = useState(queryState.fromDate)
  const [toDate, setToDate] = useState(null)
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation(['common'])

  const handleResetButton = () => {
    if (checkId !== '') setCheckId('')
    if (type) setType('')
    if (entityName) setEntityName('')
    if (selectedDate) setSelectedDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateOnboardingFilters({
        page: 0,
        limit: 25,
        offset: 0,
        checkId: '',
        type: null,
        entityName: '',
        fromDate: null,
        toDate: '',
      }),
    )
  }

  const handleDispatch = () => {
    const selectedStartOfDay = selectedDate ? dayjs(selectedDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
    const todayEndOfDay = toDate ? dayjs(toDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
    setIsLoading(true)
    return dispatch(
      updateOnboardingFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        checkId,
        type,
        entityName,
        fromDate: selectedStartOfDay,
        toDate: todayEndOfDay,
      }),
    )
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate()) : null)
    }
  }, [selectedDate])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}

        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:onboarding')}</Typography>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <Paper elevation={0}>
          <DatatableOnboardings
            setIsLoading={setIsLoading}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setModalOpen={setModalOpen}
          />
        </Paper>
      </Box>
      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          {/* TSX DETAILS */}

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
            <TextField
              placeholder={`${t('common:insert')} ID}`}
              required
              value={checkId}
              onChange={(event) => setCheckId(event.target.value)}
              size="small"
            />
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:type')}:
            </Typography>
            <Autocomplete
              fullWidth
              size="small"
              value={type}
              onChange={(event, value) => {
                if (value) {
                  setType(value)
                } else setType(null)
              }}
              options={TYPES}
              renderInput={(params) => <TextField {...params} placeholder="Select a check type" />}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:name')}:
            </Typography>

            <TextField
              value={entityName}
              onChange={(event) => setEntityName(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:name')}`}
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default Onboarding
