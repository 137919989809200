import React from 'react'
import ReactDOM from 'react-dom/client'
import AppWithInitialData from './AppWithInitialData'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import './i18n'
import 'dayjs/locale/es'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'

let persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AppWithInitialData />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
