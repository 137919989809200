import { createSlice } from '@reduxjs/toolkit'

export const defaultKytUserFilters = {
  viewMode: true,
  fromDate: null,
  toDate: null,
  userId: '',
  firstName: '',
  middleName: '',
  lastName: '',
  countryOfResidence: null,
  countryOfNationality: null,
  documentNumber: '',
  legalName: '',
  countryOfRegistration: null,
  userKey: null,
  userValue: null,
  selectedUserState: null,
  userKycStatus: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userDetails: {},
    filters: { ...defaultKytUserFilters },
  },
  reducers: {
    updateUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
    performLogoutUsers: (state) => {
      state.filters = { ...defaultKytUserFilters }
      state.userDetails = {}
    },
    updateUsersFilters: (state, action) => {
      state.filters = action.payload
    },
    resetUsersFilters: (state) => {
      state.filters = { ...defaultKytUserFilters }
    },
  },
})

export const { updateUserDetails, performLogoutUsers, updateUsersFilters, resetUsersFilters } = userSlice.actions
export default userSlice.reducer
