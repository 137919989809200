import './RequestRule.scss'
import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Divider, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RenderTooltip } from '../../../utilities/RenderTooltip'

function getStyles(name, selectedValue, theme) {
  return {
    fontWeight:
      selectedValue.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

const RequestRule = ({ aux }) => {
  const theme = useTheme()

  const [title, setTitle] = useState('')
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [detail, setDetail] = useState('')
  const [selectedValue, setSelectedValue] = useState([aux])

  const [disabled, setDisabled] = useState(false)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { accessToken, email } = useSelector((state) => state.login)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (title === '' || name === '' || desc === '') setDisabled(true)
    else setDisabled(false)
  }, [title, name, desc])

  const reset = () => {
    setTitle('')
    setName('')
    setDesc('')
    setDetail('')
    setSelectedValue([aux])
  }

  const submit = () => {
    if (!process.env.REACT_APP_IS_DEMO) {
      const urlLogin = `${process.env.REACT_APP_BASEURL}/kyt/sendSlackMessage`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          email: email,
          title,
          name,
          desc,
          aditional: detail,
          priority: selectedValue[0],
        }),
      }

      fetch(urlLogin, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode >= 400) throw Error(res.message)
          setOpen(true)
          reset()
        })
        .catch((error) => {
          setError(true)
          setErrorMessage(JSON.stringify(error.message))
        })
    } else {
      setOpen(true)
      reset()
    }
  }

  const posibleValues = ['P1', 'P2', 'P3', 'P4']

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  return (
    <Box className="component-wrapper">
      <Typography variant="title">{t('common:requestRule')}</Typography>
      <Divider />

      <Box className="aml-manual-flex">
        <Box className="label-input">
          <Box className="required">
            <InputLabel>{t('common:title')}</InputLabel>
            <Typography variant="subtitle3">({t('common:required')})</Typography>
          </Box>
          <TextField
            multiline={true}
            placeholder={t('messages:rules.ruleTitle')}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Box>
        <Box className="label-input">
          <Box className="required">
            <InputLabel>{t('common:ruleName')}</InputLabel>
            <Typography variant="subtitle3">({t('common:required')})</Typography>
          </Box>
          <TextField
            color="secondary"
            id="standard-adornment-amount"
            startAdornment={<InputAdornment position="start"></InputAdornment>}
            multiline
            placeholder={t('messages:rules.ruleName')}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Box>
        <Box className="label-input">
          <Box className="required">
            <InputLabel>{t('common:priority')}</InputLabel>
            <Typography variant="subtitle3">({t('common:required')})</Typography>
            <RenderTooltip title={t('messages:rules.priority')} />
          </Box>

          <Select
            displayEmpty
            value={selectedValue}
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
          >
            {posibleValues.map((name) => (
              <MenuItem key={name} value={name} style={getStyles(name, selectedValue, theme)}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box className="aml-manual-flex">
        <Box className="label-input">
          <Box className="required">
            <InputLabel>{t('common:description')}</InputLabel>
            <Typography variant="subtitle3">({t('common:required')})</Typography>
          </Box>
          <TextField
            multiline
            rows={4}
            placeholder={t('messages:rules.ruleDescription')}
            value={desc}
            onChange={(event) => setDesc(event.target.value)}
          />
        </Box>
        <Box className="label-input">
          <InputLabel>{t('common:additionalDetails')}</InputLabel>
          <TextField
            multiline
            placeholder={t('messages:rules.additionalDetails')}
            value={detail}
            rows={4}
            onChange={(event) => setDetail(event.target.value)}
          />
        </Box>
      </Box>

      <Box>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {t('messages:rules.requestSubmitted')}
          </Alert>
        </Collapse>
        <Collapse in={error}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="error"
                size="small"
                onClick={() => {
                  setError(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            severity="error"
            sx={{ mb: 2 }}
          >
            {t('messages:rules.requestError')} {errorMessage}
          </Alert>
        </Collapse>
      </Box>

      <Box className="modal-filter-buttons">
        <Button variant="outlinedGrey" onClick={reset}>
          {t('common:reset')}
        </Button>
        <Button disabled={disabled} variant="contained" onClick={submit}>
          {t('common:submit')}
        </Button>
      </Box>
    </Box>
  )
}

export default RequestRule
