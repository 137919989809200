import './numberInput.scss'
import { Box, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

const NumberInput = ({ propertie, ruleParameters, setRuleParameters, schema, isEditing }) => {
  const [number, setNumber] = useState(schema?.default ?? '')
  const [change, setChange] = useState(false)

  useEffect(() => {
    const currentValue = ruleParameters[propertie]

    if (currentValue !== undefined && currentValue !== number) {
      setNumber(currentValue)
      setChange(false)
    }

    if (currentValue === undefined) {
      setNumber('')
    }
  }, [propertie, ruleParameters])

  const handleChange = (value) => {
    if (schema.minimum || schema.minimum === 0 || schema.maximum) {
      if (
        (value >= schema.minimum || (!schema.minimum && schema.minimum !== 0)) &&
        (!schema.maximum || value <= schema.maximum)
      ) {
        setNumber(value)
        setChange(true)
      }
    } else {
      if (value >= 0) {
        setNumber(value)
        setChange(true)
      }
    }
  }

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      if (number !== '') {
        all[propertie] = number
      } else {
        delete all[propertie]
      }
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [number, change])

  return (
    <Box className="number-input-container">
      <Box className="number-input-block">
        {schema?.description && <Typography variant="subtitle4">{schema.description}</Typography>}
        <TextField
          fullWidth
          type="number"
          disabled={!isEditing}
          variant="outlined"
          size="small"
          value={number}
          onChange={(event) => handleChange(event.target.value !== '' ? parseInt(event.target.value) : '')}
        />
      </Box>
    </Box>
  )
}

export default NumberInput
