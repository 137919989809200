import { createTheme } from '@mui/material'
import { dsTheme } from './theme-light'

export const nucleaTheme = createTheme(dsTheme, {
  palette: {
    action: {
      main: '#CC99FF',
    },
    primary: {
      main: '#CC99FF',
    },
    secondary: {
      main: '#CC99FF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#CC99FF',
          '&:hover': {
            backgroundColor: '#CC99FF',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#CC99FF',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#CC99FF',
        },
        sliderSelected: {
          backgroundColor: '#CC99FF',
        },
        slider: {
          border: '1px solid #CC99FF',
          color: '#CC99FF',
        },
      },
    },
  },
})
