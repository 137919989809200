import { Box, Link, TablePagination, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { Status } from '../../utilities/Status'
import { Link as RouterLink } from 'react-router-dom'
import { DataGridPro, GridColumnMenu, useGridApiRef } from '@mui/x-data-grid-pro'
import DeleteRelationModal from '../../../pages/kyb/components/DeleteRelationModal'
import CheckLastBeneficiaryModal from '../../../pages/kyb/components/CheckLastBeneficiaryModal'
import UpdateKybSubjectModal from '../../../pages/kyb/components/UpdateKybSubjectModal'
import { useGetCountrySegmentFromUrl } from '../../../pages/kyb/kyb.hook-helpers'
import { useCheckKybMutation } from '../../../services/guenoApi'
import toaster from '../../../toaster'

const DataTableSubjectsList = ({
  rows,
  kybId,
  entityType,
  subjectType,
  lastBeneficiaries = [],
  currentCountry,
  isRunningKyb,
  setSelectedTab,
  setPage,
  setRowsPerPage,
  setSortField,
  setSortDirection,
  loading,
  pagination,
  isNuclea,
  countrySegment,
  handleRunKyb = () => {},
  updateDetail = () => {},
}) => {
  const { page, totalElements, rowsPerPage, sortField, sortDirection } = pagination
  const { t } = useTranslation(['common', 'messages'])
  const apiRef = useGridApiRef()
  const [country] = useGetCountrySegmentFromUrl()

  const [createCheck, { isLoading: createCheckLoading, originalArgs: createCheckArgs }] = useCheckKybMutation()
  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleCheckPropensity = async (id, documentNumber) => {
    try {
      await createCheck({
        countrySegment,
        body: {
          id,
          documentNumber,
          entity: 'BUSINESS',
          type: 'NUCLEA_PROPENSITY',
        },
      }).unwrap()
      toaster.success(t('messages:creationSuccess'))
    } catch (error) {
      console.error('[CREATE NEW COMMENT ERROR] --> ', error?.data?.data)
      toaster.error(error.message ? error.message : t('messages:creationError'))
    }
  }

  const columns = [
    {
      field: 'documentNumber',
      headerName: t('common:documentNumber'),
      flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        const { row } = params
        if (row.type === 'PERSON') {
          return (
            <Link
              component={RouterLink}
              to={`/kyc/${currentCountry ?? 'BR'}/detail/${row._id}`}
              onClick={() => {
                setSelectedTab('checks')
              }}
            >
              <p>{row.documentNumber}</p>
            </Link>
          )
        } else if (row.type === 'BUSINESS' && row.kyb?._id) {
          return (
            <Link
              component={RouterLink}
              to={`/kyb/${currentCountry ?? 'BR'}/detail/${row.kyb?._id}`}
              onClick={() => setSelectedTab('checks')}
            >
              <p>{row.documentNumber}</p>
            </Link>
          )
        } else {
          ;<p>{row.documentNumber}</p>
        }
      },
      disableColumnMenu: true,
    },
    {
      field: 'name',
      headerName: t('common:name'),
      flex: 1,
      renderCell: ({ row }) => <Typography variant="body2">{row.name ?? '---'}</Typography>,
      resizable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 1.1,
      align: 'center',
      renderCell: ({ row }) => (row.status ? <Status type={row.status} variant="colorSecondary" /> : '---'),
      resizable: false,
      disableColumnMenu: true,
    },
    {
      field: 'type',
      headerName: t('common:role'),
      flex: 0.7,
      renderCell: ({ row }) => (
        <Typography variant="body2">{row.type === 'PERSON' ? t('common:person') : t('common:business')}</Typography>
      ),
      disableColumnMenu: true,
    },
    {
      field: 'roleType',
      headerName: t('common:roleType'),
      flex: 0.8,
      renderCell: ({ row }) => <Typography variant="body2">{row.relationship?.type || '---'}</Typography>,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'relationShip',
      headerName: t('common:relationship'),
      flex: 1,
      renderCell: ({ row }) => <Typography variant="body2">{row.relationship?.name || '---'}</Typography>,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: isNuclea ? 'scoring' : 'creationTime',
      headerName: isNuclea ? t('common:scoring') : t('common:creationTime'),
      flex: 1,
      minWidth: 30,
      renderCell: ({ row }) => {
        return isNuclea ? (
          row.type === 'PERSON' ? (
            <Typography variant="body2">--</Typography>
          ) : row.type === 'BUSINESS' ? (
            row.kyb?.nucleaPropensity?.checkDetails?.score ? (
              <Typography variant="body2">{row.kyb?.nucleaPropensity?.checkDetails?.score}</Typography>
            ) : (
              <LoadingButton
                variant="contained"
                color="secondary"
                size="small"
                loading={!!createCheckLoading && createCheckArgs?.body?.id === row.kyb?._id}
                sx={{
                  padding: '0.2rem 0.8rem',
                  fontSize: '0.75rem',
                  minWidth: 'auto',
                }}
                onClick={() => {
                  handleCheckPropensity(row.kyb?._id, row.documentNumber)
                }}
              >
                {t('common:runScoring')}
              </LoadingButton>
            )
          ) : null
        ) : (
          <Typography variant="body2">{row.createdAt ? buildTimeStringFromTimestamp(row.createdAt) : '---'}</Typography>
        )
      },
      disableColumnMenu: true,
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      flex: subjectType === 'relatedPerson' ? 0.7 : 1.5,
      renderCell: (params) => {
        const { row } = params
        if (currentCountry === 'AR') {
          return (
            <Link href="https://onboarding.gueno.io" target="_blank">
              {t('common:run')} KYC
            </Link>
          )
        } else {
          return (
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
              {subjectType === 'shareHolder' && country === 'BR' && row.type === 'PERSON' && (
                <>
                  <UpdateKybSubjectModal
                    subjectId={row._id}
                    initialEmail={row.email}
                    initialPhoneNumber={row.phoneNumber}
                  />
                  <CheckLastBeneficiaryModal
                    kybId={kybId}
                    beneficiaryDocumentNumber={row.documentNumber}
                    isLastBeneficiary={lastBeneficiaries.includes(row.documentNumber)}
                    updateDetail={updateDetail}
                  />
                </>
              )}
              {entityType === 'BUSINESS' && (
                <DeleteRelationModal
                  kybId={kybId}
                  subjectId={row._id}
                  relationType={subjectType}
                  disabled={lastBeneficiaries.includes(row.documentNumber)}
                />
              )}
              {row.type === 'BUSINESS' && !row.kyb?._id && (
                <LoadingButton
                  onClick={(e) => {
                    handleRunKyb(e, row.documentNumber, row._id)
                  }}
                  size="small"
                  variant="contained"
                  sx={{
                    padding: 0,
                    paddingInline: 1,
                    fontSize: 12,
                  }}
                  loading={isRunningKyb === row.documentNumber}
                >
                  {t('common:run')} KYB
                </LoadingButton>
              )}
            </Box>
          )
        }
      },
      resizable: false,
      disableColumnMenu: true,
    },
  ]

  const handleSortModelChange = useCallback(
    (sortModel) => {
      if (sortModel.length > 0) {
        const newSortField = sortModel[0].field
        const newSortDirection = sortModel[0].sort === 'asc' ? 1 : -1
        setSortField(newSortField)
        setSortDirection(newSortDirection)
        setPage(0)
      } else {
        setSortField('createdAt')
        setSortDirection(-1)
      }
    },
    [setSortField, setSortDirection, setPage],
  )

  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: null,
          columnMenuFilterItem: null,
          columnMenuSortItem: null,
        }}
      />
    )
  }

  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <DataGridPro
        loading={loading}
        getRowHeight={() => 'auto'}
        getRowId={(row) => JSON.stringify(row._id)}
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        slots={{ columnMenu: CustomColumnMenu }}
        hideFooter
        sortingMode="server"
        sortModel={[{ field: sortField, sort: sortDirection === 1 ? 'asc' : 'desc' }]}
        onSortModelChange={handleSortModelChange}
        sx={{
          height: 'calc(100vh - 19.5rem)',
          '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto',
          },
        }}
        autoHeight
        disableColumnReorder
        disableColumnResize
      />
      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={totalElements}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

export default DataTableSubjectsList
