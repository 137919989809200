import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckJap = ({ title = '', jap, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>{title || t('common:lawsuits')}</Typography>
      <CheckDetailTitle email={jap?.email} status={jap?.status} createdAt={jap?.createdAt} updatedAt={jap?.updatedAt} />
      {Object.keys(jap?.checkDetails).length && (
        <Box className="">
          <h4>
            {t('common:lawsuits')} {t('common:details')}
          </h4>
          <Box className="detail-group-grid-5">
            <Box>
              <span>{t('common:totalLawsuits')}</span>
              <p>{jap?.checkDetails?.TotalLawsuits ?? '---'}</p>
            </Box>
            <Box>
              <span>
                {t('common:totalLawsuits')} {t('common:asAuthor')}
              </span>
              <p>{jap?.checkDetails?.TotalLawsuitsAsAuthor ?? '---'}</p>
            </Box>
            <Box>
              <span>
                {t('common:totalLawsuits')} {t('common:asDefendant')}
              </span>
              <p>{jap?.checkDetails?.TotalLawsuitsAsDefendant ?? '---'}</p>
            </Box>
            <Box>
              <span>
                {t('common:totalLawsuits')} {t('common:asOther')}
              </span>
              <p>{jap?.checkDetails?.TotalLawsuitsAsOther ?? '---'}</p>
            </Box>
            <Box>
              <span>{t('common:firstLawsuitDate')}</span>
              <p>{jap?.checkDetails?.FirstLawsuitDate ?? '---'}</p>
            </Box>
            <Box>
              <span>
                {t('common:last30')} {t('common:lawsuits')}
              </span>
              <p>{jap?.checkDetails?.Last30DaysLawsuits ?? '---'}</p>
            </Box>
            <Box>
              <span>
                {t('common:last90')} {t('common:lawsuits')}
              </span>
              <p>{jap?.checkDetails?.Last90DaysLawsuits ?? '---'}</p>
            </Box>
            <Box>
              <span>
                {t('common:last180')} {t('common:lawsuits')}
              </span>
              <p>{jap?.checkDetails?.Last180DaysLawsuits ?? '---'}</p>
            </Box>
            <Box>
              <span>
                {t('common:last365')} {t('common:lawsuits')}
              </span>
              <p>{jap?.checkDetails?.Last365DaysLawsuits ?? '---'}</p>
            </Box>
            <Box>
              <span>{t('common:lastLawsuitDate')}</span>
              <p>{jap?.checkDetails?.LastLawsuitDate ?? '---'}</p>
            </Box>
          </Box>
          {jap?.checkDetails?.informação && (
            <Box>
              <span>{t('common:information')}</span>
              <p>{jap?.checkDetails?.informação}</p>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default CheckJap
