import './checkbox.scss'
import { Box, Checkbox, Typography } from '@mui/material'
import { useState, useEffect } from 'react'

const CheckboxItem = ({ propertie, ruleParameters, setRuleParameters, label, text, isEditing }) => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    if (ruleParameters[propertie] !== undefined) {
      setValue(ruleParameters[propertie])
    } else {
      setValue(false)
    }
  }, [propertie, ruleParameters])

  const checkHandler = (newValue) => {
    setValue(newValue)
    updateRuleParameters(newValue)
  }

  const updateRuleParameters = (newValue) => {
    const updatedParameters = { ...ruleParameters }

    if (newValue) {
      updatedParameters[propertie] = newValue
    } else {
      delete updatedParameters[propertie]
    }

    setRuleParameters(updatedParameters)
  }

  return (
    <Box className="checkbox-input-container">
      <Box className="checkbox-input-row">
        <Box className="checkbox-label">
          <Typography variant="title3">{label}</Typography>
          <Checkbox disabled={!isEditing} checked={value} onChange={() => checkHandler(!value)} />
        </Box>

        {text && <Typography variant="subtitle4">{text}</Typography>}
      </Box>
    </Box>
  )
}

export default CheckboxItem
