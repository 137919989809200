/* eslint-disable no-lone-blocks */
import './datatable.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Fab, Link, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toLocaleUTCDateString } from '../time/timeHelper'
import { useGovChecks } from '../../../pages/pages-hooks/useGovChecks'
import { TableChip } from '../../utilities/TableChip'
import { camelCaseFormatter } from '../../utilities/formatters/camelCase'
import { useState } from 'react'
import { updategovChecks } from '../../../features/govCheck/govCheckSlice'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@mui/icons-material'
import '../../../pages/govCheck/govCheck.scss'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import AlertFetchSpinner from '../alertFetchSpinner/alertFetchSpinner'

const DatatableGovCheck = ({ page, setPage, rowsPerPage, setRowsPerPage, setIsLoading, setModalOpen }) => {
  const { emailClient, accessToken } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.govCheck?.filters)
  const govChecks = useGovChecks(emailClient, setIsLoading)
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const updateList = () => {
    const { limit, offset, fromDate, toDate, page, id, type } = queryState ? queryState : { ...null }

    function buildUrl(baseUrl) {
      let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}&deleted=false`
      if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
      if (type?.length) url += `&type=${type}`
      if (id?.length) url += `&id=${id}`
      return url
    }

    const baseUrlGovCheck = `${process.env.REACT_APP_BASEURL}/govCheck/getAllGovChecks/${emailClient}`
    let url = buildUrl(baseUrlGovCheck)

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/govChecks.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updategovChecks(aux))
          setIsLoading(false)
        }

        if (!res.data.length) {
          dispatch(updategovChecks([]))
          navigate('/')
        }
      })
      .catch((error) => {
        console.error('[HOOK: useGovChecks] --> ', error)
        setIsLoading(false)
      })
  }

  const handleDelete = (id) => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/govCheck/delete/${id}`
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)

            updateList()
            handleFetch(false, 'GovCheck deletion success')
          }
        })
        .catch((error) => {
          console.error('[DELETE GOVCHECK ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : 'GovCheck deletion error')
        })
    } else {
      updateList()
      handleFetch(false, 'GovCheck deletion success')
    }
  }

  const columns = [
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        return (
          <Link href={`/govCheck/${params.row._id}`}>
            <Typography variant="number">{params.row._id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'type',
      headerName: t('common:type'),
      flex: 2,
      minWidth: 190,
      renderCell: (params) => {
        return <Typography variant="subtitle4">{camelCaseFormatter(params.row.type)}</Typography>
      },
    },
    {
      field: 'document',
      headerName: t('common:documentN'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.details?.document ?? '---'}</Typography>
      },
    },
    {
      field: 'isValid',
      headerName: t('common:validation'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <TableChip action={params.row.details?.isValid ? 'valid' : 'invalid'} />
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <Typography variant="number">{toLocaleUTCDateString(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'action',
      headerName: t('common:action'),
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      minWidth: 80,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Fab variant="close" onClick={() => handleDelete(params.row._id)}>
            <DeleteOutlined />
          </Fab>
        )
      },
    },
  ]

  return (
    <Box>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
      <DataGridComponent
        rows={Array.isArray(govChecks?.data) && govChecks?.data?.length > 0 ? govChecks?.data : []}
        columns={columns}
        count={govChecks?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setIsLoading={setIsLoading}
        setModalOpen={setModalOpen}
      />
    </Box>
  )
}

export default DatatableGovCheck

{
  /* <Box className={'datatable'}>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
      <TableContainer className="wrapper">
        <TableContainer>
          <Box className="grid-table-container">
            <DataGrid
              getRowId={(row) => row._id}
              className="grid-table"
              rows={(optionalForcedResult && Object.values(optionalForcedResult).length !== 0) ? [optionalForcedResult] : ((
                Array.isArray(govChecks?.data) 
                && govChecks?.data.length > 0 
                && govChecks?.data) || [])}
              columns={columns}
              hideFooter={true}
              autoHeight={true}
            />
          </Box>
        </TableContainer>
        {(Boolean(page) && Boolean(rowsPerPage)) && <TablePagination
          labelRowsPerPage={t('common:rows')}
          component="div"
          className="pagination-bottom"
          count={govChecks?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        }
      </TableContainer>
    </Box> */
}
