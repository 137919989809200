import './lowTransactionValues.scss'
import Button from '@mui/material/Button'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useEffect, useState } from 'react'
import { Autocomplete, Box, Fab, InputLabel, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'
import { CURRENCIES_MANUAL } from '../../../../../pages/kyt/transaction/transaction-constants'

const LowTransactionValues = ({ propertie, ruleParameters, setRuleParameters, isEditing, filterTemplate }) => {
  const [ltv, setLtv] = useState([])
  const { t } = useTranslation(['common'])

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters.length === 0) return -1
    if (Array.isArray(ruleParameters) && ruleParameters) {
      return ruleParameters.map((e) => e.filterTemplate)?.indexOf(filterTemplate)
    }
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux && JSON.stringify(aux[propertie]) !== JSON.stringify(ltv)) {
      setLtv(aux[propertie] || [])
    }

    if (!aux) {
      setLtv([])
    }
  }, [ruleParameters, propertie, filterTemplate])

  const handleValueChange = (valueKey, value, index) => {
    const list = [...ltv]
    const aux = { ...list[index], [valueKey]: value !== '' ? value : '' }
    list[index] = aux
    setLtv(list)
    updateRuleParameters(list)
  }

  const handleServiceRemove = (index) => {
    const list = [...ltv]
    list.splice(index, 1)
    setLtv(list)
    updateRuleParameters(list)
  }

  const handleServiceAdd = () => {
    const objAux = { currency: '', min: '', max: '' }
    const newList = [...ltv, objAux]
    setLtv(newList)
    updateRuleParameters(newList)
  }

  const updateRuleParameters = (newList) => {
    const index = getIndex()
    let all = [...ruleParameters]
    let previous = index >= 0 && all[index] ? { ...all[index] } : {}

    previous[propertie] = newList
    previous.filterTemplate = filterTemplate

    if (index !== -1) {
      all.splice(index, 1)
    }
    all.push(previous)

    setRuleParameters(all)
  }

  return (
    <Box>
      {ltv &&
        ltv?.map((item, index) => (
          <Box key={index}>
            <Box className="low-row">
              <Box className="low-pair">
                <InputLabel>{t('common:currency')}</InputLabel>
                <Autocomplete
                  options={CURRENCIES_MANUAL}
                  getOptionLabel={(option) => mapCodesToNames(option)}
                  filterSelectedOptions
                  value={item.currency || null}
                  disabled={!isEditing}
                  onChange={(e, value) => handleValueChange('currency', value, index)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
                    />
                  )}
                />
              </Box>
              <Box className="low-pair">
                <Box className="low-label">
                  <InputLabel>Max</InputLabel>
                </Box>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  size="small"
                  type="number"
                  value={item.max || ''}
                  onChange={(event) => handleValueChange('max', event.target.value, index)}
                />
              </Box>
              <Box className="low-pair">
                <Box className="low-label">
                  <InputLabel>Min</InputLabel>
                  <span className="mandatory">* </span>
                </Box>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  size="small"
                  type="number"
                  value={item.min || ''}
                  onChange={(event) => handleValueChange('min', event.target.value, index)}
                />
              </Box>
              <Box className="low-delete">
                {isEditing && (
                  <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(index)}>
                    <DeleteOutlineOutlinedIcon />
                  </Fab>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      <Box className="button-right">
        {isEditing && (
          <Button endIcon={<AddCircleOutlineIcon />} variant="outlinedGrey" onClick={handleServiceAdd}>
            {t('common:add')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default LowTransactionValues
