import guenoApi from '../../services/guenoApi'

const kycApi = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findKycPersons: builder.query({
      query: ({ limit, page, text, status }) => {
        return {
          url: '/kyc/person/search',
          params: {
            limit,
            page,
            text,
            status,
          },
        }
      },
      providesTags: ['kycPersonList'],
    }),
    createKycPerson: builder.mutation({
      query: ({ body }) => ({
        url: `/kyc/person`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['kycPersonList'],
    }),
    updateKycPerson: builder.mutation({
      query: ({ id, body }) => ({
        url: `/kyc/person/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['kycPerson'],
    }),
    getKycPerson: builder.query({
      query: ({ id }) => {
        return {
          url: `/kyc/person/${id}`,
        }
      },
      providesTags: ['kycPerson'],
    }),
    getKycPersonChecks: builder.query({
      query: ({ id, type }) => {
        return {
          url: `/kyc/person/${id}/checks`,
          params: {
            type,
          },
        }
      },
      providesTags: ['kycPersonChecks'],
    }),
    getKycCheck: builder.query({
      query: ({ id }) => {
        return {
          url: `/kyc/check/${id}`,
        }
      },
      providesTags: ['kycCheck'],
    }),
    updateKycCheck: builder.mutation({
      query: ({ id, body }) => ({
        url: `/kyc/check/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['kycCheck', 'kycPersonChecks'],
    }),
    createKycPersonCheck: builder.mutation({
      query: ({ id, body }) => {
        const formData = new FormData()

        formData.append('documentFront', body.documentFront)

        if (body.documentBack) {
          formData.append('documentBack', body.documentBack)
        }

        if (body.checkTypes?.includes('identity_check')) {
          formData.append('selfie', body.selfie)
        }

        for (const checkType of body.checkTypes) {
          formData.append('checkTypes[]', checkType)
        }
        formData.append('country', body.country)
        formData.append('documentType', body.documentType)

        if (body.webhook) {
          formData.append('webhook', body.webhook)
        }

        return {
          url: `/kyc/person/${id}/checks`,
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
      invalidatesTags: ['kycPersonChecks', 'kycCheckList'],
    }),
    createKycPersonComment: builder.mutation({
      query: ({ id, body }) => ({
        url: `/kyc/person/${id}/comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['kycPerson'],
    }),
    findKycChecks: builder.query({
      query: ({ limit, page, text, status }) => {
        return {
          url: '/kyc/check/search',
          params: {
            limit,
            page,
            text,
            status,
          },
        }
      },
      providesTags: ['kycCheckList'],
    }),
  }),
})

export const {
  useFindKycPersonsQuery,
  useCreateKycPersonMutation,
  useUpdateKycPersonMutation,
  useGetKycPersonQuery,
  useGetKycPersonChecksQuery,
  useGetKycCheckQuery,
  useUpdateKycCheckMutation,
  useCreateKycPersonCheckMutation,
  useCreateKycPersonCommentMutation,
  useFindKycChecksQuery,
} = kycApi
