import './selectSingle.scss'
import { useState, useEffect } from 'react'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'
import { useTranslation } from 'react-i18next'

const SelectSingle = ({ propertie, ruleParameters, setRuleParameters, posibleValues, desc, isEditing, schema }) => {
  const [selected, setSelected] = useState(null)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    const currentValue = ruleParameters[propertie]

    if (currentValue !== undefined && currentValue !== selected) {
      setSelected(currentValue)
    }

    if (currentValue === undefined) {
      setSelected(null)
    }
  }, [propertie, ruleParameters])

  const handleChange = (event, value) => {
    setSelected(value)
    const updatedParameters = { ...ruleParameters, [propertie]: value }
    setRuleParameters(updatedParameters)
  }

  return (
    <Box className="select-single-container">
      {desc && <Typography variant="subtitle4">{desc}</Typography>}

      <Autocomplete
        options={posibleValues}
        getOptionLabel={(option) => mapCodesToNames(option)}
        filterSelectedOptions
        value={selected}
        disabled={!isEditing}
        onChange={(e, value) => handleChange(e, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
          />
        )}
      />
    </Box>
  )
}

export default SelectSingle
