import './tags.scss'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Button from '@mui/material/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useEffect, useState } from 'react'
import { Box, TextField, Chip, Autocomplete, InputLabel, Fab, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFindKytUsersQuery, useFindTransactionsQuery } from '../../../../../features/transactions/transactionSlice'

const Tags = ({ propertie, ruleParameters, setRuleParameters, isEditing, filterTemplate, description, type }) => {
  const [tat, setTat] = useState([])
  const [keyState, setKeyState] = useState('')
  const [valueState, setValueState] = useState([])
  const { t } = useTranslation(['common'])
  const [uniqueTransactionsKeys, setUniqueTransactionsKeys] = useState([])
  const [uniqueTransactionsValues, setUniqueTransactionsValues] = useState([])
  const [uniqueUsersKeys, setUniqueUsersKeys] = useState([])
  const [uniqueUsersValues, setUniqueUsersValues] = useState([])
  const { emailClient } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.transaction.filters)
  const queryStateUsers = useSelector((state) => state.user.filters)

  const { data: allTransactions } = useFindTransactionsQuery({
    ...queryState,
    emailClient,
    viewMode: true,
    page: 0,
    limit: 100,
    offset: 0,
  })

  const { data: consumers } = useFindKytUsersQuery({
    ...queryStateUsers,
    type: 'consumerUsers',
    emailClient,
    page: 0,
    limit: 50,
    offset: 0,
  })

  const { data: businesses } = useFindKytUsersQuery({
    ...queryStateUsers,
    type: 'businessUsers',
    emailClient,
    page: 0,
    limit: 50,
    offset: 0,
  })

  useEffect(() => {
    if (allTransactions?.unique) {
      setUniqueTransactionsKeys(allTransactions?.unique?.uniqueKeys)
      setUniqueTransactionsValues(allTransactions?.unique?.uniqueValues)
    }
  }, [allTransactions])

  useEffect(() => {
    if (consumers?.unique && businesses?.unique) {
      setUniqueUsersKeys([...(consumers?.unique?.uniqueKeys ?? []), ...(businesses?.unique?.uniqueKeys ?? [])])
      setUniqueUsersValues([...(consumers?.unique?.uniqueValues ?? []), ...(businesses?.unique?.uniqueValues ?? [])])
    }
  }, [consumers, businesses])

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)
    if (aux && JSON.stringify(aux[propertie]) !== JSON.stringify(tat)) {
      setTat(aux[propertie] || [])
    }

    if (!aux) {
      setTat([])
      setValueState([])
      setKeyState('')
    }
  }, [ruleParameters, filterTemplate, propertie])

  const handleKeyChange = (value, index) => {
    const list = [...tat]
    list[index] = { ...list[index], key: value }
    setTat(list)
    updateRuleParameters(list)
  }

  const handleValueChange = (value, index) => {
    const list = [...tat]
    list[index] = { ...list[index], value }
    setTat(list)
    updateRuleParameters(list)
  }

  const handleServiceRemove = (index) => {
    const list = tat.filter((_, i) => i !== index)
    setTat(list)
    updateRuleParameters(list)
  }

  const handleServiceAdd = () => {
    if (keyState && valueState?.length > 0) {
      const newTag = { key: keyState, value: [...valueState] }
      const newList = [...tat, newTag]
      setTat(newList)
      setKeyState('')
      setValueState([])
      updateRuleParameters(newList)
    }
  }

  const updateRuleParameters = (newList) => {
    const updatedParameters = ruleParameters?.map((param) =>
      param.filterTemplate === filterTemplate ? { ...param, [propertie]: newList } : param,
    )

    if (!updatedParameters?.some((param) => param.filterTemplate === filterTemplate)) {
      updatedParameters.push({ [propertie]: newList, filterTemplate })
    }

    setRuleParameters(updatedParameters)
  }

  return (
    <Box>
      {description && <Typography variant="subtitle4">{description}</Typography>}
      {tat &&
        tat?.map((item, index) => (
          <Box key={index}>
            <Box className="tag-row">
              <Box className="tag-pair">
                <InputLabel>{t('common:key')}</InputLabel>
                <TextField
                  className="Input"
                  disabled={!isEditing}
                  variant="outlined"
                  size="small"
                  placeholder="New Key"
                  value={item.key || ''}
                  onChange={(event) => handleKeyChange(event.target.value, index)}
                />
              </Box>
              <Box className="tag-pair">
                <InputLabel>{t('common:values')}</InputLabel>
                <Autocomplete
                  disabled={!isEditing}
                  value={item.value}
                  onChange={(event, newValue) => handleValueChange(newValue, index)}
                  multiple
                  size="small"
                  options={[]}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value?.map((option, index) => (
                      <Chip variant="transparent" color="primary" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder={t('common:values')} />
                  )}
                />
              </Box>
              {isEditing && (
                <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(index)}>
                  <DeleteOutlineOutlinedIcon />
                </Fab>
              )}
            </Box>
          </Box>
        ))}

      <Box className="tag-row">
        <Box className="tag-pair">
          <InputLabel>{t('common:key')}</InputLabel>
          <Autocomplete
            disabled={!isEditing}
            freeSolo
            size="small"
            options={type === 'transactions' ? uniqueTransactionsKeys : uniqueUsersKeys}
            value={keyState}
            onChange={(event, newValue) => setKeyState(newValue)}
            onInputChange={(event, newInputValue) => setKeyState(newInputValue)}
            renderInput={(params) => (
              <TextField {...params} placeholder={`${t('common:insert')} ${t('common:key')}...`} variant="outlined" />
            )}
          />
        </Box>
        <Box className="tag-pair">
          <InputLabel>{t('common:values')}</InputLabel>
          <Autocomplete
            disabled={!isEditing}
            value={valueState}
            onChange={(event, newValue) => setValueState(newValue)}
            multiple
            options={type === 'transactions' ? uniqueTransactionsValues : uniqueUsersValues}
            freeSolo
            size="small"
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="transparent" color="primary" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder={t('common:insertValuesPressEnter')} />
            )}
          />
        </Box>
      </Box>

      <Box className="button-right">
        {isEditing && (
          <Button
            endIcon={<AddCircleOutlineIcon />}
            disabled={keyState === '' || valueState === ''}
            variant="outlinedGrey"
            onClick={handleServiceAdd}
          >
            {t('common:add')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default Tags
