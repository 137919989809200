import './checklist.scss'
import { Box, Fab, Modal, Paper, Typography } from '@mui/material'
import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatatableChecklist from '../../../components/common/datatable/DatatableChechlist'
import { updateChecklistsFilters } from '../../../features/checklist/checklistSlice'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { ChecklistDetails } from './ChecklistDetails'
import { FilterModal } from '../../../components/utilities/FilterModal'
import MyDatePicker from '../../../components/utilities/MyDatePicker'
import Spinner from '../../../components/common/spinner/spinner'

const Checklist = () => {
  const queryState = useSelector((state) => state.chechlists?.filters)
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [id, setId] = useState('')
  const [selectedDate, setSelectedDate] = useState(queryState?.fromDate)
  const [toDate, setToDate] = useState(null)
  const [open, setOpen] = useState(false)
  const [isCreation, setIsCreation] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [check, setCheck] = useState('')
  const [checklists, setChecklists] = useState([])
  const { t } = useTranslation(['common', 'messages'])
  const [isEditing, setIsEditing] = useState(false)
  const handleClose = () => {
    setOpen(false)
    setIsEditing(false)
  }

  const dispatch = useDispatch()

  const handleResetButton = () => {
    if (id !== '') setId('')
    if (selectedDate) setSelectedDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateChecklistsFilters({
        page: 0,
        limit: 25,
        offset: 0,
        id: '',
        fromDate: null,
        toDate: '',
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    return dispatch(
      updateChecklistsFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        id,
        fromDate: selectedDate?.toISOString(),
        toDate: new Date(toDate)?.toISOString(),
      }),
    )
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [selectedDate])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner />}

        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:checklists')}</Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setOpen(true)
                setIsCreation(true)
                setCheck({})
              }}
            >
              {t('common:createChecklist')}
            </Button>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <Paper elevation={0}>
          <DatatableChecklist
            setIsLoading={setIsLoading}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setCheck={setCheck}
            setOpen={setOpen}
            setIsCreation={setIsCreation}
            setIsEditing={setIsEditing}
            checklists={checklists}
            setChecklists={setChecklists}
            setModalOpen={setModalOpen}
          />
        </Paper>
      </Box>

      <Modal open={open} onClose={() => handleClose()} onOpen={() => setOpen(true)}>
        <Box className="modal">
          <Box className="checklist-drawer-head">
            <Typography variant="title">
              {check?.id ? `Checklist ID: ${check?.id}` : t('common:createChecklist')}
            </Typography>

            <Fab
              variant="close"
              onClick={() => {
                handleClose()
              }}
            >
              <CloseIcon />
            </Fab>
          </Box>
          <ChecklistDetails
            check={check}
            isEdition={check.id || !isCreation}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            checklists={checklists}
            setChecklists={setChecklists}
            setOpen={setOpen}
          />
        </Box>
      </Modal>

      {/* MODAL */}
      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          {/* TSX DETAILS */}
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
            <TextField
              size="small"
              value={id}
              onChange={(event) => setId(event.target.value)}
              placeholder={`${t('common:insert')}  ID`}
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default Checklist
