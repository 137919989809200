/* eslint-disable no-unreachable */
import * as React from 'react'
import './swipeableSidebar.scss'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useState } from 'react'
import { dsTheme } from '../../../themes/theme-light'
import { Collapse, Fab, IconButton, Switch } from '@mui/material'
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import { useTranslation } from 'react-i18next'
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined'
import TollIcon from '@mui/icons-material/Toll'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined'
import CurrencyBitcoinOutlinedIcon from '@mui/icons-material/CurrencyBitcoinOutlined'
import { NavLink, useLocation } from 'react-router-dom'
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined'
import { useEffect } from 'react'
import { updateTheme } from '../../../features/theme/themeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { dsThemeDark } from '../../../themes/theme-dark'
import { useRef } from 'react'
import { MediationOutlined } from '@mui/icons-material'
import { IOSSwitch } from '../../utilities/Switches'
import { selectUserMaster } from '../../../features/kyb/kybCountrySlice'

const drawerWidth = '100%'

export default function MiniDrawer({ activeServices }) {
  const { t } = useTranslation(['common'])
  const [open, setOpen] = useState(true)
  const location = useLocation()
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState({})
  const { emailClient } = useSelector((state) => state.login)
  const { theme: themeGueno } = useSelector((state) => state.theme)
  const userMaster = useSelector((state) => selectUserMaster(state, emailClient))

  const listRef = useRef(null)

  const openedMixin = (theme) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: themeGueno === 'light' ? dsTheme.drawer.backgroundColor : dsThemeDark.drawer.backgroundColor,
    overflowX: 'hidden',
    border: 'none',
    borderRadius: '0px 24px 24px 0px',
    position: 'inherit',
  })

  const closedMixin = (theme) => ({
    width: `50%`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(11.5)} + 1px)`,
    },
    overflowX: 'hidden',
    border: 'none',
    backgroundColor: themeGueno === 'light' ? dsTheme.drawer.backgroundColor : dsThemeDark.drawer.backgroundColor,
    borderRadius: '0px 24px 24px 0px',
    position: 'inherit',
  })

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: themeGueno === 'light' ? dsTheme.drawer.backgroundColor : dsThemeDark.drawer.backgroundColor,
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }))

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }))

  const toggleTheme = () => {
    const newTheme = themeGueno === 'light' ? 'dark' : 'light'
    return dispatch(updateTheme(newTheme))
  }

  const verifySideBtn = (paths, value) => {
    return paths && paths.length > 0
      ? paths.some((path) => {
          if (value === 'overview') return !path.includes(value)
          else return path.includes(value)
        })
      : false
  }

  const verifySideSubBtn = (paths, values) => {
    return values && values.length > 0 ? values?.some((value) => verifySideBtn(paths, value)) : false
  }

  const handleDrawer = () => {
    setOpen((prevOpen) => {
      if (prevOpen) {
        setExpanded({})
      }
      return !prevOpen
    })
  }

  const handleScroll = () => {
    if (listRef.current) {
      localStorage.setItem('drawerScrollPosition', listRef.current.scrollTop)
    }
  }

  useEffect(() => {
    mappedServices.forEach((service, index) => {
      if (service.routes?.some((route) => route.enum?.some((path) => location.pathname.startsWith(path)))) {
        setExpanded((prev) => ({ ...prev, [index]: true }))
      }
    })
    handleSetScroll()
  }, [location.pathname])

  const handleToggle = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  const handleSetScroll = () => {
    const savedScrollPosition = localStorage.getItem('drawerScrollPosition') || 0
    setTimeout(() => {
      if (listRef.current) {
        listRef.current.scrollTop = parseInt(savedScrollPosition, 10)
      }
    }, 100)
  }

  const isServiceActive = (service) => {
    return (
      '/' + location.pathname?.split('/')[1] === service.url ||
      service.routes?.some((route) => route.enum?.some((path) => location.pathname.startsWith(path)))
    )
  }

  const isSubserviceActive = (subservice) => {
    return subservice.enum?.some((path) => location.pathname.startsWith(path))
  }

  const mappedServices = [
    {
      service: 'overview',
      title: t('common:home'),
      icon: <HomeOutlinedIcon />,
      url: '/',
      enum: ['/'],
    },
    {
      service: 'healthCheck',
      title: t('common:healthchecks'),
      icon: <TollIcon />,
      url: '/health-checks',
      enum: ['/health-checks'],
    },
    {
      service: 'caseManagement',
      title: t('common:intelligenceFraudEng'),
      icon: <LightbulbOutlinedIcon />,
      routes: [
        {
          service: ['caseManagement'],
          url: '/case-management/MyCases',
          title: t('common:casesAndInvestigation'),
          enum: [
            '/case-management/CaseHistory',
            '/case-management/MyCases',
            '/case-management/AllAlerts',
            '/case-management/AdminPanel',
            '/case-management/*',
          ],
        },
        {
          service: ['workflows'],
          url: '/case-workflows/all',
          title: t('common:workflows'),
          enum: ['/case-workflows/all', '/case-workflows/creation'],
        },
        {
          service: [''],
          url: '/device',
          title: t('common:deviceIntelligence'),
          enum: ['/device'],
          isDemo: true,
        },
        {
          service: [''],
          url: '/exploration',
          title: t('common:explorationGraph'),
          enum: ['/exploration'],
          isDemo: true,
        },
        // emailClient === 'koywe@gueno.com' && {
        //   service: [''],
        //   url: 'https://docs.google.com/presentation/d/1mVJABDSklVWHQq55CPVqRU7-tGo_DCi3/edit?usp=sharing&ouid=113747670275748630654&rtpof=true&sd=true',
        //   title: t('common:workflow'),
        //   isKoywe: emailClient === 'koywe@gueno.com',
        // },
      ],
    },
    {
      service: '',
      title: t('common:manualChecks'),
      icon: <BeenhereOutlinedIcon />,
      routes: [
        // {
        //   service: ['kyb'],
        //   url: '/kyb/manual',
        //   title: 'KYB',
        //   enum: ['/kyb/manual', '/kyc/manual'],
        // },
        {
          service: ['kyt'],
          url: '/kyt/manual/consumer',
          title: 'KYT',
          enum: ['/kyt/manual/consumer', '/kyt/manual/business', '/kyt/manual/transaction'],
        },
        {
          service: ['aml'],
          url: '/aml/manual',
          title: t('common:amlAndList'),
          enum: ['/aml/manual'],
        },
        {
          service: ['amlCrypto'],
          url: '/amlCrypto/manual',
          title: t('common:amlCrypto'),
          enum: ['/amlCrypto/manual'],
        },
        {
          service: ['onboarding'],
          url: '/onboardings/manual',
          title: t('common:onboarding'),
          enum: ['/onboardings/manual'],
        },
        {
          service: ['govCheck'],
          url: '/govCheck/manual',
          title: `${t('common:govcheck')}`,
          enum: ['/govCheck/manual'],
        },
      ],
    },
    {
      service: 'kyb',
      title: t('common:kyb'),
      icon: <LeaderboardOutlinedIcon />,
      routes: [
        {
          service: ['kyb'],
          url: '/kyb/AllKybs',
          title: t('common:verificationHistory'),
          enum: ['/kyb/AllKybs', '/kyb/MyKybs', '/kyb/AllKycs', '/kyb/MyKycs'],
        },
        {
          service: ['kyb'],
          url: '/kyb/notifications/pending',
          title: t('common:companyNotifications'),
          enum: ['/kyb/notifications/pending', '/kyb/notifications/completed', '/kyb/notifications/canceled'],
        },
        {
          service: ['kyb'],
          url: '/kyb/lists/CNPJ',
          title: `${t('common:company')} ${t('common:blocklists')}`,
          enum: ['/kyb/lists/CNPJ', '/kyb/lists/CPF'],
        },
      ],
    },
    {
      service: 'kyc',
      title: t('common:KYC'),
      icon: <LeaderboardOutlinedIcon />,
      routes: [
        {
          service: ['kyc'],
          url: '/kyc/persons',
          title: t('common:persons'),
          enum: ['/kyc/persons'],
        },
        {
          service: ['kyc'],
          url: '/kyc/checks',
          title: t('common:checks'),
          enum: ['/kyc/checks'],
        },
      ],
    },
    {
      service: 'ecommerceRisk',
      title: t('common:ecommerceRisk'),
      icon: <StorefrontOutlinedIcon />,
      url: '/ecommerceRisk',
    },
    {
      service: 'kyt',
      title: t('common:transactionMonitoring'),
      icon: <ShieldOutlinedIcon />,
      routes: [
        {
          service: ['transactions:email'],
          url: '/transactions',
          title: t('common:transactions'),
          enum: ['/transactions', '/transactions/*'],
        },
        {
          service: ['consumerUsers:email', 'businessUsers:email'],
          url: '/users?type=consumerUsers',
          title: t('common:clients'),
          enum: ['users?type=consumerUsers', '/users?type=businessUsers', '/users'],
        },
        {
          service: ['externalPaymentMethods:email'],
          url: '/externalPaymentMethods',
          title: t('common:externalPaymentMethods'),
          enum: ['/externalPaymentMethods', '/externalPaymentMethods/*'],
        },
        {
          service: ['retrieveClientInstanceRules', 'retrieveClientRules', 'sendSlackMessage'],
          url: '/rules/my-rules',
          title: t('common:rules'),
          enum: ['/rules/my-rules', '/rules/library'],
        },
      ],
    },
    {
      service: 'onboarding',
      title: t('common:onboarding'),
      icon: <LibraryBooksIcon />,
      url: '/onboardings',
    },
    {
      service: 'aml',
      title: t('common:amlAndList'),
      icon: <ManageSearchIcon />,
      routes: [
        {
          service: ['aml'],
          url: '/aml',
          title: t('common:global'),
          enum: ['/aml'],
        },
        {
          service: ['amlBR'],
          url: '/br-aml',
          title: t('common:brazil'),
          enum: ['/br-aml'],
        },
      ],
    },
    // {
    //   service: 'aml',
    //   title: t('common:amlAndList'),
    //   icon: <ManageSearchIcon />,
    //   url: '/aml',
    // },
    // {
    //   service: 'amlBR',
    //   title: t('common:amlBrazil'),
    //   icon: <ManageSearchIcon />,
    //   url: '/aml-br',
    // },
    {
      service: 'amlCrypto',
      title: t('common:amlCrypto'),
      icon: <CurrencyBitcoinOutlinedIcon />,
      url: '/amlCrypto',
    },
    {
      service: 'govCheck',
      title: t('common:govcheck'),
      icon: <AssuredWorkloadOutlinedIcon />,
      url: '/govCheck',
    },
    {
      service: 'batch',
      title: t('common:batchLoading'),
      icon: <MediationOutlined />,
      url: '/batch',
    },
    {
      service: 'admin',
      title: t('common:admin'),
      icon: <PersonOutlineOutlinedIcon />,
      routes: [
        {
          service: ['userall'],
          url: '/admin/users',
          title: t('common:dashboardUsers'),
          enum: ['/admin/users'],
        },
        {
          service: ['adminrolesemailClient'],
          url: '/admin/roles',
          title: t('common:roles'),
          enum: ['/admin/roles'],
        },
        {
          service: ['lists'],
          url: '/admin/lists/whitelist',
          title: t('common:lists'),
          enum: ['/admin/lists/whitelist', '/admin/lists/blocklist', '/admin/lists/merchants', '/admin/lists/neutral'],
        },
        {
          service: [''],
          url: '/admin/oppositionControl',
          title: t('common:oppositionControl'),
          enum: ['/admin/oppositionControl'],
        },
        {
          service: ['usermaster:emailClient'],
          url: '/admin/security',
          title: t('common:securityParameters'),
          enum: ['/admin/security'],
        },
        {
          service: ['allClientConsumptionsBetweenDates'],
          url: '/admin/consumptions',
          title: t('common:consumptions'),
          enum: ['/admin/consumptions'],
        },
        {
          service: ['alertSettingsemailClient'],
          url: '/admin/alerts',
          title: t('common:alertSettings'),
          enum: ['/admin/alerts'],
        },
        {
          service: ['webhooks'],
          url: '/admin/webhooks',
          title: t('common:webhooks'),
          enum: ['/admin/webhooks'],
        },
        {
          service: [''],
          url: '/checklists',
          title: t('common:checklists'),
          enum: ['/checklists'],
        },
        {
          service: [''],
          url: '/workflows',
          title: t('common:workflows'),
          enum: ['/workflows', '/workflows/options', '/workflows/ai', '/workflows/*'],
          isDemo: true,
        },
        {
          service: ['riskmatrix'],
          url: '/admin/riskMatrix',
          title: t('common:riskMatrix'),
          enum: ['/admin/riskMatrix'],
        },
        {
          service: ['kyt-import'],
          url: '/admin/kyt-import',
          title: t('messages:kyt-import.menu'),
          enum: ['/kyt-import'],
        },
      ],
    },
    {
      service: 'reportsclienttypes',
      title: t('common:reports'),
      icon: <GppMaybeOutlinedIcon />,
      url: '/reports',
    },
  ]

  const ServiceListItem = React.memo(({ service, index, expanded, handleToggle, isServiceActive, themeGueno }) => {
    return (
      <ListItem
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: isServiceActive(service) ? (themeGueno === 'light' ? '#F4F8F6' : '#171B18') : 'transparent',
          borderRadius: '0.75rem',
          paddingTop: '0',
          paddingBottom: '0',
        }}
      >
        <NavLink
          to={service.url ?? ''}
          className="item-button"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexGrow: 1,
          }}
        >
          <ListItemButton
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '.5rem',
              padding: open ? '.5rem' : '8px 16px',
            }}
            onClick={() => handleToggle(index)}
          >
            <ListItemIcon className="item-icon">{service.icon}</ListItemIcon>
            <Collapse in={open}>
              <ListItemText primary={service.title} />
            </Collapse>
            {service.routes && (
              <IconButton size="small" sx={{ marginLeft: 'auto' }}>
                {expanded[index] ? (
                  <KeyboardArrowUpIcon sx={{ fill: themeGueno === 'light' ? 'currentcolor' : '#919D95' }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ fill: themeGueno === 'light' ? 'currentcolor' : '#919D95' }} />
                )}
              </IconButton>
            )}
          </ListItemButton>
        </NavLink>
        {service.routes && (
          <Collapse in={expanded[index]}>
            {service.routes
              ?.filter((ss) => (!process.env.REACT_APP_IS_DEMO ? !ss.isDemo : ss))
              .map((subservice, subIndex) => {
                return (
                  verifySideSubBtn(activeServices, subservice.service) &&
                  (subservice.isKoywe ? (
                    <a
                      href={subservice.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={subIndex}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '1.5rem',
                      }}
                    >
                      <ListItemButton>
                        <ListItemText primary={subservice.title} />
                      </ListItemButton>
                    </a>
                  ) : (
                    <NavLink
                      to={subservice.url}
                      key={subIndex}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                    >
                      <Collapse in={open}>
                        <ListItemButton
                          style={{
                            display: 'flex',
                            gap: '0.25rem',
                            alignItems: 'center',
                            marginLeft: '1.5rem',
                          }}
                        >
                          {isSubserviceActive(subservice) && (
                            <Box className="icon-global item-icon" style={{ marginBottom: '0.25rem!important' }}>
                              <ArrowForwardIcon />
                            </Box>
                          )}

                          <Box
                            sx={{
                              fontWeight: isSubserviceActive(subservice) ? '500!important' : '400',
                              marginBottom: '0.25rem!important',
                            }}
                          >
                            {subservice.title}
                          </Box>
                        </ListItemButton>
                      </Collapse>
                    </NavLink>
                  ))
                )
              })}
          </Collapse>
        )}
      </ListItem>
    )
  })

  return (
    <Box
      className="swipeable"
      sx={{
        '& ::-webkit-scrollbar-thumb': {
          background: themeGueno === 'light' ? '#c9d2cc!important' : '#171b18!important',
        },
        '& ::-webkit-scrollbar-track': {
          background: themeGueno === 'light' ? '#ecefed!important' : '#242524!important',
        },
      }}
    >
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <Box className="swipeable-wrapper">
          <DrawerHeader>
            {open ? (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0',
                }}
                onClick={handleDrawer}
              >
                <Box className="gueno-logo">
                  <img
                    alt={userMaster?.isNuclea ? 'nuclea-logo' : 'gueno-logo'}
                    src={
                      userMaster?.isNuclea
                        ? '/logo-nuclea.png'
                        : themeGueno === 'light'
                        ? '/logo-gueno-light2.png'
                        : '/logo-gueno2.png'
                    }
                  />
                </Box>
                <Fab size="small" sx={{ minWidth: '2.75rem' }}>
                  {<KeyboardDoubleArrowRightIcon />}
                </Fab>
              </ListItemButton>
            ) : (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  p: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onClick={handleDrawer}
              >
                <Box className="gueno-logo-small">
                  <img
                    alt={userMaster?.isNuclea ? 'nuclea-logo' : 'gueno-logo'}
                    src={userMaster?.isNuclea ? '/logo-nuclea-mini.png' : '/logo-gueno-light2-sm.png'}
                  />
                </Box>
                <Fab size="small" sx={{ minWidth: '2.75rem' }}>
                  {<KeyboardDoubleArrowLeftIcon />}
                </Fab>
              </ListItemButton>
            )}
          </DrawerHeader>
          <List
            ref={listRef}
            onScroll={handleScroll}
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: '100vh',
              paddingRight: '.25rem!important',
            }}
          >
            {mappedServices.map((service, index) => {
              return (
                verifySideBtn(activeServices, service.service) && (
                  <ServiceListItem
                    key={index}
                    service={service}
                    index={index}
                    expanded={expanded}
                    handleToggle={handleToggle}
                    isServiceActive={isServiceActive}
                    themeGueno={themeGueno}
                  />
                )
              )
            })}
          </List>
          {!userMaster?.isNuclea && (
            <Box className="swipeable-bottom">
              <Collapse in={open}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '.5rem',
                    alignItems: 'center',
                  }}
                  className="icon-global-medium"
                >
                  <ListItemIcon className="item-icon">
                    <NightsStayOutlinedIcon />
                  </ListItemIcon>
                  {t('common:darkMode')}
                </Box>
              </Collapse>
              <IOSSwitch
                onChange={() => toggleTheme()}
                checked={themeGueno === 'light' ? false : true}
                variant="large"
                sx={{ marginRight: open ? '0' : '0.7rem' }}
                thememode={themeGueno}
              ></IOSSwitch>
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  )
}
