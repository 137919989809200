import { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import ListOfStrings from '../listOfStrings/listOfStrings'

const FatherListOfStrings = ({ propertie, ruleParameters, setRuleParameters, isEditing, filterTemplate }) => {
  const [finalParameter, setFinalParameter] = useState([])

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux && JSON.stringify(aux[propertie]) !== JSON.stringify(finalParameter)) {
      setFinalParameter(aux[propertie] || [])
    }

    if (!aux && finalParameter.length > 0) {
      setFinalParameter([])
    }
  }, [ruleParameters, filterTemplate, propertie])

  const handleChange = (newValue) => {
    setFinalParameter(newValue)

    const updatedParameters = ruleParameters.map((param) =>
      param.filterTemplate === filterTemplate ? { ...param, [propertie]: newValue } : param,
    )

    if (!updatedParameters.some((param) => param.filterTemplate === filterTemplate)) {
      updatedParameters.push({ [propertie]: newValue, filterTemplate })
    }

    setRuleParameters(updatedParameters)
  }

  return (
    <Box>
      <ListOfStrings
        isEditing={isEditing}
        propertie={propertie}
        ruleParameters={finalParameter}
        setRuleParameters={handleChange}
        defaultListValues={[]}
      />
    </Box>
  )
}

export default FatherListOfStrings
