import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckNegativeMedia = ({ title = '', negativeMediaCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>{title || t('common:negativeMedia')}</Typography>
      <CheckDetailTitle
        email={negativeMediaCheck.email}
        status={negativeMediaCheck.status}
        createdAt={negativeMediaCheck.createdAt}
        updatedAt={negativeMediaCheck.updatedAt}
      />
      <Box className="">
        <h4>{t('common:negativeMediaDetails')}</h4>
        <Box className="detail-group-grid-3">
          <Box>
            <span>{t('common:mediaExposureLevel')}</span>
            <p>{negativeMediaCheck?.checkDetails?.source1?.MediaExposureLevel ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:celebrityLevel')}</span>
            <p>{negativeMediaCheck?.checkDetails?.source1?.CelebrityLevel ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:unpopularityLevel')}</span>
            <p>{negativeMediaCheck?.checkDetails?.source1?.UnpopularityLevel ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:totalNews')}</span>
            <p>{negativeMediaCheck?.checkDetails?.source1?.EntityStatistics?.NewsByRangeDate?.TotalNews ?? '---'}</p>
          </Box>
          <Box>
            <span>
              {t('common:totalNews')} {t('common:last7')}
            </span>
            <p>
              {negativeMediaCheck?.checkDetails?.source1?.EntityStatistics?.NewsByRangeDate?.TotalNewsOnLast7Days ??
                '---'}
            </p>
          </Box>
          <Box>
            <span>
              {t('common:totalNews')} {t('common:last30')}
            </span>
            <p>
              {negativeMediaCheck?.checkDetails?.source1?.EntityStatistics?.NewsByRangeDate?.TotalNewsOnLast30Days ??
                '---'}
            </p>
          </Box>
          <Box>
            <span>
              {t('common:totalNews')} {t('common:last90')}
            </span>
            <p>
              {negativeMediaCheck?.checkDetails?.source1?.EntityStatistics?.NewsByRangeDate?.TotalNewsOnLast90Days ??
                '---'}
            </p>
          </Box>
          <Box>
            <span>
              {t('common:totalNews')} {t('common:last180')}
            </span>
            <p>
              {negativeMediaCheck?.checkDetails?.source1?.EntityStatistics?.NewsByRangeDate?.TotalNewsOnLast180Days ??
                '---'}
            </p>
          </Box>
          <Box>
            <span>
              {t('common:totalNews')} {t('common:last365')}
            </span>
            <p>
              {negativeMediaCheck?.checkDetails?.source1?.EntityStatistics?.NewsByRangeDate?.TotalNewsOnLast365Days ??
                '---'}
            </p>
          </Box>
        </Box>
        {negativeMediaCheck?.checkDetails?.source1?.informação && (
          <Box>
            <span>{t('common:information')}</span>
            <p>{negativeMediaCheck?.checkDetails?.source1?.informação}</p>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CheckNegativeMedia
