import { useParams } from 'react-router-dom'
import '../aml.scss'
import '../amlDetail.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAmlBrDetail } from '../../pages-hooks/useAml'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Fab,
  InputLabel,
  Paper,
  Typography,
} from '@mui/material'
import Spinner from '../../../components/common/spinner/spinner'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import { useTranslation } from 'react-i18next'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

const AmlBrDetail = () => {
  const { id } = useParams()
  const { accessToken } = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const amlBrDetail = useSelector((state) => state.amlBr.amlBrDetail)
  useAmlBrDetail(id, setIsLoading)
  const { t } = useTranslation(['common', 'messages'])

  const [expandedPep, setExpandedPep] = useState([])
  const [expandedSanctions, setExpandedSanctions] = useState([])

  useEffect(() => {
    if (amlBrDetail?.reportDetails?.PEPHistory?.length !== 0) {
      let aux = []
      amlBrDetail?.reportDetails?.PEPHistory?.forEach(() => aux.push(false))
      setExpandedPep(aux)
    }

    if (amlBrDetail?.reportDetails?.SanctionsHistory?.length !== 0) {
      let aux = []
      amlBrDetail?.reportDetails?.SanctionsHistory?.forEach(() => aux.push(false))
      setExpandedSanctions(aux)
    }
  }, [amlBrDetail])

  const handleChangePep = (position) => {
    let aux = [...expandedPep]
    aux[position] = !aux[position]
    setExpandedPep([...aux])
  }

  const setAllEqualPep = () => {
    let aux = []
    if (!expandedPep.includes(true)) {
      expandedPep.forEach(() => aux.push(true))
    } else {
      expandedPep.forEach(() => aux.push(false))
    }
    setExpandedPep([...aux])
  }

  const handleChangeSanctions = (position) => {
    let aux = [...expandedSanctions]
    aux[position] = !aux[position]
    setExpandedSanctions([...aux])
  }

  const setAllEqualSanctions = () => {
    let aux = []
    if (!expandedSanctions.includes(true)) {
      expandedSanctions.forEach(() => aux.push(true))
    } else {
      expandedSanctions.forEach(() => aux.push(false))
    }
    setExpandedSanctions([...aux])
  }

  return (
    <Box>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
      {isLoading && <Spinner noTransform />}
      {!isLoading && (
        <Box className="aml-manual-wrapper">
          <Paper elevation={0} className="aml-detail-wrapper">
            <Box className="aml-detail-id">
              <Typography variant="title">ID {t('common:detail')}</Typography>
            </Box>
            <Box className="aml-br-detail-grid">
              <Paper elevation={0} variant="colorPrimary">
                <Box className="aml-detail-id">
                  <Box className="aml-detail-box">
                    <Typography variant="subtitle1">ID</Typography>
                    <Typography variant="number">{id}</Typography>
                  </Box>

                  <Fab
                    size="small"
                    className="icon-global"
                    variant="action"
                    onClick={() => {
                      navigator.clipboard.writeText(id)
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </Fab>
                </Box>
              </Paper>
              <Paper elevation={0} variant="colorPrimary">
                <Box className="aml-detail-id">
                  <Box className="aml-detail-box">
                    <Typography variant="subtitle1">{t('messages:aml.IsCurrentlyPEP')}</Typography>
                    <Typography variant="number">
                      {amlBrDetail?.reportDetails?.IsCurrentlyPEP ? t('common:yes') : t('common:no')}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
              <Paper elevation={0} variant="colorPrimary">
                <Box className="aml-detail-id">
                  <Box className="aml-detail-box">
                    <Typography variant="subtitle1">{t('messages:aml.IsCurrentlySanctioned')}</Typography>
                    <Typography variant="number">
                      {amlBrDetail?.reportDetails?.IsCurrentlySanctioned ? t('common:yes') : t('common:no')}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
              <Paper elevation={0} variant="colorPrimary">
                <Box className="aml-detail-id">
                  <Box className="aml-detail-box">
                    <Typography variant="subtitle1">{t('messages:aml.WasPreviouslySanctioned')}</Typography>
                    <Typography variant="number">
                      {amlBrDetail?.reportDetails?.WasPreviouslySanctioned ? t('common:yes') : t('common:no')}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>

            {(!amlBrDetail || !amlBrDetail.documentNumber) && (
              <Box className="error-msg">
                <Button size="small" variant="outlined" color="error">
                  {t('messages:checkNotFound')}
                </Button>
              </Box>
            )}
          </Paper>

          <Paper elevation={0} className="aml-detail-wrapper">
            <Box className="aml-detail-id">
              <Typography variant="title"> {t('messages:aml.PEPHistory')}</Typography>

              {amlBrDetail && amlBrDetail?.reportDetails?.PEPHistory?.length !== 0 ? (
                <Button variant="outlined" onClick={() => setAllEqualPep()}>
                  {expandedPep.includes(true) ? t('common:hideAll') : t('common:expandAll')}
                </Button>
              ) : (
                <Typography variant="subtitle3">{t('messages:aml.noPEPHistoryToShow')}</Typography>
              )}
            </Box>
            {Array.isArray(amlBrDetail.reportDetails?.PEPHistory) &&
              amlBrDetail.reportDetails?.PEPHistory?.length !== 0 &&
              amlBrDetail.reportDetails.PEPHistory.map((item, index) => (
                <Accordion
                  variant="medium"
                  key={index}
                  expanded={expandedPep[index]}
                  onChange={() => handleChangePep(index)}
                >
                  <AccordionSummary expandIcon={null}>
                    <Box className="aml-detail-accordion-wrapper">
                      <Box className="aml-detail-input">
                        <InputLabel>{t('messages:aml.JobTitle')}:</InputLabel>
                        <Typography variant="subtitle3">{item.JobTitle}</Typography>
                      </Box>
                      <Box className="aml-detail-input">
                        <InputLabel>{t('messages:aml.Department')}:</InputLabel>
                        <Typography variant="subtitle3">{item.Department}</Typography>
                      </Box>
                    </Box>
                    <Button
                      variant="outlinedBlank"
                      onChange={(e) => {
                        e.stopPropagation()
                        return handleChangePep(index)
                      }}
                    >
                      {!expandedPep[index] ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper elevation={0} variant="colorSecondary" className="aml-br-detail-accordion-wrapper">
                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.Level')}</Typography>
                            <Typography variant="number">{item.Level}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.Motive')}</Typography>
                            <Typography variant="number">{item.Motive}</Typography>
                          </Box>
                        </Box>
                      </Paper>

                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.Source')}</Typography>
                            <Typography variant="number">{item.Source}</Typography>
                          </Box>
                        </Box>
                      </Paper>

                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.TaxId')}</Typography>
                            <Typography variant="number">{item.TaxId}</Typography>
                          </Box>
                        </Box>
                      </Paper>

                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.StartDate')}</Typography>
                            <Typography variant="number">{item.StartDate}</Typography>
                          </Box>
                        </Box>
                      </Paper>

                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.EndDate')}</Typography>
                            <Typography variant="number">{item.EndDate}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Paper>

          <Paper elevation={0} className="aml-detail-wrapper">
            <Box className="aml-detail-id">
              <Typography variant="title"> {t('messages:aml.SanctionsHistory')}</Typography>

              {amlBrDetail && amlBrDetail?.reportDetails?.SanctionsHistory?.length !== 0 ? (
                <Button variant="outlined" onClick={() => setAllEqualSanctions()}>
                  {expandedSanctions.includes(true) ? t('common:hideAll') : t('common:expandAll')}
                </Button>
              ) : (
                <Typography variant="subtitle3">{t('messages:aml.noSanctionsHistoryToShow')}</Typography>
              )}
            </Box>
            {Array.isArray(amlBrDetail.reportDetails?.SanctionsHistory) &&
              amlBrDetail.reportDetails?.SanctionsHistory?.length !== 0 &&
              amlBrDetail.reportDetails.SanctionsHistory.map((item, index) => (
                <Accordion
                  variant="medium"
                  key={index}
                  expanded={expandedSanctions[index]}
                  onChange={() => handleChangeSanctions(index)}
                >
                  <AccordionSummary expandIcon={null}>
                    <Box className="aml-detail-accordion-wrapper">
                      <Box className="aml-detail-input">
                        <InputLabel>{t('messages:aml.CreationDate')}:</InputLabel>
                        <Typography variant="subtitle3">{item.CreationDate}</Typography>
                      </Box>
                      <Box className="aml-detail-input">
                        <InputLabel>{t('messages:aml.Type')}:</InputLabel>
                        <Typography variant="subtitle3">{item.Type}</Typography>
                      </Box>
                      <Box className="aml-detail-input">
                        <InputLabel>{t('messages:aml.Source')}:</InputLabel>
                        <Typography variant="subtitle3">{item.Source}</Typography>
                      </Box>
                    </Box>
                    <Button
                      variant="outlinedBlank"
                      onChange={(e) => {
                        e.stopPropagation()
                        return handleChangePep(index)
                      }}
                    >
                      {!expandedPep[index] ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper elevation={0} variant="colorSecondary" className="aml-br-detail-accordion-wrapper">
                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.BirthDate')}</Typography>
                            <Typography variant="number">{item.Details?.StandardizedBirthDate}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.OriginalName')}</Typography>
                            <Typography variant="number">{item.Details?.OriginalName}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.LastUpdateDate')}</Typography>
                            <Typography variant="number">{item.LastUpdateDate}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.MatchRate')}</Typography>
                            <Typography variant="number">{item.MatchRate}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.StandardizedSanctionType')}</Typography>
                            <Typography variant="number">{item.StandardizedSanctionType}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.IsCurrentlyPresentOnSource')}</Typography>
                            <Typography variant="number">
                              {item.IsCurrentlyPresentOnSource ? t('common:yes') : t('common:no')}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.StartDate')}</Typography>
                            <Typography variant="number">{item.StartDate}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                      <Paper elevation={0} variant="colorPrimary">
                        <Box className="aml-detail-id">
                          <Box className="aml-detail-box">
                            <Typography variant="subtitle1">{t('messages:aml.EndDate')}</Typography>
                            <Typography variant="number">{item.EndDate}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Paper>
        </Box>
      )}
    </Box>
  )
}

export default AmlBrDetail
