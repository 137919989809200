import { GridColumnMenu } from '@mui/x-data-grid-pro'

const CustomColumnMenu = (props) => (
  <GridColumnMenu
    {...props}
    slots={{
      columnMenuColumnsItem: null,
      columnMenuFilterItem: null,
      columnMenuSortItem: null,
    }}
  />
)

export default CustomColumnMenu
