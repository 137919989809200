import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Chip, CircularProgress, MenuItem, Paper, Select, Tab, Typography } from '@mui/material'
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import CameraFrontOutlinedIcon from '@mui/icons-material/CameraFrontOutlined'
import { useTranslation } from 'react-i18next'
import { buildTimeStringFromTimestamp } from '../../../../../components/common/time/timeHelper'
import { useGetKycCheckQuery, useUpdateKycCheckMutation } from '../../../../../features/kyc/kycSlice'
import GridItem from '../GridItem'

import styles from './styles.module.scss'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CheckAnalytics from '../CheckAnalytics'
import CheckSummary from '../CheckSummary'
import CheckLegalDocuments from '../CheckLegalDocuments'
import CheckSecurityPatterns from '../CheckSecurityPatterns'
import { TableChip } from '../../../../../components/utilities/TableChip'
import toaster from '../../../../../toaster'
import EditFieldModal from '../EditFieldModal'

const kycCheckStatus = ['APPROVED', 'REJECTED', 'FLAGGED', 'PENDING']

export const CheckDetails = ({ checks, checkType, selectedCheckId, setSelectedCheck }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [selectedTab, setSelectedTab] = useState('analytics')
  const [modalEditField, setModalEditField] = useState(false)
  const [selectedField, setSelectedField] = useState({ label: '', value: '' })

  const [updateKycPerson] = useUpdateKycCheckMutation({ fixedCacheKey: 'kyc-check-update' })

  const handleUpdateKycCheck = async (data) => {
    try {
      await updateKycPerson({
        id: selectedCheckId,
        body: data,
      }).unwrap()
      toaster.success(t('messages:updateSuccess'))
      setModalEditField(false)
    } catch (error) {
      console.error('[UPDATE KYC CHECK ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:updateError'))
    }
  }

  const handleOpenModalEditField = useCallback(
    (label, value) => {
      setSelectedField({ label, value: value ?? '' })
      setModalEditField(true)
    },
    [setModalEditField],
  )

  const {
    data: dataCheck,
    isFetching: fetchingCheck,
    isLoading: loadingCheck,
  } = useGetKycCheckQuery({ id: selectedCheckId }, { skip: !selectedCheckId })

  const check = useMemo(() => {
    if (checks?.length === 0) return null
    return dataCheck?.data
  }, [dataCheck?.data, checks])

  const checkDetails = useMemo(() => {
    return check?.details
  }, [check])

  const checkImages = useMemo(() => {
    return check?.images
  }, [check])

  useEffect(() => {
    setSelectedTab('analytics')
  }, [checkType])

  return (
    <Paper
      elevation={0}
      className={`${styles.checkDetailsContainer} ${(fetchingCheck || loadingCheck) && 'spinner-transition'}`}
    >
      {(fetchingCheck || loadingCheck) && (
        <Box className={styles.loadingSpinnerContainer}>
          <CircularProgress />
        </Box>
      )}
      <Box className={styles.gridContainer}>
        <GridItem
          name={t('messages:kyc.check.status')}
          value={check?.status ? t(`messages:kyc.check.status.${check?.status}`) : '---'}
          editable={check}
          onEditClick={() => handleOpenModalEditField('status', check?.status)}
        />
        <GridItem
          name={t('messages:kyc.check.outcome')}
          value={check?.outcome ? t(`messages:kyc.check.outcome.${check.outcome}`) : '---'}
        />
        <GridItem
          name={t('messages:kyc.check.externalId')}
          value={check?.externalId ?? '---'}
          editable={check}
          onEditClick={() => handleOpenModalEditField('externalId', check?.externalId)}
        />
        {!!checks && (
          <Select
            value={selectedCheckId || ''}
            onChange={(e) => setSelectedCheck(e.target.value)}
            classes={{ select: styles.MuiOutlinedInputInput }}
          >
            {checks?.length > 0 ? (
              checks.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    {buildTimeStringFromTimestamp(item.createdAt)}
                    <Box width="100px">
                      <TableChip action={item.status} noIcon={true} />
                    </Box>
                  </Box>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value="">
                {t('messages:kyc.noChecks')}
              </MenuItem>
            )}
          </Select>
        )}
      </Box>
      {check && (
        <TabContext value={selectedTab}>
          <Box className={styles.sectionsContainer}>
            <Paper
              elevation={0}
              variant="rootTransparent"
              className={styles.sectionsSelector}
              style={{ height: 'fit-content' }}
            >
              <Typography variant="title">{t('common:sections')}</Typography>
              <TabList
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
                classes={{ flexContainer: styles.flexContainer }}
                onChange={(e, newValue) => setSelectedTab(newValue)}
              >
                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'analytics' ? <InsightsOutlinedIcon /> : <></>}
                      label={t('common:analytics')}
                      variant={selectedTab === 'analytics' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="analytics"
                  disabled={fetchingCheck || loadingCheck}
                />
                {checkType === 'document_check' && (
                  <Tab
                    label={
                      <Chip
                        icon={selectedTab === 'summaryInfo' ? <SummarizeOutlinedIcon /> : <></>}
                        label={t('common:summaryInfo')}
                        variant={selectedTab === 'summaryInfo' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                      />
                    }
                    value="summaryInfo"
                    disabled={fetchingCheck || loadingCheck}
                  />
                )}
                {checkType === 'document_check' && (
                  <Tab
                    label={
                      <Chip
                        icon={selectedTab === 'legalDocuments' ? <BalanceOutlinedIcon /> : <></>}
                        label={t('common:legalDocuments')}
                        variant={selectedTab === 'legalDocuments' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                      />
                    }
                    value="legalDocuments"
                    disabled={fetchingCheck || loadingCheck}
                  />
                )}
                {checkType === 'document_check' && (
                  <Tab
                    label={
                      <Chip
                        icon={selectedTab === 'securityAndPatterns' ? <GppGoodOutlinedIcon /> : <></>}
                        label={t('common:securityAndPatterns')}
                        variant={selectedTab === 'securityAndPatterns' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                      />
                    }
                    value="securityAndPatterns"
                    disabled={fetchingCheck || loadingCheck}
                  />
                )}
                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'receivedImages' ? <CameraFrontOutlinedIcon /> : <></>}
                      label={t('common:receivedImages')}
                      variant={selectedTab === 'receivedImages' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="receivedImages"
                  disabled={fetchingCheck || loadingCheck}
                />
              </TabList>
            </Paper>
            <Paper elevation={0} className={styles.sectionInfoContainer}>
              <TabPanel value="analytics" className={styles.sectionInfo}>
                <Typography variant="title">{t('common:analytics')}</Typography>
                <CheckAnalytics data={checkDetails} />
              </TabPanel>

              {checkType === 'document_check' && (
                <>
                  <TabPanel value="summaryInfo" className={styles.sectionInfo}>
                    <Typography variant="title">{t('common:summaryInfo')}</Typography>
                    <CheckSummary data={checkDetails} />
                  </TabPanel>
                  <TabPanel value="legalDocuments" className={styles.sectionInfo}>
                    <Typography variant="title">{t('common:legalDocuments')}</Typography>
                    <CheckLegalDocuments data={checkDetails} />
                  </TabPanel>
                  <TabPanel value="securityAndPatterns" className={styles.sectionInfo}>
                    <Typography variant="title">{t('common:securityAndPatterns')}</Typography>
                    <CheckSecurityPatterns data={checkDetails} />
                  </TabPanel>
                </>
              )}

              <TabPanel value="receivedImages" className={styles.sectionInfo}>
                <Typography variant="title">{t('common:receivedImages')}</Typography>
                {checkImages?.map((image) => (
                  <Box key={image._id} className="img-pair">
                    <Typography variant="title3">{t(`messages:kyc.check.images.${image.type}`)}</Typography>
                    <img className="received-imgs" src={image.file} alt="Base64" />
                  </Box>
                ))}
              </TabPanel>
            </Paper>
          </Box>
        </TabContext>
      )}
      <EditFieldModal
        modalOpen={modalEditField}
        setModalOpen={setModalEditField}
        field={selectedField.label}
        value={selectedField.value}
        options={selectedField.label === 'status' ? kycCheckStatus : null}
        onSubmit={handleUpdateKycCheck}
      />
    </Paper>
  )
}
