import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckCepim = ({ title = '', cepimCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>{title || 'CEPIM'}</Typography>
      <CheckDetailTitle
        email={cepimCheck?.email}
        status={cepimCheck?.status}
        createdAt={cepimCheck?.createdAt}
        updatedAt={cepimCheck?.updatedAt}
      />
      {!Array.isArray(cepimCheck?.checkDetails) && cepimCheck?.checkDetails?.informação && (
        <Box>
          <Box>
            <span>{t('common:information')}</span>
            <p>{cepimCheck?.informação ?? '---'}</p>
          </Box>
        </Box>
      )}
      {Array.isArray(cepimCheck?.checkDetails) && cepimCheck?.checkDetails?.length && (
        <Box>
          <h4>{t('common:sanctions')}</h4>
          <Box className="group-table">
            <table className="bs bst group-table">
              <thead>
                <tr className="group-table">
                  <th className="bs">{t('common:date')}</th>
                  <th className="bs">{t('common:reason')}</th>
                  <th className="bs">{t('common:higherBody')}</th>
                  <th className="bs">{t('common:legalPerson')}</th>
                </tr>
              </thead>
              <tbody>
                {cepimCheck?.checkDetails?.map((element, index) => {
                  return (
                    <tr className="group-table" key={index}>
                      <td className="bs table-cell col-4">{element.dataReferencia ?? '---'}</td>
                      <td className="bs table-cell col-4">{element.motivo ?? '---'}</td>
                      <td className="bs table-cell col-4">{element.orgaoSuperior?.nome ?? '---'}</td>
                      <td className="bs table-cell col-4">{element.pessoaJuridica?.nome ?? '---'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CheckCepim
