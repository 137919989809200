import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useUpdateKybMutation } from '../../../services/guenoApi'
import AutocompleteInputForm from '../../../components/common/form/AutocompleteInputForm/AutocompleteInputForm'

const schema = yup.object().shape({
  stringData: yup.array().optional(),
})

const KybStringFieldModal = ({ options, field, initialValue, onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [_, { isLoading }] = useUpdateKybMutation({ fixedCacheKey: 'kyb-update' })

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      stringData: initialValue,
    },
  })

  const handleFormSubmit = (formData) => {
    onSubmit(field, formData.stringData.join(','))
  }

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Box className="modal-box">
        <AutocompleteInputForm
          name="stringData"
          control={control}
          label={t(`common:${field}`)}
          placeholder={t(`common:${field}`)}
          options={options}
          multiple
        />
      </Box>
      <Box className="modal-button">
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          type="submit"
          disabled={!isDirty || !isValid}
          loading={isLoading}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default KybStringFieldModal
