import './listOfBlacklistIds.scss'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const ListOfBlacklistIds = ({ propertie, ruleParameters, setRuleParameters, isEditing, type }) => {
  const { accessToken } = useSelector((state) => state.login)
  const [selectedValue, setSelectedValue] = useState('')
  const [list, setList] = useState([])
  const [context, setContext] = useState('')
  const { t } = useTranslation(['common'])

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASEURL}/admin/lists?type=${type}`

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/black_list.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data } = res
          setList(data)
        }
      })
      .catch((error) => {
        console.error('[HOOK: useLists] --> ', error)
      })
  }, [])

  useEffect(() => {
    const aux = ruleParameters[propertie]
    if (aux) {
      setSelectedValue(aux)
    } else {
      setSelectedValue('')
    }
    if (propertie === 'blacklist' || propertie === 'whitelistIdsExclusion') setContext('userId')
    else if (propertie === 'blacklistIbans') setContext('iban')
    else if (propertie === 'blacklistBankAccountNumbers') setContext('bankAccount')
    else if (propertie === 'blacklistCardFingerprints') setContext('cardFingerprint')
    else if (propertie === 'blacklistAchAccountNumbers') setContext('achAccount')
    else if (propertie === 'blacklistSwiftAccountNumbers') setContext('swiftAccount')
    else if (propertie === 'blacklistWalletIds') setContext('walletId')
    else if (propertie === 'blacklistCheckNumbers') setContext('check')
    else if (propertie === 'blacklistCBUNumbers') setContext('cbu')
    else if (propertie === 'blacklistCVUNumbers') setContext('cvu')
    else if (propertie === 'blacklistECheckNumbers') setContext('echeck')
    else if (propertie === 'blacklistDebinAuthCodes') setContext('debin')
    else if (propertie === 'blacklistQRCodes') setContext('qr_code')
    else if (propertie === 'blacklistOnlinePaymentAccountNumbers') setContext('online_payment')
    else if (propertie === 'blacklistTedAccountNumbers') setContext('ted')
    else if (propertie === 'blacklistBoletoNumbers') setContext('boleto')
    else if (propertie === 'blacklistPixKeyValues') setContext('pix')
  }, [propertie])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    setSelectedValue(value)
    updateRuleParameters(value)
  }

  const updateRuleParameters = (value) => {
    const updatedParameters = { ...ruleParameters, [propertie]: value }
    setRuleParameters(updatedParameters)
  }

  return (
    <Box>
      <Select
        fullWidth
        size="small"
        displayEmpty
        disabled={!isEditing}
        value={selectedValue || ''}
        onChange={handleChange}
      >
        <MenuItem disabled value="">
          <Typography variant="subtitle3">{`${t('common:select')} ${t('common:list')}`}</Typography>
        </MenuItem>
        {Array.isArray(list) &&
          list
            ?.filter((item) => item.context === context)
            .map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {`${item.title} - (ID: ${item.id})`}
              </MenuItem>
            ))}
      </Select>
    </Box>
  )
}

export default ListOfBlacklistIds
