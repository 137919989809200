import { Box, Button, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FileUploader } from 'react-drag-drop-files'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import styles from './styles.module.scss'

export const FileUploaderBox = ({ title, file, setFile, fileTypes }) => {
  const { t } = useTranslation(['common', 'messages'])

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    setFile('')
  }

  return (
    <Box className="onb-files-drag">
      <Typography variant="title2">{title}</Typography>
      <FileUploader handleChange={setFile} name="file" label={t('common:fileUploaderLabel')} types={fileTypes}>
        <Paper elevation={0} variant="rootDashed" className={styles.draggableMain}>
          {!file && (
            <>
              <Box className={styles.draggableIcon}></Box>
              <Typography variant="title2">{t('common:fileUploaderLabel')}</Typography>
              <Box>
                <Typography variant="subtitle4">{t('common:or')} </Typography>
                <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                  {t('common:browseMedia')}
                </Typography>
              </Box>
            </>
          )}
          {file && (
            <Box className={styles.draggableContainer}>
              <Box className="color-button" sx={{ background: '#E4DDFF' }}>
                <Typography variant="subtitle1" sx={{ color: '#7E65D5' }}>
                  {file.type.split('/')[1].toUpperCase()}
                </Typography>
              </Box>

              <Typography variant="h6" title={file.name}>
                {file.name}
              </Typography>
              <Box className="onb-file-icons">
                <Button variant="outlinedBlank" onClick={handleDeleteClick}>
                  <DeleteOutlineIcon />
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      </FileUploader>
      <Typography variant="subtitle4">{`${t('common:supportedMedia')}: ${fileTypes
        .map((type) => type.toUpperCase())
        .join(', ')}.`}</Typography>
    </Box>
  )
}
