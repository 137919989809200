import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import { useMemo } from 'react'

const CheckSecurityPatterns = ({ data }) => {
  const { t } = useTranslation(['common', 'messages'])

  const checkSecurityPatterns = useMemo(() => {
    if (data?.result?.breakdown) return data.result.breakdown.securityAndPatternAnalysis
    return null
  }, [data])

  if (!checkSecurityPatterns) return null

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('common:expectedPattern')}</TableCell>
            <TableCell>{t('common:actualPattern')}</TableCell>
            <TableCell>{t('common:similarity')}</TableCell>
            <TableCell>{t('common:outcome')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {checkSecurityPatterns.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                {item.expectedImageData ? (
                  <img
                    className="pattern-image"
                    src={'data:image/jpeg;base64,' + item.expectedImageData}
                    alt="Base64"
                  />
                ) : (
                  '---'
                )}
              </TableCell>
              <TableCell>
                {item.actualImageData ? (
                  <img className="pattern-image" src={'data:image/jpeg;base64,' + item.actualImageData} alt="Base64" />
                ) : (
                  '---'
                )}
              </TableCell>
              <TableCell>{item.similarity || item.similarity === 0 ? item.similarity + '%' : '---'}</TableCell>
              <TableCell>
                {item.narrative && item.outcome ? (
                  <Box className="check-render">
                    {(!item.outcome || item.outcome === 'not_processed') && (
                      <RemoveCircleRoundedIcon fontSize="small" className="grey" />
                    )}
                    {item.outcome === 'clear' && <CheckCircleRoundedIcon fontSize="small" color="secondary" />}
                    {item.outcome && item.outcome !== 'clear' && item.outcome !== 'not_processed' && (
                      <CancelRoundedIcon fontSize="small" className="red" />
                    )}
                    <p>{item.narrative}</p>
                  </Box>
                ) : (
                  '---'
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CheckSecurityPatterns
