import { createSlice } from '@reduxjs/toolkit'

export const batchSlice = createSlice({
  name: 'batch',
  initialState: {
    batches: {},
    selectedBatch: null,
    temporalItems: {},
    filters: {
      page: 0,
      limit: 10,
      offset: 0,
      type: '',
      status: '',
    },
    temporalFilters: {
      page: 0,
      limit: 50,
      offset: 0,
      status: '',
    },
  },
  reducers: {
    updateTemporalItems: (state, action) => {
      state.temporalItems = action.payload
    },
    updateFilters: (state, action) => {
      state.filters = action.payload
    },
    updateTemporalFilters: (state, action) => {
      state.temporalFilters = action.payload
    },
  },
})

export const { updateTemporalItems, updateFilters, updateTemporalFilters } = batchSlice.actions
export default batchSlice.reducer
