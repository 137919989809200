import { Box, Button, IconButton, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useFieldArray } from 'react-hook-form'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import SelectInputForm from '../../../components/common/form/SelectInputForm/SelectInputForm'
import TextInputForm from '../../../components/common/form/TextInputForm/TextInputForm'
import CheckInputForm from '../../../components/common/form/CheckInputForm/CheckInputForm'

const KybManualShareholders = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shareholders',
  })

  const handleAddShareholder = () => {
    append({
      documentType: 'cuit',
      documentNumber: '',
      name: '',
      percentage: 0,
      isPep: false,
      isReportingEntity: false,
    })
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h4">Accionistas</Typography>
        <Button onClick={handleAddShareholder}>Agregar +</Button>
      </Box>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid xs={2}>
          <Box>
            <Typography variant="subtitle2">Tipo de documento</Typography>
          </Box>
        </Grid>
        <Grid xs={2}>
          <Box>
            <Typography variant="subtitle2">Número de documento (*)</Typography>
          </Box>
        </Grid>
        <Grid xs={3}>
          <Box>
            <Typography variant="subtitle2">Nombre o razón social del accionista</Typography>
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box>
            <Typography variant="subtitle2">Porcentaje (%)</Typography>
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box>
            <Typography variant="subtitle2">Es Pep?</Typography>
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box>
            <Typography variant="subtitle2">Es Sujeto Obligado?</Typography>
          </Box>
        </Grid>
        <Grid xs={2} />
      </Grid>

      {fields.map((field, index) => (
        <Grid key={field.id} container columnSpacing={2} rowSpacing={2}>
          <Grid xs={2}>
            <SelectInputForm
              name={`shareholders.${index}.documentType`}
              control={control}
              placeholder="Elija el tipo de documento"
              options={[
                { value: 'CUIT', label: 'CUIT' },
                { value: 'CUIL', label: 'CUIL' },
              ]}
              required
            />
          </Grid>
          <Grid xs={2}>
            <TextInputForm
              name={`shareholders.${index}.documentNumber`}
              control={control}
              placeholder="Ingrese el número de documento"
              type="number"
            />
          </Grid>
          <Grid xs={3}>
            <TextInputForm
              name={`shareholders.${index}.name`}
              control={control}
              placeholder="Ingrese nombre o la razón social del accionista"
            />
          </Grid>
          <Grid xs={1}>
            <TextInputForm
              name={`shareholders.${index}.percentage`}
              control={control}
              type="number"
              placeholder="Ingrese el % de participación"
            />
          </Grid>
          <Grid xs={1}>
            <CheckInputForm
              name={`shareholders.${index}.isPep`}
              control={control}
              type="number"
              placeholder="Es Pep?"
            />
          </Grid>
          <Grid xs={1}>
            <CheckInputForm
              name={`shareholders.${index}.isReportingEntity`}
              control={control}
              type="number"
              placeholder="Es sujeto obligado"
            />
          </Grid>
          <Grid xs={2}>
            <IconButton aria-label="delete" onClick={() => remove(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default KybManualShareholders
