import { useSelector } from 'react-redux'
import { useFindUserMasterQuery } from './services/guenoApi'
import { Box, CircularProgress } from '@mui/material'
import App from './App'

const AppWithInitialData = () => {
  const { emailClient } = useSelector((state) => state.login)
  const { theme } = useSelector((state) => state.theme)

  const { data, isLoading } = useFindUserMasterQuery({ emailClient })

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return <App theme={data?.isNuclea ? 'nuclea' : theme} />
}

export default AppWithInitialData
