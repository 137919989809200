import './navbar.scss'
import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Fab,
  InputAdornment,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { setLocale } from 'yup'
import { useNavigate } from 'react-router-dom'
import { Flags } from '../flag/flags'
import { useDispatch, useSelector } from 'react-redux'
import { updateLang } from '../../../features/language/languageSlice'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LetterAvatar from '../userDetails/LetterAvatar'

const Navbar = ({ handleUserLogout }) => {
  const languageState = useSelector((state) => state.language.lang)
  const { email, accessToken, roles } = useSelector((state) => state.login)
  const { theme } = useSelector((state) => state.theme)
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])
  const navigate = useNavigate()
  const [globalSearch, setGlobalSearch] = useState('')
  const [openMenu, setOpenMenu] = useState(null)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [openReset, setOpenReset] = useState(false)
  const [error, setError] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const regex = /(^[A-Z]{4,20}(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
  const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleReset = (e) => {
    e.preventDefault()
    if (!process.env.REACT_APP_IS_DEMO) {
      const data = {
        email: email,
        password: password,
        confirmNewPassword: confirmPassword,
      }

      const urlUser = `${process.env.REACT_APP_BASEURL}/user/resetPassword`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleCloseReset()
          handleFetch(false, res.message)
        })
        .catch((error) => {
          console.error('[RESET USER PASSWORD ERROR] --> ', error)
          setError(true)
          setErrorMessage(error.message ? error.message : `${t('common:user')} ${t('messages:resetPasswordError')}`)
          handleFetch(true, error.message ? error.message : `${t('common:user')} ${t('messages:resetPasswordError')}`)
        })
    } else {
      handleCloseReset()
      handleFetch(false, 'Password reset success')
    }
  }

  const logoutV001 = () => {
    handleUserLogout()
    navigate('/')
  }

  const handleCloseReset = () => {
    setOpenReset(false)
    setErrorMessage('')
    setError(false)
  }

  const changeLanguageHandler = (lang) => {
    dispatch(updateLang(lang))
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    if (password !== '' || confirmPassword !== '' || email !== '') {
      if (!regexEmail.test(email) && openReset) {
        setErrorMessage('Invalid email value')
        setError(true)
      } else if (!regexEmail.test(email) && openReset) {
        setErrorMessage('Invalid email value')
        setError(true)
      } else if (!regex.test(password) && password !== confirmPassword) {
        setErrorMessage(`${t('messages:passwordTooWeak')}. ${t('messages:passwordDontMatch')}`)
        setError(true)
      } else if (!regex.test(password)) {
        setErrorMessage(t('messages:passwordTooWeak'))
        setError(true)
      } else if (password !== confirmPassword) {
        setErrorMessage(t('messages:passwordDontMatch'))
        setError(true)
      } else {
        setError(false)
        setErrorMessage('')
      }
    }
  }, [password, confirmPassword, email])

  useEffect(() => {
    if (languageState) {
      setLocale({
        mixed: {
          required: () => t('messages:validations.mixed.required'),
          notType: ({ type }) => t(`messages:validations.mixed.notType.${type}`),
        },
        string: {
          email: t('messages:validations.string.email'),
          url: t('messages:validations.string.url'),
        },
        number: {
          min: ({ min }) => t('messages:validations.number.min', { min }),
          max: ({ max }) => t('messages:validations.number.max', { max }),
        },
        array: {
          min: ({ min }) => t('messages:validations.array.min', { min }),
        },
      })
    }
  }, [languageState, t])

  return (
    <Box className="navbar-wrapper" sx={{ backgroundColor: theme === 'light' ? '#f4f8f6' : '#171B18' }}>
      <Button
        size="small"
        className="icon-global back-button"
        variant="link"
        onClick={() => navigate(-1)}
        startIcon={<ArrowBackIosIcon />}
      >
        {t('common:back')}
      </Button>
      <Box className="navbar-right">
        {process.env.REACT_APP_IS_DEMO && (
          <TextField
            placeholder={t('common:globalSearch')}
            required
            value={globalSearch}
            onChange={(event) => setGlobalSearch(event.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        )}

        <Box className="avatar">
          <Select
            value={languageState}
            displayEmpty
            size="small"
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={(e) => changeLanguageHandler(e.target.value)}
          >
            <MenuItem value="pt-BR">
              <Box className="lang-button">
                {Flags('br', true)} <p>PT</p>
              </Box>
            </MenuItem>
            <MenuItem value="en">
              <Box className="lang-button">
                {Flags('us', true)} <p>EN</p>
              </Box>
            </MenuItem>
            <MenuItem value="es-ES">
              <Box className="lang-button">
                {Flags('es', true)} <p>ES</p>
              </Box>
            </MenuItem>
          </Select>
        </Box>
        <Link className="avatar" to="/profile" style={{ textDecoration: 'none' }}>
          <Box onClick={(e) => setOpenMenu(e.currentTarget)}>
            <LetterAvatar width="2.25rem" height="2.25rem" fontSize="1rem" fullName={email.toUpperCase()} />
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={openMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(openMenu)}
            onClose={() => setOpenMenu(null)}
          >
            <MenuItem key={0} onClick={() => setOpenMenu(null)}>
              <Box className="navbar-item">
                <LetterAvatar width="2rem" height="2rem" fontSize="1rem" fullName={email.toUpperCase()} />
                <Box className="navbar-subitem">
                  <Typography variant="text2">{email}</Typography>
                </Box>
              </Box>
            </MenuItem>
            <MenuItem key={1} onClick={() => setOpenMenu(null)}>
              <Paper elevation={0} variant="colorPrimary" className="navbar-subitem">
                <Typography variant="subtitle4">Roles</Typography>
                <Typography variant="title2">
                  {roles && roles.length > 0
                    ? roles.map((r, index) => {
                        return (
                          <Box key={index} className="navbar-roles">
                            {r.replaceAll('_', ' ').toUpperCase()}
                          </Box>
                        )
                      })
                    : '---'}
                </Typography>
              </Paper>
            </MenuItem>
            <MenuItem key={3} onClick={() => setOpenMenu(null)}>
              <Button fullWidth variant="outlinedGrey" onClick={() => setOpenReset(true)}>
                {t('messages:changePassword')}
              </Button>
            </MenuItem>
            <MenuItem key={4} onClick={() => setOpenMenu(null)}>
              <Button fullWidth variant="outlined" onClick={logoutV001} endIcon={<ExitToAppIcon className="icon" />}>
                {t('common:logout')}
              </Button>
            </MenuItem>
          </Menu>
        </Link>
      </Box>

      <Modal open={openReset} onClose={handleCloseReset}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('messages:changePassword')}</Typography>
            </Box>
            <Fab variant="close" onClick={handleCloseReset} aria-label="sidebar-collapse">
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters">
            <Box className="modal-box">
              <InputLabel>{t('common:email')}</InputLabel>
              <Typography variant="title3">{email}</Typography>
            </Box>

            <Box className="modal-box">
              <InputLabel htmlFor="password">{t('messages:newPassword')}</InputLabel>
              <TextField
                size="small"
                type="password"
                helperText={errorMessage.length > 0 ? errorMessage : null}
                error={Boolean(errorMessage)}
                placeholder={`${t('common:type')} ${t('common:here').toLowerCase()}...`}
                required
                id="password"
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              ></TextField>
            </Box>

            <Box className="modal-box">
              <InputLabel htmlFor="confirmPassword">{t('messages:confirmNewPassword')}</InputLabel>
              <TextField
                size="small"
                type="password"
                helperText={errorMessage.length > 0 ? errorMessage : null}
                error={Boolean(errorMessage)}
                placeholder={`${t('common:type')} ${t('common:here').toLowerCase()}...`}
                required
                id="confirmPassword"
                onChange={(e) => {
                  setConfirmPassword(e.target.value)
                }}
              ></TextField>
            </Box>
          </Box>
          <Box className="modal-filter-buttons">
            <Button
              disabled={error}
              size="small"
              variant="contained"
              type="submit"
              onClick={(e) => {
                return handleReset(e)
              }}
            >
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Navbar
