import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckLeniencyAgreements = ({ title = '', leniencyAgreementsCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>
        {title || t('common:leniencyAgreements')}
      </Typography>
      <CheckDetailTitle
        email={leniencyAgreementsCheck?.email}
        status={leniencyAgreementsCheck?.status}
        createdAt={leniencyAgreementsCheck?.createdAt}
        updatedAt={leniencyAgreementsCheck?.updatedAt}
      />
      {!Array.isArray(leniencyAgreementsCheck) && Object.keys(leniencyAgreementsCheck).length && (
        <Box>
          <Box>
            <span>{t('common:information')}</span>
            <p>{leniencyAgreementsCheck.checkDetails?.informação ?? '---'}</p>
          </Box>
        </Box>
      )}
      {Array.isArray(leniencyAgreementsCheck?.checkDetails) && leniencyAgreementsCheck?.checkDetails?.length && (
        <Box>
          <h4>{t('common:sanctions')}</h4>
          <Box className="group-table">
            <table className="bs bst group-table">
              <thead>
                <tr className="group-table">
                  <th className="bs">{t('common:initialDate')}</th>
                  <th className="bs">{t('common:endDate')}</th>
                  <th className="bs">{t('common:responsibleBody')}</th>
                  <th className="bs">{t('common:agreementSituation')}</th>
                  <th className="bs">{t('common:amount')}</th>
                </tr>
              </thead>
              <tbody>
                {leniencyAgreementsCheck?.checkDetails?.map((element, index) => {
                  return (
                    <tr className="group-table" key={index}>
                      <td className="bs table-cell col-5">{element.dataInicioAcordo ?? '---'}</td>
                      <td className="bs table-cell col-5">{element.dataFimAcordo ?? '---'}</td>
                      <td className="bs table-cell col-5">{element.orgaoResponsavel ?? '---'}</td>
                      <td className="bs table-cell col-5">{element.situacaoAcordo ?? '---'}</td>
                      <td className="bs table-cell col-5">{element.quantidade ?? '---'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CheckLeniencyAgreements
