import { Box, Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import TextInputForm from '../../../components/common/form/TextInputForm/TextInputForm'
import SelectInputForm from '../../../components/common/form/SelectInputForm/SelectInputForm'
import { useCreateManualKybMutation } from '../../../services/guenoApi'
import styles from './KybManualForm.module.scss'
import KybManualShareholders from './KybManualShareholders'
import KybManualFiles from './KybManualFiles'

const KybManualForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])
  const { currentCountry, countrySegment } = useSelector((state) => state.kybCountry)

  const [createManualKyb, { isLoading, isFetching }] = useCreateManualKybMutation()
  const schema = yup.object().shape({
    documentType: yup.string().required(),
    documentNumber: yup
      .string()
      .required()
      .min(8, 'El CUIT debe contener al menos 8 dígitos')
      .max(11, 'El CUIT debe contener hasta 11 dígitos'),
    email: yup.string().email().required(),
    name: yup.string().required(),
    phone: yup.string().optional(),
    activity: yup.string().optional(),
    type: yup.string().optional(),
    dateOfRegistration: yup.string().optional(),
    legalConstitutionCountry: yup.string().optional(),
    postalCode: yup.string().optional(),
    street: yup.string().optional(),
    streetNumber: yup.string().optional(),
    city: yup.string().optional(),
    state: yup.string().optional(),
    legalRepresentative: yup.string().optional(),
    legalRepresentativeDocumentNumber: yup
      .string()
      .required()
      .min(8, 'El CUIT debe contener al menos 8 dígitos')
      .max(11, 'El CUIT debe contener hasta 11 dígitos'),
    legalRepresentativeEmail: yup.string().email().optional(),
    legalRepresentativePhone: yup.string().optional(),
    shareholders: yup.array().of(
      yup.object().shape({
        documentType: yup.string().required(),
        documentNumber: yup
          .string()
          .required()
          .min(8, 'El CUIT/CUIL debe contener al menos 8 dígitos')
          .max(11, 'El CUIT/CUIL debe contener hasta 11 dígitos'),
        name: yup.string().optional(),
        percentage: yup.string().optional(),
        isPep: yup.boolean(),
        isReportingEntity: yup.boolean(),
      }),
    ),
    files: yup.array(),
  })
  const { handleSubmit, reset, control, formState } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      documentType: 'CUIT',
      documentNumber: '',
      dateOfRegistration: '',
      legalConstitutionCountry: '',
      email: '',
      name: '',
      phone: '',
      activity: '',
      type: '',
      postalCode: '',
      street: '',
      streetNumber: '',
      city: '',
      state: '',
      legalRepresentative: '',
      legalRepresentativeDocumentNumber: '',
      legalRepresentativeEmail: '',
      legalRepresentativePhone: '',
      shareholders: [],
      files: [],
      comment: '',
    },
  })

  const onSubmit = async (values) => {
    const address = {
      street: values.street,
      postcode: values.postalCode,
      number: values.streetNumber,
      city: values.city,
      state: values.state,
      country: currentCountry,
    }

    const { data } = await createManualKyb({
      countrySegment,
      body: {
        companyDetails: {
          name: values.name,
          documentNumber: values.documentNumber,
          phones: [values.phone],
          emails: [values.email],
          dateOfRegistration: values.dateOfRegistration,
          country: currentCountry,
          addresses: [address],
          type: values.type,
          legalConstitutionCountry: values.legalConstitutionCountry,
          primaryCategory: {
            code: '',
            description: values.activity,
          },
        },
        legalRepresentative: values.legalRepresentative,
        legalRepresentativeDocumentNumber: values.legalRepresentativeDocumentNumber,
        legalRepresentativeEmail: values.legalRepresentativeEmail,
        legalRepresentativePhone: values.legalRepresentativePhone,
        shareholders: values.shareholders.map((sh) => ({ ...sh, percentage: Number(sh.percentage) })),
        comments: values.comment ? [values.comment] : [],
      },
    })

    if (data.success) {
      navigate(`/kyb/${currentCountry}/detail/${data.data?._id}`)
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Box className={styles.container} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid xs={12}>
            <Box className={styles.footer}>
              <LoadingButton size="small" variant="contained" type="submit" loading={isLoading || isFetching}>
                <span>{t('common:create')}</span>
              </LoadingButton>
              <Button
                onClick={() => reset()}
                variant={'outlined'}
                disabled={!formState.isDirty || isLoading || isFetching}
              >
                Limpiar
              </Button>
            </Box>
          </Grid>
          <Grid xs={2}>
            <SelectInputForm
              name="documentType"
              control={control}
              label="Tipo de documento"
              placeholder="Elija el tipo de documento"
              options={[{ value: 'CUIT', label: 'CUIT' }]}
              disabled
              required
            />
          </Grid>
          <Grid xs={3}>
            <TextInputForm
              name="documentNumber"
              control={control}
              label="Número de documento"
              tooltip="Número de registro tributario de la empresa"
              placeholder="Ingrese el número de documento de la empresa"
              type="number"
              required
            />
          </Grid>
          <Grid xs={4}>
            <TextInputForm
              name="name"
              control={control}
              label="Razón social de la empresa"
              placeholder="Ingrese el email"
              required
            />
          </Grid>
          <Grid xs={3}>
            <TextInputForm
              name="email"
              control={control}
              label="Email asociado a la empresa"
              placeholder="Ingrese el email"
              required
            />
          </Grid>
          <Grid xs={2}>
            <TextInputForm
              name="dateOfRegistration"
              control={control}
              label="Fecha de alta"
              placeholder="Fecha de alta"
            />
          </Grid>
          <Grid xs={3}>
            <TextInputForm
              name="type"
              control={control}
              label="Tipo de sociedad"
              placeholder="Ingrese el tipo de sociedad"
            />
          </Grid>
          <Grid xs={4}>
            <TextInputForm
              name="activity"
              control={control}
              label="Actividad de la empresa"
              placeholder="Ingrese la actividad de la empresa"
            />
          </Grid>
          <Grid xs={3}>
            <TextInputForm
              name="phone"
              control={control}
              label="Teléfono asociado a la empresa"
              placeholder="Ingrese el teléfono"
            />
          </Grid>
          <Grid xs={2}>
            <TextInputForm name="postalCode" control={control} label="Código postal" required />
          </Grid>
          <Grid xs={3}>
            <TextInputForm name="street" control={control} label="Calle" required />
          </Grid>
          <Grid xs={2}>
            <TextInputForm name="streetNumber" control={control} label="Número" />
          </Grid>
          <Grid xs={2}>
            <TextInputForm name="city" control={control} label="Ciudad" />
          </Grid>
          <Grid xs={2}>
            <TextInputForm name="state" control={control} label="Provincia" />
          </Grid>
          <Grid xs={12}>
            <div>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <Typography variant="h4">Representante legal</Typography>
              </Box>
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid xs={3}>
                  <TextInputForm name="legalRepresentative" control={control} label="Nombre del Representante Legal" />
                </Grid>
                <Grid xs={3}>
                  <TextInputForm
                    name="legalRepresentativeDocumentNumber"
                    control={control}
                    label="Documento del Representante Legal"
                    type="number"
                  />
                </Grid>
                <Grid xs={3}>
                  <TextInputForm
                    name="legalRepresentativeEmail"
                    control={control}
                    label="Correo electrónico"
                    tooltip="El correo suministrado no debe ser genérico como legal@; info@, contacto@; a  efectos de la firma del contrato."
                  />
                </Grid>
                <Grid xs={3}>
                  <TextInputForm name="legalRepresentativePhone" control={control} label="Teléfono" />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid xs={12}>
            <KybManualShareholders control={control} />
          </Grid>
          <Grid xs={12}>
            <KybManualFiles control={control} />
          </Grid>
          <Grid xs={12}>
            <div>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <Typography variant="h4">Comentario</Typography>
              </Box>
              <TextInputForm name="comment" control={control} />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default KybManualForm
