import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h4',
  1: 'h4',
}

const CheckCade = ({ title = '', cadeCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>{title || t('common:cade')}</Typography>
      <CheckDetailTitle
        email={cadeCheck?.email}
        status={cadeCheck?.status}
        createdAt={cadeCheck?.createdAt}
        updatedAt={cadeCheck?.updatedAt}
      />
      <Box className="">
        <h4>
          {t('common:check')} {t('common:details')}
        </h4>
        <Box className="detail-group-grid-5">
          <Box>
            <span>Data Registro</span>
            <p>{cadeCheck?.checkDetails[0]?.data_registro ?? '---'}</p>
          </Box>

          <Box>
            <span>Interessados</span>
            <p>{cadeCheck?.checkDetails[0]?.interessados ?? '---'}</p>
          </Box>
          <Box>
            <span>Processo</span>
            <p>{cadeCheck?.checkDetails[0]?.processo ?? '---'}</p>
          </Box>
          <Box>
            <span>Tipo</span>
            <p>{cadeCheck?.checkDetails[0]?.tipo ?? '---'}</p>
          </Box>
          <Box>
            <span>Url</span>
            <p style={{ wordBreak: 'break-all' }}>{cadeCheck?.checkDetails[0]?.url ?? '---'}</p>
          </Box>
        </Box>
        <Box className="cade-wrapper">
          <h4>Andamentos</h4>
          <Box className="group-table">
            <table className="bs bst group-table">
              <thead>
                <tr className="group-table">
                  <th className="bs">Data/Hora:</th>
                  <th className="bs">Descricao:</th>
                  <th className="bs">Unidade:</th>
                </tr>
              </thead>
              <tbody>
                {cadeCheck?.checkDetails[0]?.andamentos &&
                  Array.isArray(cadeCheck?.checkDetails[0]?.andamentos) &&
                  cadeCheck?.checkDetails[0]?.andamentos.map((andamento, index) => {
                    return (
                      <tr className="group-table" key={index}>
                        <td className="bs table-cell col-5">{andamento.datahora}</td>
                        <td className="bs table-cell col-5">{andamento.descricao}</td>
                        <td className="bs table-cell col-5">{andamento.unidade}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </Box>
        </Box>

        <Box className="cade-wrapper">
          <h4>Protocolos</h4>
          <Box className="group-table">
            <table className="bs bst group-table">
              <thead>
                <tr className="group-table">
                  <th className="bs">Data:</th>
                  <th className="bs">Registro:</th>
                  <th className="bs">Documento:</th>
                  <th className="bs">Tipo:</th>
                  <th className="bs">Unidade:</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(cadeCheck?.checkDetails[0]?.protocolos) &&
                  cadeCheck?.checkDetails[0]?.protocolos.map((protocolo, index) => {
                    return (
                      <tr className="group-table" key={index}>
                        <td className="bs table-cell col-5">{protocolo.data}</td>
                        <td className="bs table-cell col-5">{protocolo.data_registro}</td>
                        <td className="bs table-cell col-5">{protocolo.documento}</td>
                        <td className="bs table-cell col-5">{protocolo.tipo}</td>
                        <td className="bs table-cell col-5">{protocolo.unidade}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </Box>
        </Box>
        {cadeCheck?.checkDetails?.information && (
          <Box>
            <span>{t('common:information')}</span>
            <p>{cadeCheck?.checkDetails?.information}</p>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CheckCade
