import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'

export const listsSlice = createSlice({
  name: 'lists',
  initialState: {
    lists: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      id: '',
      type: '',
      status: '',
      list: '',
      context: '',
      title: '',
      description: '',
    },
  },
  reducers: {
    updateLists: (state, action) => {
      state.lists = action.payload
    },
    updateListsFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

const listsApi = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findAllLists: builder.query({
      query: ({ emailClient, type, context }) => ({
        url: '/admin/lists',
        method: 'GET',
        params: {
          emailClient,
          type,
          context,
        },
      }),
    }),
  }),
})

export const { updateListsFilters, updateLists } = listsSlice.actions

export const { useFindAllListsQuery } = listsApi

export default listsSlice.reducer
