import { Box, Fab, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const ModalTitle = ({ title, subtitle, setModalOpen, closeDisabled = false }) => (
  <Box className="modal-top">
    <Box className="modal-titles">
      <Typography variant="title">{title}</Typography>
      {subtitle && <Typography variant="subtitle3">{subtitle}</Typography>}
    </Box>
    <Fab variant="close" onClick={() => setModalOpen(false)} disabled={closeDisabled}>
      <CloseOutlinedIcon />
    </Fab>
  </Box>
)

export default ModalTitle
