import '../reports.scss'
import '../../../components/common/datatable/datatable.scss'
import { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import Spinner from '../../../components/common/spinner/spinner'
import { useReports } from '../../pages-hooks/useReports'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import {
  changeUserPasswordReportDataMapper,
  changeUserPasswordReportDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/changeUserPasswordReportDataMapper'
import { useSelector } from 'react-redux'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { useTranslation } from 'react-i18next'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'

const ChangePasswordReport = ({
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  isLoading,
  setIsLoading,
  setModalOpen,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const [previous, setPrevious] = useState({})
  const [newImage, setNewImage] = useState({})
  const [open, setOpen] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { t } = useTranslation(['common', 'messages'])

  const allReports = useReports('change_user_password', setIsLoading, isLoading)

  const handleClose = () => {
    setOpen(false)
    setPrevious({})
    setNewImage({})
  }

  return (
    <div className="datatable">
      {isLoading && <Spinner noTransform />}

      <TableContainer className="wrapper">
        <Box className="filter-bar">
          <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
            {t('common:filters')}
          </Button>
          {activeServices?.includes('reportsitem') && allReports?.data?.length > 0 && (
            <Button
              disabled={!activeServices?.includes('reportsitem') || !allReports?.data?.length}
              onClick={() => setShowDownloadModal(true)}
              endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
              variant="outlined"
            >
              {/* <DownloadIcon /> */}
              <Typography variant="title2">{t('common:exportReport')}</Typography>
            </Button>
          )}
        </Box>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Table className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell>{t('common:Id')}</TableCell>
              <TableCell>
                {t('common:action')} {t('common:owner')}
              </TableCell>
              <TableCell>Affected User</TableCell>
              <TableCell>{t('common:status')}</TableCell>
              <TableCell>{t('common:date')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(allReports?.data) && allReports.data?.length !== 0 ? (
              allReports.data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="table-body-cell">{row._id}</TableCell>
                    <TableCell className="table-body-cell">{row.emailUser}</TableCell>
                    <TableCell className="table-body-cell">{row.activityDetails?.userCredentials}</TableCell>
                    <TableCell className="table-body-cell">{row.activityDetails?.actionResult}</TableCell>
                    <TableCell className="table-body-cell">{buildTimeStringFromTimestamp(row.createdAt)}</TableCell>
                  </TableRow>
                )
              })
            ) : (
              <Box className="table-custom-overlay">
                <CustomNoRowsOverlay />
              </Box>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={allReports?.data}
            dataMapper={changeUserPasswordReportDataMapper}
            dataMapperForPDF={changeUserPasswordReportDataMapperForPDF}
            fileName={'change-password-report'}
            typeDataExport={'reports'}
            keyReports={'change_user_password'}
          />
        </div>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="report-detail-modal"
      >
        <Box className="report-detail">
          <Box>
            <h2>Previous Image:</h2>
            <Box>
              <p>{`Status: ${previous?.status}`}</p>
              <p>{`Has API Key: ${previous?.hasApiKey}`}</p>
              <p>Roles: {previous?.roles?.map((role) => role + ' ')}</p>
            </Box>
          </Box>
          <hr />
          <Box>
            <h2>New Image:</h2>
            <Box>
              <p>{`Status: ${newImage?.status}`}</p>
              <p>{`Has API Key: ${newImage?.hasApiKey}`}</p>
              <p>Roles: {newImage?.roles?.map((role) => role + ' ')}</p>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default ChangePasswordReport
