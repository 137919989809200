import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateRules } from '../../features/rules/rulesSlice'

export const useRules = (isLoading, setIsLoading, setRules) => {
  const { accessToken } = useSelector((state) => state.login)
  const rulesState = useSelector((state) => state.rule)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoading) {
      const urlGuenoRules = `${process.env.REACT_APP_BASEURL}/rulesTemplates?viewMode=true`

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      let allRules = []

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/rule-templates.json' : urlGuenoRules, options)
        .then((res) => res.json())
        .then((res) => {
          const { data } = res
          if (data && Array.isArray(data)) {
            const guenoData = data.map((item) => ({ ...item, gueno: true }))
            const aux = guenoData.sort((a, b) => {
              return a.id?.split('-')[1] - b.id?.split('-')[1]
            })
            allRules.push(...aux)
          }

          dispatch(updateRules(allRules))
        })
        .catch((error) => {
          console.error('[HOOK: useRules] --> ', error)
        })
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (rulesState?.rules?.length !== 0) {
      setRules(rulesState.rules)
      setIsLoading(false)
    }
  }, [rulesState])

  return rulesState.rules
}
