import { createSlice } from '@reduxjs/toolkit'

export const merchantsSlice = createSlice({
  name: 'merchants',
  initialState: {
    merchants: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      id: '',
      type: '',
      status: '',
      list: '',
      title: '',
      description: '',
    },
  },
  reducers: {
    updateMerchants: (state, action) => {
      state.merchants = action.payload
    },
    updateMerchantsFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateMerchantsFilters, updateMerchants } = merchantsSlice.actions
export default merchantsSlice.reducer
