import { useState } from 'react'
import { Box, Button, Fab, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@mui/icons-material'
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FileUploader } from 'react-drag-drop-files'
import { RenderTooltip } from '../../../../components/utilities/RenderTooltip'
import { useCreateMCCMutation } from '../../../../services/guenoApi'
import { LoadingButton } from '@mui/lab'

function ModalCreateMCC({ setShowCreateMCC, handleCreateMCC }) {
  const { t } = useTranslation(['common', 'messages'])
  const [file, setFile] = useState('')
  const [_, { isLoading }] = useCreateMCCMutation({ fixedCacheKey: 'create-mcc' })

  const handleSubmit = async () => {
    handleCreateMCC(file)
  }

  return (
    <Box className="modal">
      <Box className="modal-top">
        <Typography variant="title">{t('common:create')} MCC</Typography>
        <Fab variant="close" onClick={() => setShowCreateMCC(false)}>
          <CloseOutlined />
        </Fab>
      </Box>
      <Box className="modal-box">
        <Box className="modal-box">
          <Box className="batch-label">
            <Typography variant="title2">{t('common:fileModel')}</Typography>
            <RenderTooltip title={t('common:MCCExample')} />
          </Box>

          <Button
            variant="outlined"
            onClick={() =>
              window.open(
                'https://docs.google.com/spreadsheets/d/16QSzzT2zQX0KAC_TrQ8wS0Soqu-4m5c7gldKW2waMuo/edit?usp=sharing',
                '_blank',
                'rel=noopener noreferrer',
              )
            }
            startIcon={<InsertLinkOutlinedIcon />}
            sx={{ paddingInline: '10px' }}
          >
            MCC.csv
          </Button>
        </Box>
        <Box className="modal-box">
          <Box className="batch-label">
            <Typography variant="title2">{t('file')}</Typography>
            <RenderTooltip title={t('common:MCCFile')} />
          </Box>
          <FileUploader
            handleChange={setFile}
            name="file"
            label={t('common:BATCH_FILE_UPLOADER_LABEL')}
            types={['csv']}
          >
            <Paper
              elevation={0}
              variant="rootDashed"
              className="draggable-main draggable-small"
              sx={{ minHeight: 'auto' }}
            >
              <Box className="draggable-icon" />
              <Typography variant="title2">{t('common:BATCH_FILE_UPLOADER_LABEL')}</Typography>
              <Box>
                <Typography variant="subtitle4">{t('common:or')} </Typography>
                <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                  {t('common:browseMedia')}
                </Typography>
              </Box>
            </Paper>
          </FileUploader>
          <Typography variant="subtitle4">{t('common:supportedMedia')}: CSV.</Typography>
          {file && (
            <Paper elevation={0} variant="colorPrimary" className="onb-file">
              <Box className="onb-file-description">
                <Box className="color-button" sx={{ background: '#E4DDFF' }}>
                  <Typography variant="subtitle1" sx={{ color: '#7E65D5' }}>
                    CSV
                  </Typography>
                </Box>

                <Typography
                  variant="h6"
                  title={file.name}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  maxWidth="160px"
                >
                  {file.name}
                </Typography>
              </Box>
              <Box className="onb-file-icons">
                <Button variant="outlinedBlank" onClick={() => setFile('')}>
                  <DeleteOutlineIcon />
                </Button>
              </Box>
            </Paper>
          )}
        </Box>
      </Box>
      <Box className="lists-buttons">
        <LoadingButton size="small" variant="outlinedGrey" onClick={() => setShowCreateMCC(false)} loading={isLoading}>
          {t('common:cancel')}
        </LoadingButton>
        <LoadingButton
          disabled={!file}
          size="small"
          variant="contained"
          type="submit"
          onClick={() => handleSubmit(file)}
          loading={isLoading}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default ModalCreateMCC
