import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRestartKybMutation } from '../../../services/guenoApi'

const KybResetModal = ({ onSubmit, mutationKey }) => {
  const [_, { isLoading }] = useRestartKybMutation({ fixedCacheKey: mutationKey })
  const { t } = useTranslation(['common', 'messages'])

  return (
    <>
      <Box className="modal-button">
        <LoadingButton size="small" color="secondary" variant="contained" onClick={onSubmit} loading={isLoading}>
          {t('common:reset')}
        </LoadingButton>
      </Box>
    </>
  )
}

export default KybResetModal
