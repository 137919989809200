import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckKyc = ({ title = '', kycCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>{title || t('common:kyc')}</Typography>
      <CheckDetailTitle
        email={kycCheck?.email}
        status={kycCheck?.status}
        createdAt={kycCheck?.createdAt}
        updatedAt={kycCheck?.updatedAt}
      />
      <Box className="">
        <h4>
          {t('common:kyc')} {t('common:details')}
        </h4>
        <Box className="detail-group-grid-4">
          <Box>
            <span>{t('common:isCurrentlyPep')}</span>
            <p>{kycCheck?.checkDetails?.IsCurrentlyPEP ? 'Yes' : 'No'}</p>
          </Box>
          <Box>
            <span>{t('common:isCurrentlySanctioned')}</span>
            <p>{kycCheck?.checkDetails?.IsCurrentlySanctioned ? 'Yes' : 'No'}</p>
          </Box>
          <Box>
            <span>{t('common:wasPreviouslySanctioned')}</span>
            <p>{kycCheck?.checkDetails?.WasPreviouslySanctioned ? 'Yes' : 'No'}</p>
          </Box>
          <Box>
            <span>
              {t('common:last30')} {t('common:sanctions')}
            </span>
            <p>{kycCheck?.checkDetails?.Last30DaysSanctions ?? '---'}</p>
          </Box>
          <Box>
            <span>
              {t('common:last90')} {t('common:sanctions')}
            </span>
            <p>{kycCheck?.checkDetails?.Last90DaysSanctions ?? '---'}</p>
          </Box>
          <Box>
            <span>
              {t('common:last180')} {t('common:sanctions')}
            </span>
            <p>{kycCheck?.checkDetails?.Last180DaysSanctions ?? '---'}</p>
          </Box>
          <Box>
            <span>
              {t('common:last365')} {t('common:sanctions')}
            </span>
            <p>{kycCheck?.checkDetails?.Last365DaysSanctions ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:lastYearPEPOccurence')}</span>
            <p>{kycCheck?.checkDetails?.LastYearPEPOccurence ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:last3YearsPEPOccurence')}</span>
            <p>{kycCheck?.checkDetails?.Last3YearsPEPOccurence ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:last5YearsPEPOccurence')}</span>
            <p>{kycCheck?.checkDetails?.Last5YearsPEPOccurence ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:last5PlusYearsPEPOccurence')}</span>
            <p>{kycCheck?.checkDetails?.Last5PlusYearsPEPOccurence ?? '---'}</p>
          </Box>
          <Box>
            <span>{t('common:firstSanctionDate')}</span>
            <p>
              {!kycCheck?.checkDetails?.FirstSanctionDate?.includes('0001-01-01')
                ? kycCheck?.checkDetails?.FirstSanctionDate
                : '---'}
            </p>
          </Box>
          <Box>
            <span>{t('common:lastSanctionDate')}</span>
            <p>
              {!kycCheck?.checkDetails?.LastSanctionDate?.includes('0001-01-01')
                ? kycCheck?.checkDetails?.LastSanctionDate
                : '---'}
            </p>
          </Box>
          <Box>
            <span>{t('common:isCurrentlyElectoralDonor')}</span>
            <p>{kycCheck?.checkDetails?.IsCurrentlyElectoralDonor ? 'Yes' : 'No'}</p>
          </Box>
          <Box>
            <span>{t('common:isHistoricalElectoralDonor')}</span>
            <p>{kycCheck?.checkDetails?.IsHistoricalElectoralDonor ? 'Yes' : 'No'}</p>
          </Box>
          <Box>
            <span>
              {t('common:total')} {t('common:electoralDonations')} {t('common:lastTwoElections')}
            </span>
            <p>{kycCheck?.checkDetails?.TotalElectoralDonationsLastTwoElections ?? '---'}</p>
          </Box>
          <Box>
            <span>
              {t('common:total')} {t('common:electoralDonations')}
            </span>
            <p>{kycCheck?.checkDetails?.TotalElectoralDonations ?? '---'}</p>
          </Box>
          <Box>
            <span>
              {t('common:total')} {t('common:electoralDonations')} {t('common:amount')} {t('common:lastTwoElections')}
            </span>
            <p>{kycCheck?.checkDetails?.TotalElectoralDonationAmountLastTwoElections ?? '---'}</p>
          </Box>
          <Box>
            <span>
              {t('common:total')} {t('common:electoralDonations')} {t('common:amount')}
            </span>
            <p>{kycCheck?.checkDetails?.TotalElectoralDonationAmount ?? '---'}</p>
          </Box>
        </Box>
        {kycCheck?.checkDetails?.informação && (
          <Box>
            <span>{t('common:data')}</span>
            <p>{kycCheck?.checkDetails?.informação}</p>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CheckKyc
