import '../transactionAmountThreshold/transactionAmountThreshold.scss'
import Button from '@mui/material/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useEffect, useState } from 'react'
import { Autocomplete, Box, Fab, TextField, Typography } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useTranslation } from 'react-i18next'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'

const TransactionAmountThresholdGueno = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  isEditing,
  possibleValues,
  schema,
}) => {
  const [tat, setTat] = useState({})
  const [change, setChange] = useState(false)
  const [keyState, setKeyState] = useState(null)
  const [valueState, setValueState] = useState('')
  const { t } = useTranslation(['common'])

  const handleKeyChange = (value, key) => {
    const aux = { ...tat }
    const aux2 = aux[key]

    delete aux[key]
    aux[value] = aux2
    setTat({ ...aux })
  }

  const handleValueChange = (value, key) => {
    const aux = { ...tat }
    aux[key] = value !== '' ? parseInt(value) : ''

    setTat({ ...aux })
  }

  const handleServiceRemove = (key) => {
    const aux = { ...tat }
    delete aux[key]

    setTat({ ...aux })
    setChange(true)
  }

  const handleServiceAdd = () => {
    if (keyState && valueState) {
      let objAux = { ...tat }
      objAux[keyState] = valueState
      setTat({ ...objAux })
      setKeyState(null)
      setValueState('')
      setChange(true)
    }
  }

  useEffect(() => {
    if (Object.keys(tat).length !== 0 || change) {
      let all = { ...ruleParameters }

      if (Object.keys(tat).length === 0) delete all[propertie]
      else all[propertie] = tat

      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [tat, change])

  useEffect(() => {
    if (ruleParameters[propertie]) {
      let aux = {}
      Object.keys(ruleParameters[propertie]).forEach((element) => {
        aux[element] = ruleParameters[propertie][element]
      })
      setTat(aux)
    }
  }, [propertie])

  return (
    <Box className="threshold-container">
      {schema?.description && <Typography variant="subtitle4">{schema.description}</Typography>}

      <Box className="threshold-inputs">
        <Typography variant="title3">{t('common:currency')}</Typography>
        <Autocomplete
          options={possibleValues}
          getOptionLabel={(option) => mapCodesToNames(option)}
          filterSelectedOptions
          value={keyState}
          onChange={(e, value) => setKeyState(value)}
          disabled={!isEditing}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
            />
          )}
        />
      </Box>

      <Box className="threshold-inputs">
        <Typography variant="title3">{t('common:amount')}</Typography>
        <TextField
          fullWidth
          disabled={!isEditing}
          variant="outlined"
          size="small"
          type="number"
          placeholder={t('common:amount')}
          value={valueState}
          onChange={(e) => setValueState(e.target.value !== '' ? parseInt(e.target.value) : '')}
        />
      </Box>
      <Box className="button-right">
        {isEditing && (
          <Button
            endIcon={<AddCircleOutlineIcon />}
            variant="outlinedGrey"
            disabled={keyState === '' || valueState === ''}
            onClick={handleServiceAdd}
          >
            {t('common:add')}
          </Button>
        )}
      </Box>

      {tat &&
        Object.keys(tat).map((tatKey, index) => (
          <Box key={index}>
            <Box className="threshold-inputs">
              <Typography variant="title3">{t('common:currency')}</Typography>
              <Autocomplete
                options={possibleValues}
                getOptionLabel={(option) => mapCodesToNames(option)}
                filterSelectedOptions
                value={tatKey}
                onChange={(e, value) => handleKeyChange(value, tatKey)}
                disabled={!isEditing}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
                  />
                )}
              />
            </Box>

            <Box className="threshold-inputs">
              <Typography variant="title3">{t('common:amount')}</Typography>
              <TextField
                className="Input"
                disabled={!isEditing}
                variant="outlined"
                size="small"
                type="number"
                value={tat[tatKey]}
                onChange={(event) => handleValueChange(event.target.value, tatKey)}
              />
            </Box>
            <Box className="button-right">
              {isEditing && (
                <Fab variant="close" className="icon-fab" onClick={() => handleServiceRemove(tatKey)}>
                  <DeleteOutlineOutlinedIcon />
                </Fab>
              )}
            </Box>
          </Box>
        ))}
    </Box>
  )
}

export default TransactionAmountThresholdGueno
