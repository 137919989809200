import { Autocomplete, Box, InputLabel, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import './paymentFilters.scss'
import { useTranslation } from 'react-i18next'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'

const PaymentFilters = ({
  propertie,
  ruleParameters,
  setRuleParameters,
  isEditing,
  possibleValues,
  possibleCountries,
  possibleChannels,
  filterTemplate,
}) => {
  const [selectedMethods, setSelectedMethods] = useState([])
  const [cardIssuedCountries, setCardIssuedCountries] = useState([])
  const [paymentChannels, setPaymentChannels] = useState([])
  const [walletType, setWalletType] = useState('')
  const [showCardData, setShowCardData] = useState(false)
  const [showWalletData, setShowWalletData] = useState(false)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    setShowCardData(selectedMethods.includes('CARD'))
    setShowWalletData(selectedMethods.includes('WALLET'))
  }, [selectedMethods])

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters.length === 0) return -1
    if (ruleParameters) return ruleParameters.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const index = getIndex()
    const buildedObject = buildObject()

    if (Object.keys(buildedObject).length !== 0) {
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}
      previous[propertie] = buildedObject
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      all.push(previous)

      setRuleParameters([...all])
    }
  }, [selectedMethods, cardIssuedCountries, paymentChannels, walletType])

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux && aux[propertie]) {
      const auxProperties = aux[propertie]
      setSelectedMethods(Array.isArray(auxProperties.paymentMethods) ? auxProperties.paymentMethods : [])
      setCardIssuedCountries(Array.isArray(auxProperties.cardIssuedCountries) ? auxProperties.cardIssuedCountries : [])
      setPaymentChannels(Array.isArray(auxProperties.cardPaymentChannels) ? auxProperties.cardPaymentChannels : [])
      setWalletType(auxProperties.walletType || '')
    }

    if (!aux) {
      setSelectedMethods([])
      setCardIssuedCountries([])
      setPaymentChannels([])
      setWalletType('')
    }
  }, [ruleParameters, filterTemplate, propertie])

  const buildObject = () => {
    const param = {
      paymentMethods: selectedMethods.length > 0 ? selectedMethods : null,
      walletType: selectedMethods.includes('WALLET') ? walletType : null,
      cardIssuedCountries: selectedMethods.includes('CARD') ? cardIssuedCountries : null,
      cardPaymentChannels: selectedMethods.includes('CARD') ? paymentChannels : null,
    }

    if (param.paymentMethods === null) delete param.paymentMethods
    if (param.walletType === null) delete param.walletType
    if (param.cardIssuedCountries === null) delete param.cardIssuedCountries
    if (param.cardPaymentChannels === null) delete param.cardPaymentChannels

    return param
  }

  const handleWalletTypeChange = (value) => {
    setWalletType(value)
  }

  return (
    <Box className="low-pair">
      <Box className="low-pair">
        <InputLabel>{t('common:paymentMethods')}</InputLabel>
        <Autocomplete
          multiple
          options={possibleValues}
          filterSelectedOptions
          value={selectedMethods}
          disabled={!isEditing}
          onChange={(e, value) => setSelectedMethods(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
            />
          )}
        />
      </Box>

      {showCardData && (
        <Box className="low-pair">
          <Box className="low-pair">
            <InputLabel>{t('common:cardIssuedCountries')}</InputLabel>
            <Autocomplete
              multiple
              options={possibleCountries}
              getOptionLabel={(option) => mapCodesToNames(option)}
              filterSelectedOptions
              value={cardIssuedCountries}
              disabled={!isEditing}
              onChange={(e, value) => setCardIssuedCountries(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
                />
              )}
            />
          </Box>
          <Box className="low-pair">
            <InputLabel>{t('common:paymentChannels')}</InputLabel>
            <Autocomplete
              multiple
              filterSelectedOptions
              options={possibleChannels}
              value={paymentChannels}
              disabled={!isEditing}
              onChange={(e, value) => setPaymentChannels(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder={`${t('common:select')} ${t('common:or')} ${t('common:search')}...`}
                />
              )}
            />
          </Box>
        </Box>
      )}
      {showWalletData && (
        <Box className="low-pair">
          <InputLabel>{t('common:walletType')}</InputLabel>

          <TextField
            size="small"
            type="text"
            disabled={!isEditing}
            variant="outlined"
            value={walletType}
            onChange={(event) => handleWalletTypeChange(event.target.value)}
          />
        </Box>
      )}
    </Box>
  )
}

export default PaymentFilters
