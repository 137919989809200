import './timeRange.scss'
import { useEffect, useState } from 'react'
import { Box, InputLabel, Typography } from '@mui/material'
import NumberInput from '../../../rules/ruleParameters2/numberInput/numberInput'
import SelectSingle from '../../../rules/ruleParameters2/selectSingle/selectSingle'
import { useTranslation } from 'react-i18next'

const granularityEnum = ['day', 'month', 'year']
const ageUnits = {
  type: 'integer',
  title: 'Number of time unit',
  minimum: 0,
}

const TimeRange = ({ propertie, ruleParameters, setRuleParameters, desc, isEditing, filterTemplate }) => {
  const [finalParameter, setFinalParameter] = useState({})
  const [granular, setGranular] = useState({})
  const [change, setChange] = useState(false)
  const { t } = useTranslation(['common'])

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters?.length === 0) return -1
    if (ruleParameters) return ruleParameters?.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux && JSON.stringify(aux[propertie]) !== JSON.stringify(finalParameter)) {
      setFinalParameter({ ...aux[propertie] })
      setGranular({ granularity: aux[propertie]?.minAge?.granularity || '' })
    }

    if (!aux) {
      setFinalParameter({})
      setGranular({})
    }
  }, [ruleParameters, filterTemplate, propertie])

  const handleGranularChange = (value) => {
    let aux = { ...finalParameter }
    if (Object.keys(value).length !== 0) {
      if (!aux['minAge']) aux['minAge'] = {}
      if (!aux['maxAge']) aux['maxAge'] = {}
      aux['minAge']['granularity'] = value.granularity
      aux['maxAge']['granularity'] = value.granularity
    } else {
      delete aux['minAge']['granularity']
      delete aux['maxAge']['granularity']
    }
    setFinalParameter({ ...aux })
    setGranular(value)
    setChange(true)
  }

  const handleMinChange = (value) => {
    let aux = { ...finalParameter }
    if (Object.keys(value).length !== 0) {
      if (!aux['minAge']) aux['minAge'] = {}
      aux['minAge']['units'] = value.units
    } else {
      delete aux['minAge']['units']
    }
    setFinalParameter({ ...aux })
    setChange(true)
  }

  const handleMaxChange = (value) => {
    let aux = { ...finalParameter }
    if (Object.keys(value).length !== 0) {
      if (!aux['maxAge']) aux['maxAge'] = {}
      aux['maxAge']['units'] = value.units
    } else {
      delete aux['maxAge']['units']
    }
    setFinalParameter({ ...aux })
    setChange(true)
  }

  useEffect(() => {
    const index = getIndex()

    if (change) {
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}

      previous[propertie] = finalParameter
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      if (Object.keys(finalParameter).length !== 0) {
        all.push(previous)
      }

      setRuleParameters([...all])
    }
    setChange(false)
  }, [change, finalParameter])

  return (
    <Box>
      {desc && <Typography variant="subtitle4">{desc}</Typography>}
      <Box className="range-container">
        <Box className="range-row">
          <Box className="range-label">
            <span className="mandatory">* </span>
            <InputLabel>{t('common:minAge')}</InputLabel>
          </Box>
          <NumberInput
            isEditing={isEditing}
            propertie={'units'}
            ruleParameters={finalParameter?.minAge ?? {}}
            setRuleParameters={handleMinChange}
            schema={ageUnits}
          />
        </Box>
        <Box className="range-row">
          <Box className="range-label">
            <span className="mandatory">* </span>
            <InputLabel>{t('common:maxAge')}</InputLabel>
          </Box>
          <NumberInput
            isEditing={isEditing}
            propertie={'units'}
            ruleParameters={finalParameter?.maxAge ?? {}}
            setRuleParameters={handleMaxChange}
            schema={ageUnits}
          />
        </Box>
        <Box className="range-row">
          <Box className="range-label">
            <span className="mandatory">* </span>
            <InputLabel>{t('common:timeGranularity')}</InputLabel>
          </Box>
          <SelectSingle
            isEditing={isEditing}
            propertie={'granularity'}
            ruleParameters={granular}
            setRuleParameters={handleGranularChange}
            posibleValues={granularityEnum}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default TimeRange
