import './datatable.scss'
import { Box, Button, Fab, Modal, Switch, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import AlertFetchSpinner from '../alertFetchSpinner/alertFetchSpinner'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { useTranslation } from 'react-i18next'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import EditIcon from '@mui/icons-material/Edit'
import { useWorkflows } from '../../../pages/pages-hooks/useWorkflows'
import { useCases } from '../../../pages/pages-hooks/useCases'

const DatatableWorkflows = ({ page, setPage, rowsPerPage, setRowsPerPage, setModalOpen, setIsLoading, isLoading }) => {
  const { accessToken, activeServices } = useSelector((state) => state.login)
  const [idToDelete, setIdToDelete] = useState('')
  const [message, setMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [isAssigned, setIsAssigned] = useState(false)
  const [assignedCases, setAssignedCases] = useState([])

  const [open2, setOpen2] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])

  const workflowsState = useWorkflows(setIsLoading, isLoading)
  const casesState = useCases(setIsLoading, isLoading)

  const handleClose2 = () => {
    setIsLoading(false)
    setOpen2(false)
    setMessage('')
    setIsAssigned(false)
    setAssignedCases([])
    setIdToDelete('')
  }

  const handleWorkflowView = (name) => {
    navigate(`/case-workflows/case/${name}`)
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setIsAssigned(false)
    setAssignedCases([])
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleOpen2 = (name) => {
    setOpen2(true)
    setMessage(`${t('messages:deleteWorkflowSelected')}: ${name}?`)
  }

  const handleDelete = (id) => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const assignedCases = casesState?.data?.filter((c) => c.assignedWorkflow === id)

      if (assignedCases.length > 0) {
        setIsAssigned(true)
        setAssignedCases(assignedCases)
      } else {
        const urlAdminRoles = `${process.env.REACT_APP_BASEURL}/workflows/${id}`
        const options = {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
        fetch(urlAdminRoles, options)
          .then((res) => {
            if (!res.status || res.status !== 204)
              throw Error(`${t('messages:deletionError')}. ${t('messages:contactSupport')}.`)
            handleClose2()
            handleFetch(false, t('messages:deletionSuccess'))
          })
          .catch((error) => {
            console.error('[DELETE WORKFLOW ERROR] --> ', error)
            handleClose2()
            handleFetch(true, error.message ? error.message : t('messages:deletionError'))
          })
      }
    } else {
      handleClose2()
      handleFetch(false, t('messages:deletionSuccess'))
    }
  }

  const handleUpdateWorkflowStatus = (e, row) => {
    setIsLoading(true)
    e.preventDefault()
    let data = {
      status: row.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    }

    const urlUser = `${process.env.REACT_APP_BASEURL}/workflows/${row.id}`
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }

    fetch(urlUser, options)
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
        handleFetch(false, res.message)
      })
      .catch((error) => {
        console.error('[UPDATE WORKFLOW ERROR] --> ', error)
        setIsLoading(false)
        handleFetch(true, error.message ? error.message : `${t('common:workflows')} ${t('messages:updateError')}`)
      })
  }

  const columns = [
    {
      field: 'name',
      headerName: t('common:name'),
    },
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => {
        return (
          <Box className="user-admin-flex">
            <>
              {params.row.status}
              <Switch
                key={params.row.id}
                checked={params.row.status === 'ACTIVE' ? true : false}
                onChange={(e) => {
                  handleUpdateWorkflowStatus(e, params.row)
                }}
              />
            </>
          </Box>
        )
      },
    },
    {
      field: 'context',
      headerName: t('common:context'),
      renderCell: (params) => {
        return <Box>{params.row?.context ? t(`common:${params.row?.context}`) : '---'}</Box>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      hide: !activeServices.includes('workflows:id'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box className="search-buttons">
            <Button
              variant="outlinedButtonSuccess"
              onClick={() => {
                handleWorkflowView(params.row.id)
              }}
            >
              <EditIcon />
            </Button>
            <Button
              variant="outlinedButtonError"
              onClick={() => {
                setIdToDelete(params.row.id)
                handleOpen2(params.row.name)
              }}
            >
              <DeleteIcon />
            </Button>
          </Box>
        )
      },
    },
  ]

  return (
    <Box>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
      <DataGridComponent
        rows={
          (Array.isArray(workflowsState?.workflows?.data) &&
            workflowsState?.workflows?.data?.length > 0 &&
            workflowsState?.workflows?.data) ||
          []
        }
        columns={columns}
        count={workflowsState?.workflows?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setIsLoading={setIsLoading}
        setModalOpen={setModalOpen}
      />

      <Modal open={open2} onClose={handleClose2}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:delete')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => handleClose2()}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box">
            {!isAssigned ? (
              <Box className="modal-box">
                <Typography variant="subtitle">{message}</Typography>
                <Typography variant="subtitle">{t('messages:actionUndone')}</Typography>
              </Box>
            ) : (
              <Box className="modal-box">
                <Typography variant="subtitle">{t('messages:isAssignedMessage')}: </Typography>
                <Box className="state-edit-container">
                  {assignedCases &&
                    assignedCases.length > 0 &&
                    assignedCases.map((c) => {
                      return <Typography variant="title2" key={c.maskId ? c.maskId : c.id}>{c.maskId ? c.maskId : c.id} </Typography>
                    })}
                  <Typography variant="subtitle">{t('messages:cannotBeDeleted')}</Typography>
                </Box>
              </Box>
            )}
            {!isAssigned ? (
              <Box className="modal-filter-buttons">
                <Button variant="outlined" onClick={handleClose2}>
                  {t('common:no')}
                </Button>
                <Button variant="contained" onClick={() => handleDelete(idToDelete)}>
                  {t('common:yes')}
                </Button>
              </Box>
            ) : (
              <Box sx={{ ml: 'auto' }}>
                <Button variant="outlined" onClick={handleClose2}>
                  {t('common:close')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default DatatableWorkflows
