import { Box, Button, FormControl, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import styles from './styles.module.scss'
import useDebounce from '../../../components/hooks/useDebounce'
import DatatableChecks from '../components/DataTableChecks'
import { CreateCheckForm } from '../components/CreateCheckForm'
import { useCreateKycPersonCheckMutation } from '../../../features/kyc/kycSlice'
import toaster from '../../../toaster'
import { useNavigate } from 'react-router-dom'

export const KycChecks = () => {
  const { t } = useTranslation(['common', 'messages'])
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState()
  const [showCreateCheckModal, setShowCreateCheckModal] = useState(false)

  const [createKycCheck] = useCreateKycPersonCheckMutation({ fixedCacheKey: 'create-check' })

  const searchTextDebounced = useDebounce(searchText, 500)

  const onSubmit = async (values) => {
    try {
      const { data } = await createKycCheck({ id: values.id, body: values }).unwrap()

      toaster.success(t('messages:creationSuccess'))
      setShowCreateCheckModal(false)

      if (data.length === 1) {
        return navigate(`/kyc/person/${values.id}/${data[0].type}?checkId=${data[0]._id}`)
      }

      return navigate(`/kyc/person/${values.id}`)
    } catch (error) {
      console.error('[CREATE NEW COMMENT ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:creationError'))
    }
  }

  return (
    <Box className={`${styles.container} filter`}>
      <Typography variant="h2" className={styles.pageTitle}>
        {t('common:KYC')} - {t('common:checks')}
      </Typography>
      <Box className={styles.actionsContainer}>
        <FormControl sx={{ m: 1, width: 350 }}>
          <TextField
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={() => {}}
            placeholder={t('messages:searchKycPersonByEmailOrName')}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton alt="Clear" disabled={!searchText} onClick={() => setSearchText('')}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Button variant="contained" onClick={() => setShowCreateCheckModal(true)}>
          {`${t('common:create')} ${t('common:check')}`}
        </Button>
      </Box>
      <Box className={styles.tableContainer}>
        <DatatableChecks text={searchTextDebounced} />
      </Box>
      <Modal open={showCreateCheckModal} onClose={() => setShowCreateCheckModal(false)}>
        <div>
          <CreateCheckForm onSubmit={onSubmit} onCancel={() => setShowCreateCheckModal(false)} />
        </div>
      </Modal>
    </Box>
  )
}
