import { Box, Tooltip, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, Link as RouterLink } from 'react-router-dom'

const RiskMatrixValueData = ({ data, list }) => {
  const componentValueRef = useRef(null)
  const [isOverflowText, setIsOverflowText] = useState(false)
  const isObject = typeof data === 'object' && !Array.isArray(data) && data !== null

  const text = useMemo(() => {
    if (isObject) {
      return `${Object.keys(data)
        .map((k) => `${k}=${data[k]}`)
        .join(', ')}`
    }

    if (Array.isArray(data)) {
      return `[${data?.join(', ')}]`
    }

    return `${data}`
  }, [isObject, data])

  const tooltipText = useMemo(() => {
    if (!isOverflowText && !list?.title) {
      return ''
    }

    if (Array.isArray(data)) {
      return (
        <Box>
          {!!list?.title && (
            <Link component={RouterLink} to={`/admin/lists/neutral`} title={list.title} target="_blank">
              <Typography fontWeight="bold">{list.title}</Typography>
            </Link>
          )}
          {data.map((t) => (
            <Typography key={t}>- {t}</Typography>
          ))}
        </Box>
      )
    }

    return data
  }, [data, isOverflowText, list?.title])

  useEffect(() => {
    if (componentValueRef.current) {
      const { offsetHeight, scrollHeight, offsetWidth, scrollWidth } = componentValueRef.current
      setIsOverflowText(offsetHeight < scrollHeight || offsetWidth < scrollWidth)
    }
  }, [text])

  return (
    <Tooltip title={tooltipText}>
      <Typography ref={componentValueRef} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {text}
      </Typography>
    </Tooltip>
  )
}

export default RiskMatrixValueData
