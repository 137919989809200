import { Box, Button, Paper, Typography } from '@mui/material'
import { FileUploader } from 'react-drag-drop-files'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import { Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const KybManualFiles = ({ control }) => {
  const { t } = useTranslation(['common', 'messages'])
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'files',
  })

  const handleUploadFile = (file) => {
    append(file)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="h4">Documentos</Typography>
      </Box>
      <FileUploader
        handleChange={handleUploadFile}
        label={t('common:fileUploaderLabel')}
        types={['JPG', 'JPEG', 'PNG', 'GIF', 'PDF', 'DOC', 'CSV']}
      />
      {fields.map((field, index) => (
        <Controller
          key={field.id}
          name={`files.${index}.name`}
          control={control}
          render={({ field }) => (
            <Paper
              elevation={0}
              variant="colorPrimary"
              key={index}
              sx={{ display: 'flex', gap: 2, width: 'fit-content' }}
            >
              <Typography variant="text2" title={field.value}>
                {field.value}
              </Typography>
              <Button variant="outlinedBlank" onClick={() => remove(index)}>
                <DeleteOutline />
              </Button>
            </Paper>
          )}
        />
      ))}
    </Box>
  )
}

export default KybManualFiles
