import { useEffect, useState } from 'react'
import { Box, Input, InputLabel, MenuItem, Modal, Select } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import ModalTitle from '../../../../../components/common/Modal/ModalTitle'
import { useUpdateKycCheckMutation, useUpdateKycPersonMutation } from '../../../../../features/kyc/kycSlice'

const EditFieldModal = ({ modalOpen, setModalOpen, field, value, options, onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [textValue, setTextValue] = useState(value)
  const [change, setChange] = useState(false)

  const [_, { isLoading: isLoadingPerson }] = useUpdateKycPersonMutation({ fixedCacheKey: 'kyc-person-update' })
  const [__, { isLoading: isLoadingCheck }] = useUpdateKycCheckMutation({ fixedCacheKey: 'kyc-check-update' })

  const onChange = (newValue) => {
    setTextValue(newValue)
    if (newValue !== value) {
      setChange(true)
    } else {
      setChange(false)
    }
  }

  useEffect(() => {
    setTextValue(value)
    setChange(false)
  }, [value])

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Box className="modal">
          <ModalTitle setModalOpen={setModalOpen} title={`${t('common:edit')} ${t(`common:${field}`)}`} />
          <Box className="modal-box">
            <InputLabel>{t(`common:${field}`)}:</InputLabel>
            {options && options.length > 0 ? (
              <Select value={textValue || ''} onChange={(e) => onChange(e.target.value)}>
                {options.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      {item}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Input value={textValue} onChange={(event) => onChange(event.target.value)} />
            )}
          </Box>
          <Box className="modal-button">
            <LoadingButton
              size="small"
              color="secondary"
              variant="contained"
              disabled={!change}
              onClick={() => onSubmit({ [field]: textValue })}
              loading={isLoadingPerson || isLoadingCheck}
            >
              {t('common:save')}
            </LoadingButton>
          </Box>
        </Box>
      </div>
    </Modal>
  )
}

export default EditFieldModal
