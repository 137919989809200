import './datatable.scss'
import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Autocomplete, Box, TextField, Link, Typography, Button, IconButton } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { FilterModal } from '../../utilities/FilterModal'
import { updateFilters } from '../../../features/batch/batchSlice'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDeleteBatchMutation, useFindBatchesQuery } from '../../../services/guenoApi'

function DatatableBatch() {
  const { activeServices, emailClient } = useSelector((state) => state.login)
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const { filters } = useSelector((state) => state.batch)
  const [type, setType] = useState(filters.type)
  const [status, setStatus] = useState(filters.status)

  const { data, isFetching, isLoading, refetch } = useFindBatchesQuery({
    emailClient,
    page: filters.page,
    limit: filters.limit,
    status: filters.status,
    type: filters.type,
    offset: filters.offset,
  })
  const [deleteBatch] = useDeleteBatchMutation()

  const handleBatchDelte = async (id) => {
    await deleteBatch({ id })
    await refetch()
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Batch ID',
      flex: 1,
      minWidth: 230,
      renderCell: (params) => (
        <Link component={ReactRouterLink} to={`/batchDetails/${params.id}`}>
          <Typography variant="number">{params.row.id}</Typography>
        </Link>
      ),
    },
    {
      field: 'type',
      headerName: t('common:type'),
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      flex: 1,
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      flex: 1,
      minWidth: 230,
      renderCell: (params) => (
        <Button
          disabled={!activeServices?.includes('batchdeepDelete:batchId')}
          variant="outlinedButtonError"
          onClick={() => {
            handleBatchDelte(params?.id)
          }}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ]

  const handleResetButton = () => {
    if (status !== '') setStatus('')
    if (type !== '') setType('')
    setPage(0)
    setModalOpen(false)

    return dispatch(
      updateFilters({
        page: 0,
        limit: 50,
        offset: 0,
        type: '',
        status: '',
      }),
    )
  }

  const handleDispatch = () => {
    setPage(0)

    return dispatch(
      updateFilters({
        page,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        type,
        status,
      }),
    )
  }

  return (
    <Box>
      <Box sx={{ textAlign: 'right', padding: 0 }}>
        <IconButton onClick={refetch} disabled={isLoading || isFetching} title={t('common:refresh')}>
          <RefreshIcon color="action" />
        </IconButton>
      </Box>
      <DataGridComponent
        rows={data?.data || []}
        columns={columns}
        count={data?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        isLoading={isLoading || isFetching}
        setIsLoading={() => {}}
        setModalOpen={setModalOpen}
      />

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:type')}:
            </Typography>

            <Autocomplete
              size="small"
              value={type}
              onChange={(event, value) => {
                if (value) {
                  setType(value)
                } else {
                  setType(null)
                }
              }}
              disablePortal
              options={['CONSUMER', 'BUSINESS', 'TRANSACTION']}
              renderInput={(params) => <TextField variant="outlined" {...params} placeholder={t('common:type')} />}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:status')}:
            </Typography>

            <Autocomplete
              size="small"
              value={status}
              onChange={(event, value) => {
                if (value) {
                  setStatus(value)
                } else {
                  setStatus(null)
                }
              }}
              disablePortal
              options={['CREATED', 'PROCESSING', 'READY', 'ERROR', 'PROCESSED', 'PARTIAL_PROCESSED', 'DISCARDED']}
              renderInput={(params) => <TextField variant="outlined" {...params} placeholder={t('common:status')} />}
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default DatatableBatch
