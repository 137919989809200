import { Autocomplete, Box, Chip, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StringArrayInput = ({ propertie, ruleParameters, setRuleParameters, isEditing, filterTemplate }) => {
  const [valueState, setValueState] = useState([])
  const { t } = useTranslation(['common'])

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters.length === 0) return -1
    if (ruleParameters) return ruleParameters?.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters?.find((e) => e.filterTemplate === filterTemplate)

    if (aux && JSON.stringify(aux[propertie]) !== JSON.stringify(valueState)) {
      setValueState(aux[propertie] || [])
    }

    if (!aux && valueState.length > 0) {
      setValueState([])
    }
  }, [ruleParameters, propertie, filterTemplate])

  const handleChange = (event, newValue) => {
    setValueState(newValue)

    const index = getIndex()
    const updatedParameters = [...ruleParameters]

    if (index >= 0) {
      updatedParameters[index] = {
        ...updatedParameters[index],
        [propertie]: newValue,
      }
    } else {
      updatedParameters.push({
        [propertie]: newValue,
        filterTemplate,
      })
    }

    setRuleParameters(updatedParameters)
  }

  return (
    <Autocomplete
      disabled={!isEditing}
      value={valueState}
      onChange={handleChange}
      multiple
      id="tags-filled"
      className="tags-chips"
      options={[]}
      freeSolo
      fullWidth
      size="small"
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="transparent" color="primary" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder={t('common:insertValuesPressEnter')} />}
    />
  )
}

export default StringArrayInput
