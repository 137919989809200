import '../reports.scss'
import { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ruleQReportDataMapper,
  ruleQReportDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/ruleQReportDataMapper'
import Spinner from '../../../components/common/spinner/spinner'
import { useReports } from '../../pages-hooks/useReports'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import { useTranslation } from 'react-i18next'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'

const RulesQReport = ({
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  reportKey,
  isLoading,
  setIsLoading,
  setModalOpen,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])

  const allReports = useReports(reportKey, setIsLoading, isLoading)

  return (
    <Box className="datatable">
      {isLoading && <Spinner noTransform />}

      <TableContainer className="wrapper">
        <Box className="filter-bar">
          <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
            {t('common:filters')}
          </Button>
          {activeServices?.includes('reportsitem') && allReports?.data?.length > 0 && (
            <Button
              disabled={!activeServices?.includes('reportsitem') || !allReports?.data?.length}
              onClick={() => setShowDownloadModal(true)}
              endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
              variant="outlined"
            >
              {/* <DownloadIcon /> */}
              <Typography variant="title2">{t('common:exportReport')}</Typography>
            </Button>
          )}
        </Box>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>{t('common:ruleId')}</TableCell>
              <TableCell>{t('common:ruleInstanceId')}</TableCell>
              <TableCell>{t('common:exeQ')}</TableCell>
              <TableCell>{t('common:hitQ')}</TableCell>
              <TableCell>{t('common:exeRate')}</TableCell>
              <TableCell>{t('common:hitRate')}</TableCell>
              <TableCell>{t('common:hitExeRatio')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(allReports?.data) && allReports?.data?.length > 0 ? (
              allReports.data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{row.ruleId}</TableCell>
                    <TableCell>{row.ruleInstanceId}</TableCell>
                    <TableCell>{row.exeQ}</TableCell>
                    <TableCell>{row.hitQ}</TableCell>
                    <TableCell>{row.exeRate !== -1 ? row?.exeRate?.toFixed(4) : 'not-defined value'}</TableCell>
                    <TableCell>{row.hitRate !== -1 ? row?.hitRate?.toFixed(4) : 'not-defined value'}</TableCell>
                    <TableCell>{row.hitExeRate !== -1 ? row?.hitExeRate?.toFixed(4) : 'not-defined value'}</TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow className="no-rows-user no-rows-container">
                <td>{t('common:noRows')}</td>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={allReports?.data}
            dataMapper={ruleQReportDataMapper}
            dataMapperForPDF={ruleQReportDataMapperForPDF}
            fileName={reportKey}
          />
        </div>
      </Modal>
    </Box>
  )
}

export default RulesQReport
