import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckLawSuits = ({ title = '', lawSuitsCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>
        {title || `${t('common:lawsuits')} ${t('common:shareholders')}`}
      </Typography>
      <CheckDetailTitle
        email={lawSuitsCheck.email}
        status={lawSuitsCheck.status}
        createdAt={lawSuitsCheck.createdAt}
        updatedAt={lawSuitsCheck.updatedAt}
      />
      <Box>
        <h4>{t('common:sanctions')}</h4>
        <Box className="group-table">
          <table className="bs bst group-table">
            <thead>
              <tr className="group-table">
                <th className="bs">{t('common:firstLawsuitDate')}</th>
                <th className="bs">{t('common:lastLawsuitDate')}</th>
                <th className="bs">
                  {t('common:last90')} {t('common:lawsuits')}
                </th>
                <th className="bs">{t('common:averagePerOwner')}</th>
                <th className="bs">{t('common:activeOwners')}</th>
                <th className="bs">{t('common:total')}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="group-table">
                <td className="bs table-cell col-4">{lawSuitsCheck?.checkDetails?.FirstLawsuitDate ?? '---'}</td>
                <td className="bs table-cell col-4">{lawSuitsCheck?.checkDetails?.LastLawsuitDate ?? '---'}</td>
                <td className="bs table-cell col-4">{lawSuitsCheck?.checkDetails?.Last90DaysLawsuits ?? '---'}</td>
                <td className="bs table-cell col-4">{lawSuitsCheck?.checkDetails?.AverageLawsuitsPerOwner ?? '---'}</td>
                <td className="bs table-cell col-4">{lawSuitsCheck?.checkDetails?.ActiveOwners?.length ?? '---'}</td>
                <td className="bs table-cell col-4">{lawSuitsCheck?.checkDetails?.TotalLawsuits ?? '---'}</td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
      {lawSuitsCheck.checkDetails?.informação && (
        <Box>
          <span>{t('common:data')}</span>
          <p>{lawSuitsCheck.checkDetails.informação}</p>
        </Box>
      )}
    </Box>
  )
}

export default CheckLawSuits
