import { Box, Button, Fab, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import './notWorkingDays.scss'
import { camelCaseFormatter } from '../../../../utilities/formatters/camelCase'
import { t } from 'i18next'

const resetRange = {
  dayInit: 'sunday',
  dayEnd: 'sunday',
  dayTimeInitHours: 0,
  dayTimeInitMinutes: 0,
  dayTimeInitSeconds: 0,
  dayTimeEndHours: 0,
  dayTimeEndMinutes: 0,
  dayTimeEndSeconds: 0,
}

const WeekDayRangePicker = ({ ruleParameters, setRuleParameters, isEditing, propertie }) => {
  const [selectedRanges, setSelectedRanges] = useState([])
  const [change, setChange] = useState(false)
  const [currentRange, setCurrentRange] = useState({ ...resetRange })
  const [message, setMessage] = useState('')
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  const hours = Array.from({ length: 24 }, (_, index) => index).map((hour) => hour.toString().padStart(2, '0'))
  const minutes = Array.from({ length: 60 }, (_, index) => index).map((minute) => minute.toString().padStart(2, '0'))
  const seconds = Array.from({ length: 60 }, (_, index) => index).map((second) => second.toString().padStart(2, '0'))

  useEffect(() => {
    if (Array.isArray(ruleParameters[propertie]) && ruleParameters[propertie].length !== 0) {
      const aux = ruleParameters[propertie].map((range) => {
        return {
          dayInit: range.dayInit,
          dayEnd: range.dayEnd,
          dayTimeInitHours: range.dayTimeInit?.split(':')[0],
          dayTimeInitMinutes: range.dayTimeInit?.split(':')[1],
          dayTimeInitSeconds: range.dayTimeInit?.split(':')[2],
          dayTimeEndHours: range.dayTimeEnd?.split(':')[0],
          dayTimeEndMinutes: range.dayTimeEnd?.split(':')[1],
          dayTimeEndSeconds: range.dayTimeEnd?.split(':')[2],
        }
      })
      setSelectedRanges(aux)
    }
  }, [propertie])

  const handleTimeChange = (key, event) => {
    const aux = { ...currentRange }
    aux[key] = event.target.value
    setCurrentRange({ ...aux })
  }

  const addRange = () => {
    if (!isRangeOverlap(currentRange)) {
      setSelectedRanges([...selectedRanges, currentRange])
      setCurrentRange({ ...resetRange })
      setMessage('')
      setChange(true)
    } else {
      setMessage(t('messages:rangeOverlap'))
    }
  }

  const removeWorkingDay = (index) => {
    const updatedRanges = [...selectedRanges]
    updatedRanges.splice(index, 1)
    setSelectedRanges([...updatedRanges])
    setChange(true)
    setMessage('')
  }

  const getTimeString = (hours, minutes, seconds) => {
    return parseInt(hours) * 60 * 60 + parseInt(minutes) * 60 + parseInt(seconds)
  }

  const isRangeOverlap = (newRange) => {
    const newRangeDayInit = days.indexOf(newRange.dayInit)
    let newRangeDayEnd = days.indexOf(newRange.dayEnd)

    if (newRangeDayInit > newRangeDayEnd) newRangeDayEnd += 7

    for (const range of selectedRanges) {
      const rangeDayInit = days.indexOf(range.dayInit)
      let rangeDayEnd = days.indexOf(range.dayEnd)

      if (rangeDayInit > rangeDayEnd) rangeDayEnd += 7
      if (newRangeDayInit > newRangeDayEnd) newRangeDayEnd += 7

      if (newRangeDayInit < rangeDayInit && newRangeDayEnd > rangeDayEnd) {
        return true
      } else if (newRangeDayInit > rangeDayInit && newRangeDayEnd < rangeDayEnd) {
        return true
      } else if (newRangeDayInit < rangeDayInit && newRangeDayEnd > rangeDayInit && newRangeDayEnd < rangeDayEnd) {
        return true
      } else if (newRangeDayInit > rangeDayInit && newRangeDayEnd > rangeDayEnd && newRangeDayInit < rangeDayEnd) {
        return true
      } else {
        const previousInit = getTimeString(range.dayTimeInitHours, range.dayTimeInitMinutes, range.dayTimeInitSeconds)
        const previousEnd = getTimeString(range.dayTimeEndHours, range.dayTimeEndMinutes, range.dayTimeEndSeconds)
        const newRangeInit = getTimeString(
          newRange.dayTimeInitHours,
          newRange.dayTimeInitMinutes,
          newRange.dayTimeInitSeconds,
        )

        const newRangeEnd = getTimeString(
          newRange.dayTimeEndHours,
          newRange.dayTimeEndMinutes,
          newRange.dayTimeEndSeconds,
        )
        if ((newRangeDayEnd === rangeDayInit || newRangeDayEnd - 7 === rangeDayInit) && newRangeEnd >= previousInit) {
          return true
        } else if (
          (newRangeDayInit === rangeDayEnd || newRangeDayInit === rangeDayEnd - 7) &&
          newRangeInit <= previousEnd
        ) {
          return true
        }
      }
    }

    return false
  }

  useEffect(() => {
    if (change) {
      const all = { ...ruleParameters }
      const finalParam = []
      selectedRanges.forEach((range) => {
        finalParam.push({
          dayInit: range.dayInit,
          dayEnd: range.dayEnd,
          dayTimeInit: `${range.dayTimeInitHours}:${range.dayTimeInitMinutes}:${range.dayTimeInitSeconds}`,
          dayTimeEnd: `${range.dayTimeEndHours}:${range.dayTimeEndMinutes}:${range.dayTimeEndSeconds}`,
        })
      })
      all[propertie] = finalParam
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [selectedRanges])

  return (
    <Box>
      {message !== '' && (
        <Box className="error-drawer">
          <strong>{message}</strong>
        </Box>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box className="working-days">
          <Box className="working-days-row">
            <Box className="selected-ranges">
              <Typography variant="title3">{t('common:from')}:</Typography>
              <Select
                size="small"
                displayEmpty
                fullWidth
                disabled={!isEditing}
                value={currentRange.dayInit}
                onChange={(event) => handleTimeChange('dayInit', event)}
                input={<OutlinedInput />}
                label={t('common:from')}
              >
                {days &&
                  days.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {camelCaseFormatter(name)}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            <Box className="selected-ranges">
              <Typography variant="title3">{t('common:atHour')}:</Typography>
              <Select
                size="small"
                displayEmpty
                fullWidth
                disabled={!isEditing}
                value={currentRange.dayTimeInitHours}
                onChange={(event) => handleTimeChange('dayTimeInitHours', event)}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {hours &&
                  hours.map((name, index) => (
                    <MenuItem key={index} value={index}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            <Box className="selected-ranges">
              <Typography variant="title3">{t('common:atMinute')}:</Typography>
              <Select
                size="small"
                displayEmpty
                fullWidth
                disabled={!isEditing}
                value={currentRange.dayTimeInitMinutes}
                onChange={(event) => handleTimeChange('dayTimeInitMinutes', event)}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {minutes &&
                  minutes.map((name, index) => (
                    <MenuItem key={index} value={index}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            <Box className="selected-ranges">
              <Typography variant="title3">{t('common:atSecond')}:</Typography>
              <Select
                size="small"
                displayEmpty
                fullWidth
                disabled={!isEditing}
                value={currentRange.dayTimeInitSeconds}
                onChange={(event) => handleTimeChange('dayTimeInitSeconds', event)}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {seconds &&
                  seconds.map((name, index) => (
                    <MenuItem key={index} value={index}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Box>

          <Box className="working-days-row">
            <Box className="selected-ranges">
              <Typography variant="title3">{t('common:to')}:</Typography>
              <Select
                size="small"
                displayEmpty
                fullWidth
                disabled={!isEditing}
                value={currentRange.dayEnd}
                onChange={(event) => handleTimeChange('dayEnd', event)}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {days &&
                  days.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {camelCaseFormatter(name)}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            <Box className="selected-ranges">
              <Typography variant="title3">{t('common:atHour')}:</Typography>
              <Select
                size="small"
                displayEmpty
                fullWidth
                disabled={!isEditing}
                value={currentRange.dayTimeEndHours}
                onChange={(event) => handleTimeChange('dayTimeEndHours', event)}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {hours &&
                  hours.map((name, index) => (
                    <MenuItem key={index} value={index}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            <Box className="selected-ranges">
              <Typography variant="title3">{t('common:atMinute')}:</Typography>
              <Select
                size="small"
                displayEmpty
                disabled={!isEditing}
                value={currentRange.dayTimeEndMinutes}
                onChange={(event) => handleTimeChange('dayTimeEndMinutes', event)}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {minutes &&
                  minutes.map((name, index) => (
                    <MenuItem key={index} value={index}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            <Box className="selected-ranges">
              <Typography variant="title3">{t('common:atSecond')}:</Typography>
              <Select
                size="small"
                displayEmpty
                fullWidth
                disabled={!isEditing}
                value={currentRange.dayTimeEndSeconds}
                onChange={(event) => handleTimeChange('dayTimeEndSeconds', event)}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {seconds &&
                  seconds.map((name, index) => (
                    <MenuItem key={index} value={index}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Box>
        </Box>

        <Box className="working-button-right">
          <Button variant="outlinedGrey" endIcon={<AddCircleOutlineIcon />} onClick={addRange} disabled={!isEditing}>
            <Typography variant="title2">{t('common:addRange')}</Typography>
          </Button>
        </Box>
      </LocalizationProvider>

      {selectedRanges && selectedRanges.length !== 0 && (
        <Box className="selected-ranges">
          <Typography variant="title3">{t('common:selectedRanges')}</Typography>

          {selectedRanges.map((range, index) => (
            <Box className="selected-ranges-container" key={index}>
              <Box className="selected-ranges-row">
                <Typography variant="title3">{t('common:from')}:</Typography>
                {`${camelCaseFormatter(range.dayInit)} - ${range.dayTimeInitHours
                  ?.toString()
                  .padStart(2, '0')}:${range.dayTimeInitMinutes?.toString().padStart(2, '0')}:${range.dayTimeInitSeconds
                  ?.toString()
                  .padStart(2, '0')}`}
              </Box>

              <Box className="selected-ranges-row">
                <Typography variant="title3">{t('common:to')}:</Typography>
                {`${camelCaseFormatter(range.dayEnd)} - ${range.dayTimeEndHours
                  ?.toString()
                  .padStart(2, '0')}:${range.dayTimeEndMinutes.toString().padStart(2, '0')}:${range.dayTimeEndSeconds
                  ?.toString()
                  .padStart(2, '0')}`}
              </Box>

              <Box className="working-button-right">
                {isEditing && (
                  <Fab variant="close" className="icon-fab" onClick={() => removeWorkingDay(index)}>
                    <DeleteOutlineOutlinedIcon />
                  </Fab>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default WeekDayRangePicker
