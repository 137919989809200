import '../../kyt/user/user.scss'
import '../reports.scss'
import { useNavigate, useParams } from 'react-router-dom'
import LoginReport from './loginReport'
import ChangePasswordReport from './changePasswordReport'
import AbmGenericReport from './abmGenericReport'
import BlockUnblockUserReport from './blockUnblockUserReport'
import { Autocomplete, Box, InputLabel, Modal, Paper, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { updateReportsFilters } from '../../../features/reports/reportSlice'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import GeneratedReport from './generatedReport'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import RulesQReport from './rulesQReport'
import RulesQReportByClient from './rulesQReportByClient'
import { FilterModalContainer } from '../../../components/utilities/FilterModalContainer'

const ReportRouter = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { reportKey } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const queryState = useSelector((state) => state.report.filters)
  const [id, setId] = useState(queryState.id)
  const [actionResult, setActionResult] = useState(queryState.actionResult)
  const [actionType, setActionType] = useState(queryState.actionType)
  const [emailUser, setEmailUser] = useState(queryState.emailUser)
  const [fromDate, setFromDate] = useState(null)
  const [fromTime, setFromTime] = useState('00:00')
  const [toDate, setToDate] = useState(null)
  const [toTime, setToTime] = useState('23:59')
  const [affectedUser, setAffectedUser] = useState(queryState.affectedUser)
  const [abmAction, setAbmAction] = useState(queryState.abmAction)
  const [reportRoleName, setReportRoleName] = useState(queryState.reportRoleName)
  const [affectedUser2, setAffectedUser2] = useState(queryState.affectedUser2)
  const [status, setStatus] = useState(queryState.status)
  const [extension, setExtension] = useState(queryState.extension)
  const [collection, setCollection] = useState(queryState.collection)
  const languageState = useSelector((state) => state.language.lang)
  const [userId, setUserId] = useState(queryState.userId ?? '')
  const [ruleId, setRuleId] = useState(queryState.ruleId ?? '')
  const [ruleInstanceId, setRuleInstanceId] = useState(queryState.ruleInstanceId ?? '')
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation(['common', 'messages'])

  useEffect(() => {
    dayjs.locale(languageState)
  }, [languageState])

  useEffect(() => {
    dispatch(
      updateReportsFilters({
        page: 0,
        limit: rowsPerPage,
        offset: 0,
        fromDate: null,
        toDate: null,
        id: '',
        actionResult: '',
        emailUser: '',
        affectedUser: '',
        abmAction: '',
        reportRoleName: '',
        affectedUser2: '',
        status: '',
        extension: '',
        collection: '',
        userId: '',
        ruleId: '',
        ruleInstanceId: '',
      }),
    )
  }, [reportKey])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleDispatchPages = () => {
    return dispatch(
      updateReportsFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        id,
        actionResult,
        actionType,
        emailUser,
        affectedUser,
        abmAction,
        reportRoleName,
        affectedUser2,
        status,
        extension,
        collection,
        fromDate: fromDate?.toISOString(),
        toDate: toDate?.toISOString(),
        userId,
        ruleId,
        ruleInstanceId,
      }),
    )
  }

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true)
      handleDispatchPages()
    }
  }, [rowsPerPage, page])

  const reportSelector = () => {
    switch (reportKey) {
      case 'login_user_activity':
        return (
          <LoginReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setModalOpen={setModalOpen}
          />
        )
      case 'change_user_password':
        return (
          <ChangePasswordReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setModalOpen={setModalOpen}
          />
        )
      case 'block_unblock_user_access':
        return (
          <BlockUnblockUserReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setModalOpen={setModalOpen}
          />
        )
      case 'abm_users':
        return (
          <AbmGenericReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reportKey={reportKey}
            setModalOpen={setModalOpen}
          />
        )
      case 'abm_roles':
        return (
          <AbmGenericReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reportKey={reportKey}
            setModalOpen={setModalOpen}
          />
        )
      case 'abm_security_params':
        return (
          <AbmGenericReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reportKey={reportKey}
            setModalOpen={setModalOpen}
          />
        )
      case 'abm_kyt_transactions':
        return (
          <AbmGenericReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reportKey={reportKey}
            setModalOpen={setModalOpen}
          />
        )
      case 'abm_kyt_users':
        return (
          <AbmGenericReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reportKey={reportKey}
            setModalOpen={setModalOpen}
          />
        )
      case 'abm_kyt_rules':
        return (
          <AbmGenericReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reportKey={reportKey}
            setModalOpen={setModalOpen}
          />
        )
      case 'generated_reports':
        return (
          <GeneratedReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reportKey={reportKey}
            title={'KYT Rules Reports'}
            setModalOpen={setModalOpen}
          />
        )
      case 'rules_report':
        return (
          <RulesQReport
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reportKey={reportKey}
            setModalOpen={setModalOpen}
          />
        )
      case 'rules_report_by_client':
        return (
          <RulesQReportByClient
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reportKey={reportKey}
            setModalOpen={setModalOpen}
          />
        )
      case 'rules_total_report_by_client':
        return (
          <RulesQReportByClient
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reportKey={reportKey}
            setModalOpen={setModalOpen}
          />
        )
      default:
        navigate('/')
    }
  }

  const handleResetButton = () => {
    setIsLoading(true)
    setFromDate(null)
    setToDate(null)
    setId(null)
    setActionResult(null)
    setActionType(null)
    setEmailUser(null)
    setAffectedUser(null)
    setAbmAction(null)
    setReportRoleName(null)
    setAffectedUser2(null)
    setStatus(null)
    setExtension(null)
    setCollection(null)
    setPage(0)
    setUserId('')
    setRuleId('')
    setRuleInstanceId('')

    dispatch(
      updateReportsFilters({
        page: 0,
        limit: rowsPerPage,
        offset: 0,
        fromDate: null,
        toDate: null,
        id: '',
        actionResult: '',
        emailUser: '',
        affectedUser: '',
        abmAction: '',
        reportRoleName: '',
        affectedUser2: '',
        status: '',
      }),
    )
  }

  const handleDispatch = () => {
    setIsLoading(true)
    setPage(0)
    return dispatch(
      updateReportsFilters({
        ...queryState,
        page: 0,
        offset: 0,
        id,
        actionResult,
        actionType,
        emailUser,
        affectedUser,
        abmAction,
        reportRoleName,
        affectedUser2,
        status,
        extension,
        collection,
        fromDate: fromDate?.toISOString(),
        toDate: toDate?.toISOString(),
        userId,
        ruleId,
        ruleInstanceId,
      }),
    )
  }

  useEffect(() => {
    if (!fromDate && toDate) {
      const formattedDate = new Date(toDate)
      formattedDate?.setHours(0)
      formattedDate?.setMinutes(0)
      formattedDate?.setSeconds(0)
      setFromDate(formattedDate)
    }
  }, [toDate])

  useEffect(() => {
    if (fromDate && !toDate) {
      const formattedDate = new Date(fromDate)
      formattedDate?.setHours(23)
      formattedDate?.setMinutes(59)
      formattedDate?.setSeconds(59)
      setToDate(formattedDate ?? null)
    }
  }, [fromDate])

  const handleFromTimeChange = (time) => {
    let newDate = new Date(fromDate)
    const [hs, mins] = time.split(':')
    newDate.setHours(hs)
    newDate.setMinutes(mins)
    setFromTime(time)
    setFromDate(newDate)
  }

  const handleToTimeChange = (time) => {
    let newDate = new Date(toDate)
    const [hs, mins] = time.split(':')
    newDate.setHours(hs)
    newDate.setMinutes(mins)
    setToTime(time)
    setToDate(newDate)
  }

  return (
    <Box>
      <Box className="filter">
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">
              {t('common:reports')} - {t(`common:${reportKey ?? 'type'}`)}
            </Typography>
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>
        <Paper elevation={0}>{reportSelector()}</Paper>
      </Box>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <FilterModalContainer
          setModalOpen={setModalOpen}
          handleResetButton={handleResetButton}
          setPage={setPage}
          handleDispatch={handleDispatch}
        >
          <Box className="modal-box-filters">
            {reportKey === 'rules_report' && (
              <Box className="modal-box">
                <InputLabel>{t('common:ruleInstanceId')}</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={ruleInstanceId}
                  onChange={(event) => setRuleInstanceId(event.target.value)}
                  placeholder={t('messages:insertRuleInstanceIdHere')}
                />
              </Box>
            )}
            {(reportKey === 'rules_report_by_client' || reportKey === 'rules_total_report_by_client') && (
              <Box className="modal-box">
                <InputLabel>{t('common:userId')}</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={userId}
                  onChange={(event) => setUserId(event.target.value)}
                  placeholder={t('messages:insertUserIdHere')}
                />
              </Box>
            )}
            {!['rules_report', 'rules_report_by_client', 'rules_total_report_by_client'].includes(reportKey) && (
              <Box className="modal-box">
                <InputLabel>{t('common:status')}</InputLabel>
                <Autocomplete
                  className="item-status"
                  size="small"
                  value={actionResult}
                  onChange={(event, value) => {
                    if (value) {
                      setActionResult(value)
                    } else setActionResult(null)
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={['SUCCESS', 'FAIL', '']}
                  renderInput={(params) => <TextField {...params} placeholder="Select status" />}
                />
              </Box>
            )}
            <Box className="modal-box">
              <InputLabel>{t('common:fromDate')}</InputLabel>
              <LocalizationProvider
                size="small"
                dateAdapter={AdapterDayjs}
                adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
              >
                <Box>
                  <DatePicker
                    size="small"
                    value={dayjs(fromDate)}
                    onChange={(newValue) => {
                      setFromDate(newValue ? newValue['$d'] : null)
                    }}
                    renderInput={(params) => <TextField size="small" {...params} />}
                  />
                  <TextField
                    disabled={!fromDate}
                    type="time"
                    step="1"
                    value={fromTime}
                    onChange={(e) => handleFromTimeChange(e.target.value)}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            <Box className="modal-box">
              <InputLabel>{t('common:toDate')}</InputLabel>
              <LocalizationProvider
                size="small"
                dateAdapter={AdapterDayjs}
                adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
              >
                <Box>
                  <DatePicker
                    size="small"
                    value={dayjs(toDate)}
                    onChange={(newValue) => {
                      let aux = newValue ? new Date(newValue['$d']) : null
                      aux?.setHours(23)
                      aux?.setMinutes(59)
                      aux?.setSeconds(59)
                      setToDate(aux)
                    }}
                    renderInput={(params) => <TextField size="small" {...params} />}
                  />
                  <TextField
                    disabled={!toDate}
                    type="time"
                    step="1"
                    value={toTime}
                    onChange={(e) => handleToTimeChange(e.target.value)}
                  />
                </Box>
              </LocalizationProvider>
            </Box>

            {!['rules_report', 'rules_report_by_client', 'rules_total_report_by_client'].includes(reportKey) && (
              <Box className="modal-box">
                <InputLabel>Id</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={id}
                  onChange={(event) => setId(event.target.value)}
                  placeholder={t('messages:insertIdHere')}
                />
              </Box>
            )}

            {reportKey === 'rules_report' && (
              <Box className="modal-box">
                <InputLabel>{t('common:ruleId')}</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={ruleId}
                  onChange={(event) => setRuleId(event.target.value)}
                  placeholder={t('messages:insertRuleIdHere')}
                />
              </Box>
            )}
            {reportKey === 'rules_report_by_client' && (
              <Box className="modal-box">
                <InputLabel>{t('common:ruleInstanceId')}</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={ruleInstanceId}
                  onChange={(event) => setRuleInstanceId(event.target.value)}
                  placeholder={t('messages:insertRuleInstanceIdHere')}
                />
              </Box>
            )}
            {!['rules_report', 'rules_report_by_client'].includes(reportKey) && (
              <Box className="modal-box">
                <InputLabel>
                  {!reportKey.includes('abm') && reportKey !== 'change_user_password' ? 'User' : 'Action Owner'}:{' '}
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={emailUser}
                  onChange={(event) => setEmailUser(event.target.value)}
                  placeholder="Insert user email here"
                />
              </Box>
            )}
            {reportKey === 'abm_users' && (
              <Box className="modal-box">
                <InputLabel>{t('common:affectedUser')}</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={affectedUser}
                  onChange={(event) => setAffectedUser(event.target.value)}
                  placeholder="Insert user email here"
                />
              </Box>
            )}
            {(reportKey === 'block_unblock_user_access' || reportKey === 'change_user_password') && (
              <Box className="modal-box">
                <InputLabel>{t('common:affectedUser')}</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={affectedUser2}
                  onChange={(event) => setAffectedUser2(event.target.value)}
                  placeholder="Insert user email here"
                />
              </Box>
            )}
            {reportKey === 'login_user_activity' && (
              <Box className="modal-box">
                <InputLabel>{t('common:action')}</InputLabel>
                <Autocomplete
                  size="small"
                  value={actionType}
                  onChange={(event, value) => {
                    if (value) {
                      setActionType(value)
                    } else setActionType(null)
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={['LOGIN', 'LOGOUT']}
                  renderInput={(params) => <TextField {...params} placeholder="Select action" />}
                />
              </Box>
            )}
            {reportKey?.includes('abm') && (
              <Box className="modal-box">
                <InputLabel>{t('common:action')}</InputLabel>
                <Autocomplete
                  size="small"
                  value={abmAction}
                  onChange={(event, value) => {
                    if (value) {
                      setAbmAction(value)
                    } else setAbmAction(null)
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={['CREATION', 'MODIFICATION', 'DELETION']}
                  renderInput={(params) => <TextField {...params} placeholder="Select action" />}
                />
              </Box>
            )}
            {reportKey === 'abm_roles' && (
              <Box className="modal-box">
                <InputLabel>{t('common:roleName')}</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  value={reportRoleName}
                  onChange={(event) => setReportRoleName(event.target.value)}
                  placeholder="Insert role name here"
                />
              </Box>
            )}
            {reportKey === 'block_unblock_user_access' && (
              <Box className="modal-box">
                <InputLabel>{t('common:activity')}</InputLabel>
                <Autocomplete
                  size="small"
                  value={abmAction}
                  onChange={(event, value) => {
                    if (value) {
                      setStatus(value)
                    } else setStatus(null)
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    'ACTIVE',
                    'INACTIVE',
                    'FAILED_PASSWORD_ATTEMPTS',
                    'EXPIRED_PASSWORD',
                    'INACTIVE_FAILED_ATTEMPTS',
                    'INACTIVE_BY_NOLOGIN',
                  ]}
                  renderInput={(params) => <TextField {...params} placeholder="Select action" />}
                />
              </Box>
            )}
            {reportKey === 'generated_reports' && (
              <Box className="modal-box">
                <InputLabel>{t('common:extension')}</InputLabel>
                <Autocomplete
                  size="small"
                  value={extension}
                  onChange={(event, value) => {
                    if (value) {
                      setExtension(value)
                    } else setExtension(null)
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={['CSV', 'PDF', 'XML']}
                  renderInput={(params) => <TextField {...params} placeholder="Select extension" />}
                />
              </Box>
            )}
            {reportKey === 'generated_reports' && (
              <Box className="modal-box">
                <InputLabel>{t('common:collection')}</InputLabel>
                <Autocomplete
                  size="small"
                  value={collection}
                  onChange={(event, value) => {
                    if (value) {
                      setCollection(value)
                    } else setCollection(null)
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    'Dashboard Users',
                    'Roles',
                    'Security Parameters',
                    'Transactions',
                    'Consumer Users',
                    'Business Users',
                    'Case Management',
                    'Alerts',
                  ]}
                  renderInput={(params) => <TextField {...params} placeholder="Select extension" />}
                />
              </Box>
            )}
          </Box>
        </FilterModalContainer>
      </Modal>
    </Box>
  )
}

export default ReportRouter
